import { useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Menu } from 'antd';

import useNavigationKeyManager from 'hooks/useNavigationKeyManager';

const ApplicationMenu = () => {
  const { url } = useRouteMatch();
  const { showPoi } = useFlags();

  const fraudNavigation = useMemo(() => {
    const children = [
      {
        key: 'fraudCases',
        path: `${url}/fraud-cases`,
        exact: 'true',
        label: <Link to={`${url}/fraud-cases`}>Fraud Cases</Link>,
      },
      {
        key: 'efaCallVerification',
        path: `${url}/efa-call-verification`,
        exact: 'true',
        label: (
          <Link to={`${url}/efa-call-verification`}>EFA Call Verification</Link>
        ),
      },
      {
        key: 'efaContactPull',
        path: `${url}/efa-contact-pull`,
        exact: 'true',
        label: <Link to={`${url}/efa-contact-pull`}>EFA Contact Pull</Link>,
      },
    ];

    if (showPoi) {
      children.push({
        key: 'poiVerification',
        path: `${url}/poi-verification`,
        exact: 'true',
        label: <Link to={`${url}/poi-verification`}>POI Verification</Link>,
      });
    }

    return {
      key: 'fraud',
      label: 'Fraud',
      onTitleClick: ({ key }) => {
        toggleOpenKey(key);
      },
      children,
    };
  }, [showPoi]);

  const navigationItems = [
    {
      key: 'accounts-overview',
      path: `${url}/application-overview`,
      exact: 'true',
      label: (
        <Link to={`${url}/application-overview`}>Application Overview</Link>
      ),
    },
    {
      key: 'attachments',
      label: <Link to={`${url}/attachments`}>Attachments</Link>,
    },
    fraudNavigation,
  ];

  const [activeRouteKey, openKeys, toggleOpenKey] =
    useNavigationKeyManager(navigationItems);

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      selectedKeys={activeRouteKey}
      items={navigationItems}
    ></Menu>
  );
};

export default ApplicationMenu;
