import dayjs from 'dayjs';

import CustomerAccountStatus from 'components/CustomerAccountStatus/CustomerAccountStatus';
import Pill from 'components/Pill/Pill';
import { STATES_ABBR } from 'utilities/constants';

import {
  formatDate,
  formatTime,
  getUserLocalTime,
} from 'utilities/datesAndTimes';

const LocationAndTime = ({ client = {} }) => {
  const accounts = client.accounts || [];

  const customerAddress = client.contactInfo?.address;
  const applicantAddress = client.address;
  const address = customerAddress || applicantAddress || {};

  const localTime = formatTime(getUserLocalTime(address.state));

  let accountOpened;
  if (accounts.length) {
    const sortedAccounts = [...accounts].sort((a1, a2) => {
      return dayjs(a1.createdAt) - dayjs(a2.createdAt);
    });

    const customerSince = sortedAccounts[0].createdAt;
    const openedDate = dayjs(customerSince);
    const signUpDate = formatDate(openedDate);
    const signUpTime = formatTime(openedDate);

    accountOpened = (
      <>
        Customer since {signUpDate} at {signUpTime}
        <CustomerAccountStatus createdAt={customerSince} />
      </>
    );
  }

  const state = STATES_ABBR[address.state]?.name || 'No State';

  const stateColorMap = {
    MA: 'yellow',
    FL: 'orange',
    DC: 'red',
  };

  return (
    <div>
      <Pill color={stateColorMap[address.state]}>{state}</Pill>{' '}
      {`Local time is ${localTime}`}
      <br></br>
      {accountOpened ? accountOpened : null}
    </div>
  );
};

export default LocationAndTime;
