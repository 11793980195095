import { Router } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { SecureRoute } from '@okta/okta-react';

import config from 'config';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';

import ThemeProvider from 'providers/ThemeProvider/ThemeProvider';
import AgentProvider from 'providers/AgentProvider';
import AgentSettingsProvider from 'providers/AgentSettingsProvider';
import CustomerInfoProvider from 'providers/CustomerInfoProvider';
import StylesProvider from 'providers/StylesProvider';
import GQLApolloProvider from 'providers/GQLApolloProvider';
import SecurityProvider from 'providers/SecurityProvider';
import SegmentProvider from 'providers/SegmentProvider';
import TranslationProvider from 'providers/TranslationProvider';

import { sentryBrowserHistory } from 'services/sentry';

const AppWithProviders = () => {
  return (
    <Router history={sentryBrowserHistory}>
      <ThemeProvider>
        <StylesProvider>
          <SecurityProvider>
            <AgentProvider>
              <SegmentProvider>
                <AgentSettingsProvider>
                  <GQLApolloProvider>
                    <CustomerInfoProvider>
                      <TranslationProvider>
                        <SecureRoute path="/" component={DashboardLayout} />
                      </TranslationProvider>
                    </CustomerInfoProvider>
                  </GQLApolloProvider>
                </AgentSettingsProvider>
              </SegmentProvider>
            </AgentProvider>
          </SecurityProvider>
        </StylesProvider>
      </ThemeProvider>
    </Router>
  );
};

export default withLDProvider({
  clientSideID: `${config.LAUNCH_DARKLY_CLIENT_ID}`,
  context: {
    anonymous: true,
    kind: 'user',
    key: 'anonymous',
  },
  options: {
    streaming: true,
    // Comment this dummy logger object to debug launch darkly
    logger: {
      debug: () => {},
      error: () => {},
      info: () => {},
      warn: () => {},
    },
  },
})(AppWithProviders);
