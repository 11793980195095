import { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FrownOutlined, LikeOutlined, SmileOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import useModalControls from 'hooks/useModalControls';
import { GET_SETTLEMENT_OFFERS } from 'queries/settlements';
import { GET_UPCOMING_PAYMENT_ACTIVITY } from 'queries/account';
import { GET_THIRD_PARTY_INFO } from 'queries/accountPreferences';
import {
  SETTLEMENT_OFFER_STATES,
  SETTLEMENT_OFFER_TYPES,
  STATUS,
} from 'utilities/constants';

import ActiveOffer from './ActiveOffer';
import OfferSetup from './OfferSetup';
import OfferEligibilityModal from '../OfferEligibilityModal';
import {
  useRecoveriesState,
  resetRecoveriesState,
  setOffer,
} from './recoveriesState';

export const RecoveriesGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 30% 50%;
  grid-column-gap: var(--spacing);
`;

const RecoveriesSection = () => {
  const { showRecoveries } = useFlags();
  const { currentStep, offer } = useRecoveriesState();
  const { accountId, customerId } = useParams();

  const [offers, setOffers] = useState([]);

  const [eligibilityChecks, setEligibilityChecks] = useState([]);
  const offerEligibilityModal = useModalControls();

  const [hasEligibleSettlementsOffer, setHasEligibleSettlementsOffer] =
    useState(false);
  const [
    hasInitiatedSettlementOfferFromAppWeb,
    setHasInitiatedSettlementOfferFromAppWeb,
  ] = useState(false);
  const [
    hasActiveOrNonDebitPendingCloseOutOffer,
    setHasActiveOrNonDebitPendingCloseOutOffer,
  ] = useState(false);
  const [hasDebitPendingCloseOutOffer, setHasDebitPendingCloseOutOffer] =
    useState(false);
  const [hasScheduledPayment, setHasScheduledPayment] = useState(false);
  const [hasPendingPayment, setHasPendingPayment] = useState(false);
  const [hasExpiredOffer, setHasExpiredOffer] = useState(false);
  const [hasCompletedOffer, setHasCompletedOffer] = useState({
    offerType: null,
  });

  const settlementsQuery = useQuery(GET_SETTLEMENT_OFFERS, {
    variables: { customerId, accountId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ account }) => {
      const eligibilityChecks = account.offers.eligibility[0].checks;
      setEligibilityChecks(eligibilityChecks);
      setHasEligibleSettlementsOffer(account.offers.eligibility[0].eligible);

      const activeOffer = account.offers.offers.find(
        (offer) => offer?.data.state === SETTLEMENT_OFFER_STATES.IN_PROGRESS,
      );

      const nonDebitPendingCloseOutOffer = account.offers.offers.find(
        (offer) =>
          offer?.data.state === SETTLEMENT_OFFER_STATES.PENDING_CLOSE_OUT &&
          !offer?.data.isDebitCardOffer,
      );

      const debitPendingCloseOutOffer = account.offers.offers.find(
        (offer) =>
          offer?.data.state === SETTLEMENT_OFFER_STATES.PENDING_CLOSE_OUT &&
          offer?.data.isDebitCardOffer,
      );

      if (debitPendingCloseOutOffer) {
        setHasDebitPendingCloseOutOffer(true);
      }

      if (activeOffer || nonDebitPendingCloseOutOffer) {
        setOffer({ offer: activeOffer || nonDebitPendingCloseOutOffer });
        setHasActiveOrNonDebitPendingCloseOutOffer(true);
      }

      const hasInitiatedOffer = account.offers.offers.find(
        (offer) =>
          offer?.data.state === SETTLEMENT_OFFER_STATES.READY ||
          offer?.data.state === SETTLEMENT_OFFER_STATES.NOT_READY,
      );

      if (hasInitiatedOffer) {
        setHasInitiatedSettlementOfferFromAppWeb(true);
      }

      const hasCompletedOffer = account.offers.offers.find(
        (offer) => offer?.data.state === SETTLEMENT_OFFER_STATES.FULFILLED,
      );

      if (hasCompletedOffer) {
        setHasCompletedOffer({
          offerType: hasCompletedOffer.data.subType,
        });
      }

      const hasExpiredOffer = account.offers.offers.find(
        (offer) =>
          offer?.data.acceptedAt &&
          offer?.data.state === SETTLEMENT_OFFER_STATES.EXPIRED,
      );

      if (hasExpiredOffer) {
        setHasExpiredOffer(true);
      }

      const offersWithIndex = account.offers.settlement.available.map(
        (offer, index) => {
          return {
            ...offer,
            key: index + 1,
          };
        },
      );

      setOffers(offersWithIndex);
    },
  });

  useEffect(() => {
    if (settlementsQuery.loading === true) {
      setHasActiveOrNonDebitPendingCloseOutOffer(false);
      setHasDebitPendingCloseOutOffer(false);
      setHasScheduledPayment(false);
      setHasPendingPayment(false);
      setHasExpiredOffer(false);
      setHasCompletedOffer({ offerType: null });
    }
  }, [settlementsQuery.loading]);

  useQuery(GET_UPCOMING_PAYMENT_ACTIVITY, {
    variables: { customerId, accountId },
    fetchPolicy: 'no-cache',
    onCompleted: ({ account }) => {
      const hasAutopaySchedule = !!account.autopay.length;
      const hasUpcomingPayment = !!account.upcomingPayments.find(
        (upcomingPayment) => upcomingPayment.state === 'SCHEDULED',
      );
      const hasPendingPayment = !!account.upcomingPayments.find(
        (upcomingPayment) => upcomingPayment.state === 'PENDING',
      );

      setHasScheduledPayment(hasAutopaySchedule || hasUpcomingPayment);
      setHasPendingPayment(hasPendingPayment);
    },
  });

  const { error, data: thirdPartyData } = useQuery(GET_THIRD_PARTY_INFO, {
    notifyOnNetworkStatusChange: true,
    variables: { accountId },
    fetchPolicy: 'network-only',
  });

  const thirdPartyInfo = {
    name: thirdPartyData?.getAccountPreferences?.thirdPartyName,
    email: thirdPartyData?.getAccountPreferences?.thirdPartyEmail,
    customerOptIn: thirdPartyData?.getAccountPreferences?.customerOptIn,
  };

  const renderStep = () => {
    const StepComponent = currentStep?.component;
    if (StepComponent) {
      return (
        <StepComponent
          offers={offers}
          hasPendingPayment={hasPendingPayment}
          hasScheduledPayment={hasScheduledPayment}
          settlementsQuery={settlementsQuery}
          thirdParty={{ ...thirdPartyInfo, error }}
        />
      );
    }
  };

  const offerDashboard = useMemo(() => {
    // we always want to show active offers (includes pending close out, completed, expired),
    // regardless of feature flag status
    // but if the flag is off (i.e. we are no longer offering settlement offers to customers),
    // we want to show the agents a message that the feature is not available
    const hasActiveOffer = offer?.state === SETTLEMENT_OFFER_STATES.IN_PROGRESS;

    if (hasActiveOrNonDebitPendingCloseOutOffer) {
      return <ActiveOffer thirdParty={{ ...thirdPartyInfo, error }} />;
    }

    if (hasDebitPendingCloseOutOffer) {
      return (
        <SectionMessage
          status={STATUS.INFORMATION}
          icon={LikeOutlined}
          text="The account is pending completion of their settlement offer."
        />
      );
    }

    if (hasCompletedOffer.offerType) {
      return (
        <SectionMessage
          status={STATUS.SUCCESS}
          icon={SmileOutlined}
          text={
            hasCompletedOffer.offerType === SETTLEMENT_OFFER_TYPES.SIF
              ? 'The account has been settled in full.'
              : 'The account has been paid in full.'
          }
        />
      );
    }

    if (hasExpiredOffer) {
      return (
        <SectionMessage
          status={STATUS.ERROR}
          icon={FrownOutlined}
          text="The account did not complete their settlement offer."
          buttons={[
            {
              text: 'Check for another available offer',
              onClick: () => setHasExpiredOffer(false),
            },
          ]}
        />
      );
    }

    if (!showRecoveries) {
      return (
        <SectionMessage
          status={STATUS.WARNING}
          text="The Recoveries program is not yet available."
        />
      );
    }

    if (showRecoveries && !hasActiveOffer && hasEligibleSettlementsOffer) {
      return (
        <OfferSetup
          renderStep={renderStep}
          thirdParty={{ ...thirdPartyInfo, error }}
          hasInitiatedSettlementOfferFromAppWeb={
            hasInitiatedSettlementOfferFromAppWeb
          }
        />
      );
    }

    if (showRecoveries && !hasActiveOffer && !hasEligibleSettlementsOffer) {
      return (
        <>
          <SectionMessage
            status={STATUS.WARNING}
            text="This account is not eligible for a settlement offer."
            buttons={[
              {
                text: 'View Eligibility Criteria',
                onClick: offerEligibilityModal.show,
              },
            ]}
          />
          <OfferEligibilityModal
            {...offerEligibilityModal}
            eligibilityChecks={eligibilityChecks}
            eligible={hasEligibleSettlementsOffer}
            offer="Settlements"
          />
        </>
      );
    }
  }, [
    offer.offerId,
    offer.state,
    hasCompletedOffer,
    hasExpiredOffer,
    renderStep,
    hasEligibleSettlementsOffer,
    hasInitiatedSettlementOfferFromAppWeb,
    settlementsQuery.data,
  ]);

  useEffect(() => {
    resetRecoveriesState();
  }, [accountId, customerId]);

  const prevOfferIdRef = useRef();
  useEffect(() => {
    const prevOfferId = prevOfferIdRef.current;

    if (prevOfferId && offer?.offerId) {
      settlementsQuery.refetch();
    }

    prevOfferIdRef.current = offer?.offerId;
  }, [offer?.offerId]);

  return (
    <>
      <QueryBoundary
        query={settlementsQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage={'Loading Programs'}
      >
        {offerDashboard}
      </QueryBoundary>
    </>
  );
};

export default RecoveriesSection;
