import { useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Alert, Button, Checkbox, Flex, Form, Select } from 'antd';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import DatePicker from 'components/DatePicker/DatePicker';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import useAgent from 'hooks/useAgent';
import useAnalytics from 'hooks/useAnalytics';
import useAWSConnectInfo from 'hooks/useAWSConnectInfo';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useTimeoutValue from 'hooks/useTimeoutValue';
import {
  ADD_APPLICATION_INTERACTION,
  ADD_CUSTOMER_INTERACTION,
} from 'mutations/notes';
import { ANALYTICS_EVENTS, STATUS } from 'utilities/constants';

import { topics, topicCategories } from './callTagTopics';

const { Option } = Select;
const { Item } = Form;

const refetchQueries = ['NotesInteractionsZendeskRecords', 'GetAgentNotes'];

const TagCall = () => {
  const agent = useAgent();
  const { applicationId, customerId } = useCustomerInfo();
  const { inboundCallInfo } = useAWSConnectInfo();
  const [contactEventId, setContactEventId] = useState(null);
  const { trackEvent } = useAnalytics();
  const [form] = Form.useForm();
  const [topicVal, setTopicVal] = useState([]);
  const [disabledState, setDisabledState] = useState(true);
  const [pushContactDate, setPushContactDate] = useToggle(false);
  const [showSuccessMessage, setShowSuccessMessage] = useTimeoutValue(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  const initialValues = useMemo(() => {
    return {
      resolution: 'Right Party Contact',
      product: 'Credit',
      category: null,
      topic: null,
      date: dayjs().add(1, 'week'),
    };
  }, []);

  const [addApplicationInteraction, applicationInteractionMutation] =
    useMutation(ADD_APPLICATION_INTERACTION, {
      refetchQueries,
    });

  const [addCustomerInteraction, customerInteractionMutation] = useMutation(
    ADD_CUSTOMER_INTERACTION,
    {
      refetchQueries,
    },
  );

  useEffect(() => {
    if (inboundCallInfo) {
      setContactEventId(inboundCallInfo.contactId);
    }
  }, [inboundCallInfo]);

  const onFinish = async (values) => {
    setShowFailureMessage(false);

    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    trackEvent(ANALYTICS_EVENTS.NOTE_BOX_TAG_CALL_RESOLVED);
    try {
      const variables = {
        agentId: agent?.user?.requester_id,
        agentName: agent?.user?.name,
        inBound: false,
        channel: 'Phone',
        callTags: '',
        resolution: values.resolution,
        product: 'CREDIT',
        topicCategory: values.category,
        topic: values.topic,
        calledFrom: '',
        calledTo: '',
        nextTouchDateTime: values.date.startOf('minute').toISOString(),
        collections: values.category === 'Collections',
        contactEventId,
      };

      let addInteraction;
      if (applicationId) {
        addInteraction = addApplicationInteraction;
        variables.applicationId = applicationId;
      } else {
        addInteraction = addCustomerInteraction;
        variables.customerId = customerId;
      }

      await addInteraction({
        variables,
      });

      setContactEventId(null);
      setShowSuccessMessage(true);
      form.setFieldsValue({ ...initialValues });
    } catch {
      setShowFailureMessage(true);
    }
  };

  const handleChange = () => {
    setTopicVal(topics[form.getFieldValue('category')] || []);
    if (form.getFieldValue('category')) {
      setDisabledState(false);
    }

    // Clear selected topic if category is changed
    form.setFieldsValue({
      topic: null,
    });
  };

  const loading =
    applicationInteractionMutation.loading ||
    customerInteractionMutation.loading;

  return (
    <>
      <Form
        form={form}
        layout={'vertical'}
        size="small"
        name="tag-call-form"
        initialValues={initialValues}
        onFinish={onFinish}
        style={{ position: 'relative ' }}
      >
        <SectionMessage
          cover={true}
          coverBackgroundColor="var(--color-fill-quaternary)"
          status={STATUS.SUCCESS}
          text="Tag Recorded"
          visible={showSuccessMessage}
        />
        <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
          <Item
            label="Resolution"
            name="resolution"
            style={{
              display: 'inline-block',
              width: 'calc(48% - 8px)',
              marginBottom: 'var(--spacing-xs)',
            }}
          >
            <Select style={{ width: 212 }} data-testid="resolution-select">
              <Option value="Right Party Contact">Right Party Contact</Option>
              <Option value="Live Answer - Non RPC">
                Live Answer - Non RPC
              </Option>
              <Option value="Voicemail Left">Voicemail Left</Option>
              <Option value="Voicemail - No Message Left">
                Voicemail - No Message Left
              </Option>
              <Option value="Number Out of Service">
                Number Out of Service
              </Option>
              <Option value="Call Failed or Dropped">
                Call Failed or Dropped
              </Option>
              <Option value="Live Answer - Bad Number">
                Live Answer - Bad Number
              </Option>
              <Option value="Number Not Attempted">Number Not Attempted</Option>
            </Select>
          </Item>
        </Item>

        <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
          <Item
            shouldUpdate={true}
            label="Topic Category"
            name="category"
            rules={[{ required: true, message: 'Category is required' }]}
            style={{
              display: 'inline-block',
              width: 'calc(48% - 8px)',
              marginBottom: 'var(--spacing-xs)',
            }}
          >
            <Select
              data-testid="topic-category-select"
              placeholder="-- Select one --"
              options={topicCategories}
              onChange={handleChange}
              style={{ width: 212 }}
            />
          </Item>
          <Item
            label="Topic"
            name="topic"
            rules={[{ required: true, message: 'Topic is required' }]}
            style={{
              display: 'inline-block',
              width: 'calc(48% - 8px)',
              marginBottom: 'var(--spacing-xs)',
            }}
          >
            <Select
              data-testid="topic-select"
              disabled={disabledState}
              style={{ width: 212 }}
              placeholder="-- Select Topic --"
              popupMatchSelectWidth={false}
            >
              {topicVal.map((item) => (
                <Option
                  key={item + form.getFieldValue('category')}
                  value={item}
                >
                  {item}
                </Option>
              ))}
            </Select>
          </Item>
        </Item>

        <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
          <Checkbox
            checked={pushContactDate}
            data-testid="push-out-calls-check"
            defaultChecked={false}
            onChange={setPushContactDate}
            style={{ marginBottom: '8px' }}
          >
            Check to Push Out Calls Until
          </Checkbox>
          <Item name="date" style={{ marginBottom: 'var(--spacing-xs)' }}>
            <DatePicker
              showTime={{ use12Hours: true, format: 'hh:mm' }}
              showNow
              format="YYYY-MM-DD hh:mm a"
              data-testid="schedule-date"
              style={{ display: !pushContactDate ? 'none' : 'inline-block' }}
            />
          </Item>
        </Item>

        {showFailureMessage ? (
          <Alert
            type="error"
            message="Failed to record tag. Please try again."
            style={{ marginBottom: 'var(--spacing-sm)' }}
          />
        ) : null}

        <Flex justify="flex-end">
          <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
            <Button
              data-testid="resolve-tag-button"
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              Resolve
            </Button>
          </Item>
        </Flex>
      </Form>
    </>
  );
};

export default TagCall;
