import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import QueryResult from 'components/QueryResult/QueryResult';

import { RETURNED_PAYMENTS } from 'queries/payments';
import { formatDate } from 'utilities/datesAndTimes';
import {
  formatDollarsFromCents,
  formatFundingAccount,
} from 'utilities/helpers';

const R16_ACCOUNT_FROZEN = 'R16';

const columns = [
  {
    title: 'Transaction Date',
    dataIndex: 'transactionDate',
    render: formatDate,
    sorter: (a, b) => a.date - b.date,
  },
  {
    title: 'Transaction ID',
    dataIndex: 'referenceNumber',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    title: 'Return Code',
    dataIndex: 'code',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    render: (reason, returnedPayment) => {
      if (returnedPayment?.code === R16_ACCOUNT_FROZEN) {
        return "The customer's bank account is frozen.";
      }

      return reason;
    },
  },
  {
    title: 'Payment Type',
    dataIndex: 'source',
  },
  {
    title: 'Bank Account',
    dataIndex: 'fundingAccount',
  },
  {
    title: 'Return Date',
    dataIndex: 'returnDate',
    render: formatDate,
    sorter: (a, b) => a.date - b.date,
  },
  {
    title: 'Validation Method',
    render: (_, payment) => {
      return payment?.bankAccount?.validationMethod;
    },
  },
];

const PaymentReturnSection = () => {
  const { accountId, customerId } = useParams();

  const [getReturns, returnsQuery] = useLazyQuery(RETURNED_PAYMENTS);

  useEffect(() => {
    if (customerId && accountId) {
      getReturns({
        variables: {
          customerId: customerId,
          accountId: accountId,
        },
      });
    }
  }, [customerId, accountId, getReturns]);

  const returns = returnsQuery?.data?.paymentReturn.map(
    (paymentReturn, index) => ({
      key: index,
      code: paymentReturn.code,
      reason: paymentReturn.reason,
      returnDate: dayjs(paymentReturn.returnDate),
      transactionDate: dayjs(paymentReturn.transactionDate),
      amount: formatDollarsFromCents(paymentReturn.amount),
      fundingAccount: formatFundingAccount(paymentReturn.fundingAccount),
      source: paymentReturn.source,
      referenceNumber: paymentReturn.referenceNumber,
    }),
  );

  return (
    <>
      <Typography.Title level={5}>Returned Payments</Typography.Title>
      <QueryResult {...returnsQuery}>
        <CondensedTable columns={columns} data={returns} />
      </QueryResult>
    </>
  );
};

export default PaymentReturnSection;
