import dayjs from 'dayjs';

import { useRecoveriesState } from './recoveriesState';
import {
  PaymentsTableWithHighlights,
  applyRowClass,
} from './ScheduledPaymentsTable';

import { formatDollarsFromCents } from 'utilities/helpers';

const EftaPaymentScheduleReviewTable = () => {
  const { offer, paymentPlan } = useRecoveriesState();

  const isOfferSetup = !offer.state;
  const { current, future, updatedFuture, pendingDeleted } = paymentPlan;

  let data = [];
  if (!isOfferSetup) {
    const hasUpdatedOrPendingDeletedPayments =
      updatedFuture.length || pendingDeleted.length;
    const scheduledPayments = hasUpdatedOrPendingDeletedPayments
      ? updatedFuture
      : future;
    data = scheduledPayments?.sort((a, b) => dayjs(a.date) - dayjs(b.date));
  } else {
    data = current?.sort((a, b) => dayjs(a.date) - dayjs(b.date));
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return dayjs(date).format('LL');
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => formatDollarsFromCents(record.amount),
    },
  ];

  return (
    <PaymentsTableWithHighlights
      rowClassName={applyRowClass}
      columns={columns}
      data={data}
      length={6}
    />
  );
};

export default EftaPaymentScheduleReviewTable;
