import { useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import HighlightHTML from 'components/HighlightHTML/HighlightHTML';

const ExpandableContainer = styled('div')(({ expanded, fade }) => {
  let heightStyle = '';
  if (!expanded) {
    heightStyle = `max-height: 92px;`;
  }

  let fadeStyle = '';
  if (fade) {
    fadeStyle = `
      mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
      --webkit-mask-image:
        linear-gradient(to bottom, black 70%, transparent 100%);
    `;
  }
  return `
    overflow: hidden;
    white-space: pre-wrap;
    ${heightStyle}
    ${fadeStyle}
  `;
});

/**
 * NoteBody component that supports searchable text with highlight.
 * DO NOT use with children. See example below
 *
 * Recommended Usage:
 * <NoteBody searchableContent={string} otherElements={JSX.Element} highlight={string} ... />
 *
 * @param {Object} props
 * @param {string} props.searchableContent - string content that is used to search upon.
 * @param {JSX} props.otherElements - One or more JSX.
 * @param {string} props.highlight- the text to hightlight within the searchable context if there is a match.
 * @param {boolean} enableTruncatedNotes - to show in expandable container.
 * @returns
 */
const NoteBody = ({
  searchableContent,
  otherElements,
  enableTruncatedNotes,
  highlight,
  ...rest
}) => {
  let elementRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const open = !enableTruncatedNotes || expanded;
  const fade = hasOverflow && !open;

  useEffect(() => {
    const ref = elementRef.current;
    if (ref) {
      setHasOverflow(ref.scrollHeight > ref.clientHeight);
    }
  }, [elementRef.current]);

  return (
    <div {...rest}>
      <ExpandableContainer ref={elementRef} expanded={open} fade={fade}>
        <HighlightHTML html={searchableContent} textToHighlight={highlight} />
        {otherElements}
      </ExpandableContainer>

      {hasOverflow ? (
        <Button
          type="link"
          size="small"
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ marginRight: 'var(--spacing)' }}
        >
          {expanded ? 'Show Less' : 'Show More'}
          {expanded ? <UpOutlined /> : <DownOutlined />}
        </Button>
      ) : null}
    </div>
  );
};

export default NoteBody;
