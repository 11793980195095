import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { APPLICATION } from 'queries/applications';

const useApplicationQuery = (applicationId, options = {}) => {
  const [application, setApplication] = useState({});
  const [applicant, setApplicant] = useState({});
  const [bureauReports, setBureauReports] = useState([]);
  const [documents, setDocuments] = useState({});
  const [sponsorBank, setSponsorBank] = useState('');

  const [getApplication, applicationQuery] = useLazyQuery(APPLICATION, {
    ...options,
  });

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { applicationId } });
    } else {
      setApplication({});
      setApplicant({});
    }
  }, [applicationId]);

  useEffect(() => {
    if (applicationQuery.data) {
      const applicationData = applicationQuery.data.applicationV2;
      const applicants = applicationData.submission.applicants;
      const bureauReports = applicationQuery.data.bureauReports;
      const documentData = applicationQuery.data.applicationDocuments;
      const sponsorBank = applicationQuery.data.applicationV2.sponsorBank;
      setApplication(applicationData);
      setApplicant(applicants[0] || {});
      setBureauReports(bureauReports);
      setDocuments(documentData);
      setSponsorBank(sponsorBank);
    }
  }, [applicationId, applicationQuery.data]);

  return {
    application,
    applicationQuery,
    applicant,
    bureauReports,
    documents,
    sponsorBank,
  };
};

export default useApplicationQuery;
