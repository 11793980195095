import { Flex, Typography, Row, Col } from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';

import { SidebarDivider } from 'layouts/DashboardLayout/Sidebar';

import ClientMetaSkeleton from 'components/ClientMetaSkeleton/ClientMetaSkeleton';
import DisplayValue from 'components/DisplayValue/DisplayValue';
import ErrorTooltip from 'components/ErrorTooltip/ErrorTooltip';
import LocationAndTime from 'components/LocationAndTime/LocationAndTime';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useAgent from 'hooks/useAgent';
import useApplicantFullSsn from 'hooks/useApplicantFullSsn';
import useCustomerInfo from 'hooks/useCustomerInfo';

import { SIZE } from 'utilities/constants';
import { formatDate, getAgeFromDate } from 'utilities/datesAndTimes';

const ApplicantMeta = () => {
  const { isSuperUser } = useAgent();

  const { customerInfo, customerInfoQuery } = useCustomerInfo();
  const [fullSsn, getFullSsn, ssnFailureMessage, { loading: loadingFullSsn }] =
    useApplicantFullSsn();

  const getApplicantName = () => {
    const { firstName, middleName, lastName } = customerInfo;
    return `${firstName} ${(middleName || '').charAt(0)} ${lastName}`;
  };

  return (
    <>
      <QueryBoundary
        messageHeight="372px"
        errorMessage="Failed to load Applicant Information."
        query={customerInfoQuery}
        skeleton={ClientMetaSkeleton}
      >
        <Flex vertical={true}>
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {getApplicantName()}
          </Typography.Title>

          <SidebarDivider />

          <LocationAndTime client={customerInfo}></LocationAndTime>

          <SidebarDivider />

          <Row gutter={[10, '1rem']}>
            <Col sm={11}>
              <ErrorTooltip
                placement="topLeft"
                open={!!ssnFailureMessage}
                title={ssnFailureMessage}
                styles={{
                  root: {
                    position: 'fixed',
                  },
                }}
              >
                <DisplayValue
                  label="SSN"
                  labelIcon={
                    isSuperUser
                      ? loadingFullSsn
                        ? LoadingOutlined
                        : EyeOutlined
                      : undefined
                  }
                  onLabelIconClick={
                    loadingFullSsn.loading ? undefined : getFullSsn
                  }
                  size={SIZE.MD}
                >
                  {fullSsn ? fullSsn : `_____ ${customerInfo.lastFourSSN}`}
                </DisplayValue>
              </ErrorTooltip>
            </Col>
            <Col sm={13}>
              <DisplayValue
                label="Primary Phone"
                size={SIZE.MD}
                canCopy={true}
                copiedValue={`${customerInfo.phoneNumber}`}
              >
                {customerInfo.phoneNumber}
              </DisplayValue>
            </Col>
            <Col sm={11}>
              <DisplayValue
                label="Date of Birth"
                size={SIZE.MD}
                canCopy={true}
                copiedValue={formatDate(customerInfo.datOfBirth)}
              >
                {formatDate(customerInfo.datOfBirth)} &bull;{' '}
                {getAgeFromDate(customerInfo.datOfBirth)} yrs
              </DisplayValue>
            </Col>
            <Col sm={13}>
              <DisplayValue label="Email" canCopy={true} size={SIZE.MD}>
                {customerInfo.email}
              </DisplayValue>
            </Col>
            <Col sm={24}>
              <DisplayValue
                label="Address"
                size={SIZE.MD}
                canCopy={true}
                copiedValue={`${customerInfo.address?.street1} ${customerInfo.address?.city}, ${customerInfo.address?.state} ${customerInfo.address?.postalCode}`}
              >
                {`${customerInfo.address?.street1} ${customerInfo.address?.street2}`.trim()}
                <br></br>
                {`${customerInfo.address?.city}, ${customerInfo.address?.state} ${customerInfo.address?.postalCode}`}
              </DisplayValue>
            </Col>
          </Row>
        </Flex>
      </QueryBoundary>
    </>
  );
};

export default ApplicantMeta;
