import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Form, InputNumber, Modal } from 'antd';

import DatePicker from 'components/DatePicker/DatePicker';

import { formatDate, formatDateToSubmit } from 'utilities/datesAndTimes';

import { setPaymentPlan, useRecoveriesState } from './recoveriesState';
import { isAfterTodayPaymentCutoff } from './PaymentPlanDetailsStep';

const { Item } = Form;

const EditPaymentModal = ({
  transactions,
  initialValues,
  setInitialValues,
  type,
  hide,
  ...modalProps
}) => {
  const { paymentPlan, offer } = useRecoveriesState();
  const isOfferSetup = !offer.state;

  const [editPaymentForm] = Form.useForm();

  const [updatedPaymentDateExists, setUpdatedPaymentDateExists] =
    useState(false);

  const onDateChange = (date) => {
    const paymentDateExists = transactions.find((transaction) =>
      dayjs(transaction.date).isSame(date, 'day'),
    );

    if (paymentDateExists) {
      return setUpdatedPaymentDateExists(true);
    }

    setUpdatedPaymentDateExists(false);
  };

  const onClose = () => {
    editPaymentForm.resetFields();
    setInitialValues({});
    hide();
  };

  const onDelete = () => {
    let currentPaymentPlan;
    if (isOfferSetup) {
      currentPaymentPlan = paymentPlan?.current;
    } else {
      currentPaymentPlan = paymentPlan?.updatedFuture?.length
        ? paymentPlan?.updatedFuture
        : paymentPlan?.future;
    }

    const updatedFuturePaymentPlan = currentPaymentPlan.reduce(
      (acc, payment) => {
        const key = formatDate(dayjs(payment.date));
        const value = !dayjs(payment.date).isSame(dayjs(initialValues.date))
          ? payment
          : { ...payment, status: 'delete' };

        acc[key] = value;

        return acc;
      },
      {},
    );

    const newUpdatedFuture = Object.values(updatedFuturePaymentPlan);

    const pendingDeletedPayment = newUpdatedFuture.filter(
      (payment) => payment?.status === 'delete',
    );

    setPaymentPlan({
      ...paymentPlan,
      current: newUpdatedFuture,
      pendingDeleted: [
        ...paymentPlan?.pendingDeleted,
        ...pendingDeletedPayment,
      ],
      updatedFuture: newUpdatedFuture,
    });
    onClose();
  };

  const getUpdatedPayments = (payments, updatedPayment) => {
    return payments
      .map((payment) => {
        if (dayjs(payment.date).isSame(dayjs(initialValues.date))) {
          return updatedPayment;
        } else {
          return payment;
        }
      })
      .sort((a, b) => dayjs(a.date) - dayjs(b.date));
  };

  const onFinish = async ({ amount, date }) => {
    await editPaymentForm.validateFields();

    let currentPaymentPlan = [];
    if (isOfferSetup) {
      currentPaymentPlan = paymentPlan?.current;
    } else {
      currentPaymentPlan = paymentPlan?.updatedFuture.length
        ? paymentPlan?.updatedFuture
        : paymentPlan?.future;
    }

    if (type === 'edit') {
      const updatedPayments = getUpdatedPayments(currentPaymentPlan, {
        amount: Math.round(amount * 100),
        date: formatDateToSubmit(date),
        status: 'edit',
      });

      setPaymentPlan({
        ...paymentPlan,
        updatedFuture: updatedPayments,
        current: updatedPayments,
      });
    }

    if (type === 'add') {
      const updatedPayments = [
        ...currentPaymentPlan,
        {
          amount: Math.round(amount * 100),
          date: formatDateToSubmit(date),
          status: 'add',
        },
      ].sort((a, b) => dayjs(a.date) - dayjs(b.date));

      setPaymentPlan({
        ...paymentPlan,
        updatedFuture: updatedPayments,
        current: updatedPayments,
      });
    }

    onClose();
  };

  const buttons = [
    <Button
      form="editPaymentForm"
      type="primary"
      htmlType="submit"
      key="confirm"
    >
      Confirm Payment Details
    </Button>,
  ];

  if (type === 'edit') {
    buttons.unshift(
      <Button key="delete" danger onClick={onDelete}>
        Delete This Payment
      </Button>,
    );
  }

  const dateIsSameOrAfterOfferExpiration = (date) => {
    return date.isSameOrAfter(offer?.expiration);
  };

  useEffect(() => {
    editPaymentForm.setFieldsValue({
      amount: initialValues.amount,
      date: initialValues.date,
    });
  }, [initialValues]);

  return (
    <Modal
      {...modalProps}
      title="Edit Payment"
      onCancel={onClose}
      footer={buttons}
    >
      <Form
        id="editPaymentForm"
        name="editPaymentForm"
        form={editPaymentForm}
        onFinish={onFinish}
      >
        <Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: 'Please enter a payment amount.',
            },
          ]}
        >
          <InputNumber min="0.00" step="0.01" />
        </Item>
        <Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: 'Please enter a payment date.',
            },
            {
              /* eslint-disable no-unused-vars */
              validator: () => {
                return new Promise((resolve, reject) => {
                  if (updatedPaymentDateExists) {
                    reject();
                  }
                  resolve();
                });
              },
              message:
                'The customer already has a payment scheduled for this date. Please select a different date.',
            },
          ]}
        >
          <DatePicker
            onChange={onDateChange}
            disabledDate={(d) =>
              !d ||
              d.isSameOrBefore(dayjs()) ||
              dateIsSameOrAfterOfferExpiration(d) ||
              isAfterTodayPaymentCutoff(d)
            }
            style={{ marginBottom: 'var(--spacing-sm)' }}
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default EditPaymentModal;
