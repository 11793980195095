import dayjs from 'dayjs';
import {
  createReactiveState,
  createFundingAccountHandlers,
  createStepHandlers,
  getFundingAccountProperties,
  getStepProperties,
} from 'utilities/stateHelpers';

import {
  SETTLEMENT_OFFER_TYPES,
  SETTLEMENTS_CADENCE_VALUES,
  PAYMENT_STATES,
  SETTLEMENT_OFFER_STATES,
} from 'utilities/constants';

import ChooseOfferStep from './ChooseOfferStep';
import FundingAccountStepWrapper from './FundingAccountStepWrapper';
import PaymentPlanDetailsStep from './PaymentPlanDetailsStep';
import FinalizePaymentPlanStep from './FinalizePaymentPlanStep';
import DisclosureStep from './DisclosureStep';
import EFTAStep from './EFTAStep';
import { formatDollarsFromCents } from 'utilities/helpers';
import StepLabel from 'components/StepLabel/StepLabel';

const CHOOSE_OFFER_STEP = {
  title: 'Choose an Offer',
  component: ChooseOfferStep,
  renderPreprocess: () => {
    return;
  },
  renderPostprocess: () => {
    const currentState = recoveriesStateVar();
    const { offerType, duration, settlementPercentage } = currentState.offer;
    const planDuration = currentState?.paymentPlan?.planDuration;
    const selectedOfferIsPif = offerType.includes(SETTLEMENT_OFFER_TYPES.PIF);
    const selectedOfferIsDebit = offerType.includes('OneTimeDebit');
    const offerTypeText = selectedOfferIsPif ? 'Paid in Full' : 'Settlement';
    const balancePercentage = `${settlementPercentage}`;
    const offerDuration =
      selectedOfferIsPif && planDuration ? planDuration : duration;

    return (
      <StepLabel
        status="positive"
        title="OfferSelected"
        secondary={`${offerTypeText} | ${balancePercentage} over ${offerDuration} ${!selectedOfferIsDebit ? 'month(s)' : 'day'}`}
      />
    );
  },
};

const ADD_FUNDING_ACCOUNT_STEP = {
  title: 'Select a Funding Account',
  component: FundingAccountStepWrapper,
  renderPreprocess: () => {
    return <StepLabel title={ADD_FUNDING_ACCOUNT_STEP.title} />;
  },
  renderPostprocess: () => {
    const currentState = recoveriesStateVar();
    const { name } = currentState.fundingAccount;
    return (
      <StepLabel status="positive" title="Funding Account:" secondary={name} />
    );
  },
};

const PAYMENT_PLAN_DETAILS_STEP = {
  title: 'Set Payment Plan Details',
  component: PaymentPlanDetailsStep,
  renderPreprocess: () => {
    return <StepLabel title={PAYMENT_PLAN_DETAILS_STEP.title} />;
  },
  renderPostprocess: () => {
    const currentState = recoveriesStateVar();
    const firstPaymentDate = currentState?.paymentDates?.startDate;
    const { cadence, current } = currentState.paymentPlan;

    const paymentCadenceText =
      cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME
        ? '1 payment of'
        : `${current?.length} ${cadence} payments of`;

    return (
      <StepLabel
        status="positive"
        title="Payment Plan Details:"
        secondary={`${paymentCadenceText} ${formatDollarsFromCents(current?.[0]?.amount)} 
      starting ${firstPaymentDate}`}
      />
    );
  },
};

const FINALIZE_PAYMENT_PLAN_STEP = {
  title: 'Finalize Payment Plan',
  component: FinalizePaymentPlanStep,
  renderPreprocess: () => {
    return <StepLabel title={FINALIZE_PAYMENT_PLAN_STEP.title} />;
  },
  renderPostprocess: () => {
    return <StepLabel status="positive" title="Payment Plan finalized" />;
  },
};

const DISCLOSURE_STEP = {
  title: 'Read Disclosures',
  component: DisclosureStep,
  renderPreprocess: () => {
    return <StepLabel title={DISCLOSURE_STEP.title} />;
  },
  renderPostprocess: () => {
    <StepLabel status="positive" title="Disclosures accepted" />;
  },
};

const EFTA_STEP = {
  title: 'Read EFTA',
  component: EFTAStep,
  renderPreprocess: () => {
    return <StepLabel title={EFTA_STEP.title} />;
  },
  renderPostprocess: () => {
    return;
  },
};

export const RECOVERIES_STEPS = Object.freeze({
  CHOOSE_OFFER: CHOOSE_OFFER_STEP,
  ADD_FUNDING_ACCOUNT: ADD_FUNDING_ACCOUNT_STEP,
  PAYMENT_PLAN_DETAILS: PAYMENT_PLAN_DETAILS_STEP,
  FINALIZE_PAYMENT_PLAN: FINALIZE_PAYMENT_PLAN_STEP,
  DISCLOSURE: DISCLOSURE_STEP,
  EFTA: EFTA_STEP,
});

export const PAYMENT_PLAN_PROGRESS_STEPS = [
  RECOVERIES_STEPS.CHOOSE_OFFER,
  RECOVERIES_STEPS.ADD_FUNDING_ACCOUNT,
  RECOVERIES_STEPS.PAYMENT_PLAN_DETAILS,
  RECOVERIES_STEPS.FINALIZE_PAYMENT_PLAN,
  RECOVERIES_STEPS.DISCLOSURE,
  RECOVERIES_STEPS.EFTA,
];

export const ONE_TIME_DEBIT_PROGRESS_STEPS = [
  RECOVERIES_STEPS.CHOOSE_OFFER,
  RECOVERIES_STEPS.DISCLOSURE,
  RECOVERIES_STEPS.EFTA,
];

export const OPTIONAL_PAYMENT_PLAN_PROGRESS_STEPS = [
  RECOVERIES_STEPS.CHOOSE_OFFER,
  RECOVERIES_STEPS.DISCLOSURE,
];

const initializeState = () => {
  return {
    ...getStepProperties(RECOVERIES_STEPS.CHOOSE_OFFER),
    ...getFundingAccountProperties(),
    offer: {
      balance: {
        remainingBalance: null,
        outstandingBalance: null,
        settlementBalance: null,
      },
      acceptanceExpiration: null,
      duration: null,
      isDebitCard: null,
      expiration: null,
      offerId: null,
      offerName: null,
      offerType: null,
      paymentPlanId: null,
      settlementPercentage: null,
      state: null,
    },
    fundingAccount: {
      id: null,
      name: null,
    },
    paymentDates: {
      startDate: null,
      endDate: null,
    },
    paymentPlan: {
      cadence: null,
      planDuration: null,
      totalNumberOfPayments: null,
      amountPerPaymentPeriod: null,
      current: [],
      future: [],
      updatedFuture: [],
      pendingDeleted: [],
    },
    payments: [],
    eftaDetails: {
      customerName: null,
      email: null,
      cardLast4: null,
      fundingAccountLast4: null,
    },
  };
};

const recoveriesState = createReactiveState(initializeState);

const { setPropertyValue } = recoveriesState;

export const {
  resetState: resetRecoveriesState,
  stateVar: recoveriesStateVar,
  useState: useRecoveriesState,
} = recoveriesState;

const stepHandlers = createStepHandlers(
  recoveriesState,
  Object.values(RECOVERIES_STEPS),
);
export const { setNextStep } = stepHandlers;

export const goToPreviousStep = () => {
  const currentState = recoveriesStateVar();
  if (currentState?.steps.length === 2) {
    resetRecoveriesState();
  } else {
    stepHandlers.goToPreviousStep();
  }
};

export const { setFundingAccount } =
  createFundingAccountHandlers(recoveriesState);

export const setPaymentDates = (startDate, endDate) => {
  setPropertyValue('paymentDates', { startDate, endDate });
};

export const setPayments = ({ payments }) => {
  setPropertyValue('payments', payments);
};

export const setPaymentPlan = ({
  cadence,
  current,
  future,
  updatedFuture,
  pendingDeleted,
  planDuration,
}) => {
  const currentState = recoveriesStateVar();
  setPropertyValue('paymentPlan', {
    ...currentState.paymentPlan,
    cadence,
    current,
    ...(planDuration !== undefined && { planDuration }),
    ...(future && { future }),
    ...(updatedFuture && { updatedFuture }),
    ...(pendingDeleted && { pendingDeleted }),
  });

  if (currentState.offer.state !== SETTLEMENT_OFFER_STATES.IN_PROGRESS) {
    return setPropertyValue('offer', {
      ...currentState.offer,
      expiration: dayjs().add(currentState.offer?.duration, 'month'),
    });
  }
};

export const setOffer = ({
  balance,
  duration,
  expiration,
  offerId,
  offerName,
  offerType,
  settlementPercentage,
  offer = null,
}) => {
  if (offer) {
    const {
      acceptanceExpiration,
      duration,
      expiration,
      fundingAccountId,
      isDebitCardOffer,
      offerId,
      originalOutstandingBalance,
      originalSettlementBalance,
      paymentPlan,
      payments,
      remainingBalance,
      state,
      subType,
    } = offer.data;

    const createPaymentObject = (amount, date) => ({ amount, date });

    let currentPaymentPlan = [];
    let futurePayments = [];
    if (!paymentPlan && isDebitCardOffer) {
      const debitPaymentPlan = createPaymentObject(
        remainingBalance,
        // debit expiration is now created date + 1 day offer duration + extra 1 day padding
        // we want to display created date
        dayjs(expiration).subtract(2, 'day'),
      );
      currentPaymentPlan = [{ ...debitPaymentPlan, type: 'SCHEDULED DEBIT' }];
      futurePayments = [{ ...debitPaymentPlan, type: 'SCHEDULED DEBIT' }];
    } else if (paymentPlan) {
      currentPaymentPlan = paymentPlan?.transactions.map((transaction) =>
        createPaymentObject(transaction.amount, transaction.date),
      );

      futurePayments = paymentPlan?.transactions
        .filter((transaction) => dayjs(transaction.date).isAfter(dayjs()))
        .map((transaction) =>
          createPaymentObject(transaction.amount, transaction.date),
        );
    }

    const removeDuplicatePaymentTxIds = (payments) => {
      const paymentsByTxId = payments?.reduce((acc, payment) => {
        // payments-service recognizes a RETURNED payment immediately which determines the account's remaining balance
        // but there is an indeterminate time delay in cc-transactions to determine a RETURNED payment
        // that being the case, we need to trump RETURNED state from payments if it is not yet processed in cc-transactions
        if (!acc[payment.txId] || payment.type === PAYMENT_STATES.RETURNED) {
          acc[payment.txId] = payment;
        }
        return acc;
      }, {});

      return Object.values(paymentsByTxId);
    };

    let uniquePayments;
    if (payments) {
      uniquePayments = removeDuplicatePaymentTxIds(payments);
    }

    const currentState = recoveriesStateVar();
    return recoveriesStateVar({
      ...currentState,
      offer: {
        balance: {
          settlementBalance: originalSettlementBalance,
          outstandingBalance: originalOutstandingBalance,
          remainingBalance: remainingBalance,
        },
        acceptanceExpiration,
        duration,
        expiration: dayjs(expiration).subtract(1, 'day'),
        isDebitCard: isDebitCardOffer,
        offerId,
        offerName,
        offerType: offerType ?? subType,
        paymentPlanId: paymentPlan?.id,
        state,
      },
      fundingAccount: {
        ...currentState.fundingAccount,
        id: fundingAccountId,
      },
      paymentPlan: {
        ...currentState.paymentPlan,
        current: currentPaymentPlan,
        future: futurePayments,
      },
      payments: uniquePayments ?? payments,
    });
  }

  setPropertyValue('offer', {
    balance,
    duration,
    expiration,
    offerId,
    offerName,
    offerType,
    settlementPercentage: settlementPercentage ?? '100%',
  });
};

export const setEftaDetails = ({
  customerName,
  email,
  cardLast4,
  fundingAccountLast4,
}) => {
  setPropertyValue('eftaDetails', {
    customerName,
    email,
    cardLast4,
    fundingAccountLast4,
  });
};
