import { formatDollarsFromCents } from 'utilities/helpers';
import { PAYMENT_STATES, SETTLEMENT_OFFER_TYPES } from 'utilities/constants';

export const paymentsExceedOrUnderBalanceAlert = ({
  offer,
  paymentPlan,
  payments,
}) => {
  const { balance, state } = { ...offer };

  const isOfferSetup = !offer.state;
  const hasUpdatedFuturePayments = Boolean(paymentPlan?.updatedFuture?.length);

  const futurePayments = hasUpdatedFuturePayments
    ? paymentPlan?.updatedFuture
    : paymentPlan?.future;

  if (balance) {
    return getTotalPaymentsAndBalanceMsg({
      balance,
      futurePayments: isOfferSetup ? paymentPlan?.current : futurePayments,
      updatedFuturePayments: isOfferSetup
        ? paymentPlan?.current
        : futurePayments,
      payments,
      offer: { type: offer.offerType, state },
    });
  }
};

export const getTotalPaymentsAndBalanceMsg = ({
  balance,
  futurePayments = [],
  updatedFuturePayments = [],
  payments,
  offer,
}) => {
  const isOfferSetup = !offer.state;

  const scheduledPayments = updatedFuturePayments?.length
    ? updatedFuturePayments
    : futurePayments;

  const totalFuturePayments = scheduledPayments
    ?.filter((payment) => payment.status !== 'delete')
    ?.reduce((total, payment) => total + payment.amount, 0);

  const totalPastPayments = payments?.reduce((total, payment) => {
    const isPositivePayment =
      payment.type === PAYMENT_STATES.POSTED ||
      payment.type === PAYMENT_STATES.PENDING ||
      payment.type === PAYMENT_STATES.EXECUTED;

    if (isPositivePayment) {
      return total + payment.amount;
    }

    return total;
  }, 0);

  const totalPayments = (totalPastPayments ?? 0) + totalFuturePayments;

  const { remainingBalance, outstandingBalance, settlementBalance } =
    balance || {};

  // total higher than account balance
  if (totalPayments > outstandingBalance) {
    const diff = formatDollarsFromCents(totalPayments - outstandingBalance);
    return {
      type: 'error',
      message: (
        <>
          Oops! The total is higher than the entire account balance by
          <strong> {diff}</strong>. Please lower any of the other scheduled
          payments.
        </>
      ),
    };
  }

  // total higher than settlement balance
  if (
    offer.type === SETTLEMENT_OFFER_TYPES.SIF &&
    remainingBalance !== 0 &&
    totalPayments > settlementBalance
  ) {
    const diff = formatDollarsFromCents(totalPayments - settlementBalance);

    return {
      type: 'warning',
      message: (
        <>
          Great, the customer will be getting ahead! They’ll be overpaying by
          <strong> {diff}</strong>. You can adjust any payment, or the extra
          will be applied to the total outstanding balance of{' '}
          {formatDollarsFromCents(outstandingBalance)}.
        </>
      ),
    };
  }

  // total lower than remaining balance or settlement amount
  const comparisonBalance = isOfferSetup ? settlementBalance : remainingBalance;
  if (totalFuturePayments < comparisonBalance) {
    const diff = formatDollarsFromCents(
      comparisonBalance - totalFuturePayments,
    );
    return {
      type: 'error',
      message: (
        <>
          Oh no! The total is lower than the remaining balance by
          <strong> {diff}</strong>. Please increase any of the remaining
          payments to meet the remaining balance.
        </>
      ),
    };
  }
};

export const updatePaymentsWithPaymentState = (payments, accountPayments) => {
  const accountPaymentsByReference = accountPayments.reduce((acc, payment) => {
    // ach and debit payment transaction ids will always be 10 digits
    // but reference ids from cc-transactions can sometimes be prepended by many zeroes
    const reference = payment.reference.substring(
      payment.reference.length - 10,
    );
    acc[reference] = payment;
    return acc;
  }, {});

  const updatedPayments = payments?.map((payment) => {
    const matchingAccountPayment = accountPaymentsByReference[payment.txId];
    if (payment.type === PAYMENT_STATES.RETURNED) {
      return payment;
    }
    if (matchingAccountPayment) {
      return { ...payment, type: matchingAccountPayment.state };
    }
    return payment;
  });

  return updatedPayments;
};
