import { Link } from 'react-router-dom';
import {
  Button,
  Flex,
  Tag,
  Dropdown,
  Layout,
  Menu,
  Space,
  Segmented,
  Typography,
} from 'antd';
import {
  DownOutlined,
  MoonFilled,
  SunFilled,
  PhoneFilled,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AddNotes from 'components/AddNotes/AddNotes';
import useAgent from 'hooks/useAgent';
import useTheme from 'hooks/useTheme';
import useAWSConnectInfo from 'hooks/useAWSConnectInfo';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useModalControls from 'hooks/useModalControls';
import useNavigationKeyManager from 'hooks/useNavigationKeyManager';

import { useSoftphone } from './Softphone';

const StyledHeader = styled(Layout.Header)`
  border-bottom: var(--border-secondary);
  box-shadow: var(--box-shadow-tertiary);
  display: flex;
  height: var(--header-height);
  padding: 0 var(--spacing-lg);
  z-index: 5;
  width: 100%;

  .ant-menu-title-content {
    a {
      display: block;
      height: 100%;
      color: inherit;
    }
  }
`;

const HeaderMenu = styled(Menu)`
  &.ant-menu-horizontal {
    border-bottom: 0px;
    .ant-menu-title-content {
      display: block;
      line-height: var(--header-height);
      vertical-align: middle;
    }
  }
`;

const StyledSegmented = styled(Segmented)(({ color }) => {
  return `
    .ant-segmented-item-selected {
      .ant-segmented-item-icon {
        color: ${color}
      }
    }
  `;
});

const ThemeToggle = () => {
  const { isDark, toggleDark } = useTheme();
  const iconColor = isDark ? 'var(--teal-4)' : 'var(--orange-6)';
  return (
    <StyledSegmented
      color={iconColor}
      size="small"
      value={isDark}
      shape="round"
      onChange={() => {
        toggleDark();
      }}
      options={[
        { value: true, icon: <MoonFilled /> },
        { value: false, icon: <SunFilled /> },
      ]}
    />
  );
};

const getNavigationItems = (
  recentCustomers,
  recentApplications,
  isFdrComplaints,
) => {
  const customerSearchPath = '/customer-search';
  const customerNavigation = {
    key: 'customers',
    path: '/customers',
    label: <Link to={customerSearchPath}>Customers</Link>,
    children: [
      {
        label: <Link to={customerSearchPath}>Search Customers</Link>,
        key: 'customer-search',
        path: customerSearchPath,
      },
      {
        type: 'group',
        label: `Recently Viewed Customers`,
        children: recentCustomers.map((recentCustomer) => {
          const customerPath = `/customers/${recentCustomer.id}`;
          return {
            label: <Link to={customerPath}>{recentCustomer.label}</Link>,
            key: customerPath,
            path: customerPath,
          };
        }),
      },
    ],
  };

  const applicationSearchPath = '/application-search';
  const applicationNavigation = {
    key: 'applications',
    path: '/applications',
    label: <Link to={applicationSearchPath}>Applications</Link>,
    children: [
      {
        label: <Link to={applicationSearchPath}>Search Applications</Link>,
        key: 'application-search',
        path: applicationSearchPath,
      },
      {
        type: 'group',
        label: `Recently Viewed Applicants`,
        children: recentApplications.map((recentApplication) => {
          const applicationPath = `/applications/${recentApplication.id}`;
          return {
            label: <Link to={applicationPath}>{recentApplication.label}</Link>,
            key: applicationPath,
            path: applicationPath,
          };
        }),
      },
    ],
  };

  const navigationItems = [customerNavigation, applicationNavigation];

  if (isFdrComplaints) {
    navigationItems.push({
      label: <Link to="/fraud-files-search">Fraud Files Search</Link>,
      key: 'fraudFilesSearch',
      path: '/fraud-files-search',
      exact: 'true',
    });
  }

  return navigationItems;
};

const Header = () => {
  const bulkNoteModal = useModalControls();
  const agent = useAgent();
  const { softphoneOpen, toggleSoftphoneOpen } = useSoftphone();
  const { recentCustomers, recentApplications } = useCustomerInfo();
  const { agentStatusColors } = useAWSConnectInfo();
  const { isFdrComplaints } = useFlags();

  const navigationItems = getNavigationItems(
    recentCustomers,
    recentApplications,
    isFdrComplaints,
  );

  const [activeRouteKey] = useNavigationKeyManager(navigationItems);

  const getUserBadge = () => {
    // Look int refactoring this. Dropdown is awkward here. Bulk notes should be moved to the notes pane.
    return agent.isSuperUser ? (
      <div>
        <Dropdown
          menu={{
            items: [
              {
                label: 'Add bulk note',
                onClick: bulkNoteModal.show,
              },
            ],
          }}
          trigger={['click']}
        >
          <Tag
            color={'var(--blue-4)'}
            style={{ cursor: 'pointer', marginRight: 0 }}
          >
            Super User
            <DownOutlined style={{ marginLeft: 'var(--spacing-xs)' }} />
          </Tag>
        </Dropdown>
      </div>
    ) : agent.isCommandManager ? (
      <Tag color={'var(--green-4)'}>Command Manager</Tag>
    ) : agent.hasAccess ? (
      <Tag color={'var(--green-4)'}>Agent</Tag>
    ) : (
      <Tag color={'var(--red-4)'}>No Access</Tag>
    );
  };

  return (
    <StyledHeader>
      <Flex
        style={{
          marginRight: 'var(--spacing-sm)',
          backgroundColor: 'var(--purple-3)',
        }}
      >
        <Typography.Title
          level={5}
          style={{
            margin: 'auto 0',
            padding: '0 var(--spacing-sm)',
            lineHeight: 0.9,
            color: 'var(--color-text-light)',
          }}
        >
          Mission
          <br /> Ctrl
        </Typography.Title>
      </Flex>

      <HeaderMenu
        mode="horizontal"
        theme="light"
        style={{ flexGrow: 2 }}
        selectedKeys={activeRouteKey}
        items={navigationItems}
      />

      <Flex gap="var(--spacing)" align="center">
        {getUserBadge()}
        <ThemeToggle />
        <Space.Compact>
          <Button
            size="small"
            disabled
            style={{
              backgroundColor: agentStatusColors.background,
              borderColor: agentStatusColors.background,
              cursor: 'auto',
            }}
          >
            <PhoneFilled
              style={{
                fontSize: '1.2rem',
                color: agentStatusColors.foreground,
              }}
              color={agentStatusColors.foreground}
            />
          </Button>
          <Button onClick={toggleSoftphoneOpen} size="small">
            {softphoneOpen ? 'Hide' : 'Show'}
          </Button>
        </Space.Compact>
      </Flex>
      <AddNotes {...bulkNoteModal} />
    </StyledHeader>
  );
};

export default Header;
