import dayjs from 'dayjs';
import { Card, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import { formatDollarsFromCents, getDefaultPayments } from 'utilities/helpers';
import { formatDate } from 'utilities/datesAndTimes';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';

import { useRecoveriesState } from './recoveriesState';

const { Text, Paragraph } = Typography;

const EstimatedPayments = ({ loadingQuery }) => {
  const { offer, paymentCadence, paymentDates, paymentPlan } =
    useRecoveriesState();

  const currentPaymentPlan = paymentPlan.current;
  const isPifOffer = offer?.offerType === SETTLEMENT_OFFER_TYPES.PIF;
  const balanceText = isPifOffer ? 'Balance' : 'Settlement Balance';

  const allPaymentPlanDetailsFilled = isPifOffer
    ? !!(
        paymentPlan?.cadence &&
        paymentDates?.startDate &&
        paymentPlan?.planDuration
      )
    : !!(paymentPlan?.cadence && paymentDates?.startDate);

  const durationMonths = paymentPlan?.planDuration ?? offer?.duration;

  const estimatedPayments = getDefaultPayments(
    offer.balance.settlementBalance,
    dayjs(),
    dayjs().add(durationMonths, 'month').add(1, 'day'),
    durationMonths,
  );

  const startDateText = (
    <>
      <Paragraph>
        {balanceText}:{' '}
        {formatDollarsFromCents(offer?.balance.settlementBalance)}
        <br />
        <strong>Plan Due Date: {formatDate(dayjs(offer?.expiration))}</strong>
        <br />
        --
      </Paragraph>
      <Paragraph>
        <CalendarOutlined /> <strong>With a start date of today</strong>
        <br />
        {currentPaymentPlan?.length ? '' : ' the customer would owe about:'}
      </Paragraph>
    </>
  );

  const estimatedPaymentsText = (
    <Text>
      {startDateText}
      <strong>
        {formatDollarsFromCents(estimatedPayments.monthly)} | monthly
      </strong>
      , over {durationMonths} months
      <br />
      <strong>
        {formatDollarsFromCents(estimatedPayments.bimonthly)} | every 2 weeks
      </strong>
      , over {durationMonths} months
      <br />
      <strong>
        {formatDollarsFromCents(estimatedPayments.weekly)} | weekly
      </strong>
      , over {durationMonths} months
    </Text>
  );

  const calculatedPaymentsText = (
    <Text>
      {startDateText}
      <QueryBoundary
        query={{ loading: loadingQuery, called: true, data: {} }}
        skeleton={{
          title: false,
          paragraph: { rows: 1 },
        }}
      >
        {paymentCadence?.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME ? (
          <strong>
            Offer consists of one payment of{' '}
            {currentPaymentPlan?.[0]?.amount ?? '--'}
          </strong>
        ) : (
          <>
            <strong>
              Offer consists of {currentPaymentPlan?.length} payments of about:
            </strong>
            <br />
            {formatDollarsFromCents(currentPaymentPlan?.[0]?.amount) ?? '--'} |
            over {durationMonths} months
          </>
        )}
      </QueryBoundary>
    </Text>
  );

  return (
    <Card type="inner" title="Estimated Payments Calculator">
      {!allPaymentPlanDetailsFilled
        ? estimatedPaymentsText
        : calculatedPaymentsText}
    </Card>
  );
};

export default EstimatedPayments;
