import dayjs from 'dayjs';

import {
  createReactiveState,
  createAddressReviewHandlers,
  createStepHandlers,
  createSubmissionHandlers,
  getAddressReviewProperties,
  getStepProperties,
  getSubmissionProperties,
} from 'utilities/stateHelpers';

import AccountStandingStep from './AccountStandingStep';
import AddressReviewStepWrapper from './AddressReviewStepWrapper';
import CardStatusStep from './CardStatusStep';
import CardLossDetailsStep from './CardLossDetailsStep';
import FraudReviewStep from './FraudReviewStep';
import SubmissionStep from './SubmissionStep';
import StepLabel from 'components/StepLabel/StepLabel';

export const FRAUDULENT_ACTIVITY_STATUS = Object.freeze({
  YES: 'yes',
  NO: 'no',
  REPORTED: 'reported',
});

const ACCOUNT_STANDING_STEP = {
  title: 'Account Standing Review',
  component: AccountStandingStep,
  renderPreprocess: () => {
    const { accountInGoodStanding } = sendCardStateVar();
    const title = 'Verifying account standing';
    if (accountInGoodStanding === false) {
      return <StepLabel status="blocked" title={title} />;
    }
    return <StepLabel title={title} />;
  },
  renderPostprocess: () => {
    return <StepLabel status="positive" title="Account is in good standing" />;
  },
};

const ADDRESS_REVIEW_STEP = {
  title: 'Address Review',
  component: AddressReviewStepWrapper,
  renderPreprocess: () => {
    return <StepLabel title="Reviewing customer address" />;
  },
  renderPostprocess: () => {
    const { addressIsCurrent } = sendCardStateVar();
    const title = addressIsCurrent ? 'Address is current' : 'Address updated';
    return <StepLabel status="positive" title={title} />;
  },
};

const CARD_LOSS_DETAILS_STEP = {
  title: 'Card Loss Details',
  component: CardLossDetailsStep,
  renderPreprocess: () => {
    return <StepLabel title="Collecting card loss details" />;
  },
  renderPostprocess: () => {
    return <StepLabel status="positive" title="Card loss details collected" />;
  },
};

const CARD_STATUS_STEP = {
  title: 'Card Status',
  component: CardStatusStep,
  renderPreprocess: () => {
    return <StepLabel title="Confirming card status" />;
  },
  renderPostprocess: () => {
    const { customerHasCard } = sendCardStateVar();
    return customerHasCard ? (
      <StepLabel status="positive" title="Customer is in possession of card" />
    ) : (
      <StepLabel status="warning" title="Card has been lost or stolen" />
    );
  },
};

const FRAUD_REVIEW_STEP = {
  title: 'Fraud Review',
  component: FraudReviewStep,
  renderPreprocess: () => {
    const { fraudulentActivityStatus } = sendCardStateVar();
    if (fraudulentActivityStatus === FRAUDULENT_ACTIVITY_STATUS.YES) {
      return <StepLabel status="blocked" />;
    }
    return <StepLabel title="Reviewing purchases for fraudulent activity" />;
  },
  renderPostprocess: () => {
    return (
      <StepLabel
        status="positive"
        title="Account is free of recent fraudulent activity"
      />
    );
  },
};

const SUBMISSION_STEP = {
  title: 'Submit Send Card Request',
  component: SubmissionStep,
  renderPreprocess: () => {
    const { submissionCompleted } = sendCardStateVar();
    return submissionCompleted ? (
      <StepLabel status="positive" title="Card requested" />
    ) : (
      <StepLabel title="Complete send card request" />
    );
  },
  renderPostprocess: () => {},
};

export const SEND_CARD_STEPS = Object.freeze({
  ACCOUNT_STANDING: ACCOUNT_STANDING_STEP,
  ADDRESS_REVIEW: ADDRESS_REVIEW_STEP,
  CARD_LOSS_DETAILS: CARD_LOSS_DETAILS_STEP,
  CARD_STATUS: CARD_STATUS_STEP,
  FRAUD_REVIEW: FRAUD_REVIEW_STEP,
  SUBMISSION: SUBMISSION_STEP,
});

const initializeState = () => {
  return {
    ...getStepProperties(SEND_CARD_STEPS.CARD_STATUS),
    ...getAddressReviewProperties(),
    ...getSubmissionProperties(),
    accountInGoodStanding: undefined,
    cardLossDetails: {
      dateTimeDiscovered: dayjs(),
    },
    customerHasCard: undefined,
    expediteShipping: false,
    fraudulentActivityStatus: undefined,
    reissueDecline: false,
  };
};

const sendCardState = createReactiveState(initializeState);
const { createPropertySetter } = sendCardState;
export const {
  resetState: resetSendCardState,
  stateVar: sendCardStateVar,
  useState: useSendCardState,
} = sendCardState;

export const { setNextStep, goToPreviousStep } = createStepHandlers(
  sendCardState,
  Object.values(SEND_CARD_STEPS),
);

export const { setAddressIsCurrent, setAddressUpdated, setInitialAddressInfo } =
  createAddressReviewHandlers(sendCardState);

export const { setSubmissionInitiated, setSubmissionCompleted } =
  createSubmissionHandlers(sendCardState);

export const setAccountInGoodStanding = createPropertySetter(
  'accountInGoodStanding',
);

export const setCardLossDetails = createPropertySetter('cardLossDetails');

export const setCustomerHasCard = createPropertySetter('customerHasCard');

export const setExpediteShipping = createPropertySetter('expediteShipping');

export const setFraudulentActivityStatus = createPropertySetter(
  'fraudulentActivityStatus',
);

export const setReissueDecline = createPropertySetter('reissueDecline');
