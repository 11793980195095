import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, Table } from 'antd';

import FraudNewActionForm from './FraudNewActionForm';
import { formatDate, formatTime } from 'utilities/datesAndTimes';

const FraudCaseDetails = ({ cases }) => {
  const [addingNewAction, setAddingNewAction] = useState(false);
  const [selectedCase, setSelectedCase] = useState({});
  const { caseId } = useParams();

  useEffect(() => {
    setSelectedCase(cases.find((caseObj) => caseObj.caseHash === caseId));
  }, [caseId, cases]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'date',
      render: formatDate,
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'time',
      render: formatTime,
    },
    {
      title: 'Actor',
      dataIndex: 'actor',
      key: 'actor',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Evidence',
      dataIndex: 'evidence',
      key: 'evidence',
      render: (evidence) => {
        return evidence ? (
          <ul>
            {evidence.map(({ evidenceType }, i) => (
              <li key={i}>{evidenceType}</li>
            ))}
          </ul>
        ) : undefined;
      },
    },
    {
      title: 'Reasons',
      dataIndex: 'reasons',
      key: 'reasons',
      render: (reasons) => {
        return reasons ? (
          <ul>
            {reasons.map(({ name }, i) => (
              <li key={i}>{name}</li>
            ))}
          </ul>
        ) : undefined;
      },
    },
  ];

  return (
    <>
      {addingNewAction ? (
        <FraudNewActionForm
          selectedCase={selectedCase}
          setAddingNewAction={setAddingNewAction}
        />
      ) : (
        <Card
          title={`Case #${caseId}`}
          extra={
            <Link
              to={({ pathname }) => {
                let subRoute = /(\/.*)fraud/.exec(pathname);
                return subRoute ? subRoute[0] : pathname;
              }}
            >
              <Button>All Cases</Button>
            </Link>
          }
        >
          <Table
            columns={columns}
            dataSource={selectedCase?.details}
            bordered={true}
            pagination={false}
            size="small"
            rowKey="timestamp"
          />
          <Button
            type="primary"
            style={{ marginTop: '24px' }}
            onClick={() => setAddingNewAction(true)}
          >
            New Action
          </Button>
        </Card>
      )}
    </>
  );
};

export default FraudCaseDetails;
