import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Descriptions, Form, Modal, Radio, Space } from 'antd';
import { useMutation } from '@apollo/client';

import { EDIT_FUNDING_ACCOUNT } from 'mutations/fundingAccounts';
import { formatDate } from 'utilities/datesAndTimes';
import { ACCOUNT_STATUS, CHANNEL } from 'utilities/constants';

const EditFundingAccountModal = ({
  account,
  refetchQueries = [],
  hide,
  ...modalProps
}) => {
  const { customerId } = useParams();
  const [statusForm] = Form.useForm();
  const [showFailure, setShowFailure] = useState(false);

  const [editFundingAccount, editFundingAccountMutation] = useMutation(
    EDIT_FUNDING_ACCOUNT,
    {
      refetchQueries,
    },
  );

  const handleSave = async () => {
    setShowFailure(false);

    let formValues;
    try {
      formValues = await statusForm.validateFields();
    } catch {
      return;
    }

    try {
      await editFundingAccount({
        variables: {
          customerId,
          status: formValues.updatedStatus,
          fundingAccountId: String(account.id),
          channel: CHANNEL.AGENT,
        },
      });
      setTimeout(() => {
        hide();
      }, 2000);
    } catch (err) {
      setShowFailure(true);
    }
  };

  useEffect(() => {
    let accountStatus;
    if (account) {
      accountStatus = account.status;
    }
    statusForm.setFieldsValue({
      updatedStatus: accountStatus,
    });
  }, [account, statusForm]);

  return (
    <Modal
      {...modalProps}
      title="Edit Bank Account Status"
      onOk={handleSave}
      okText="Save"
      confirmLoading={editFundingAccountMutation.loading}
    >
      <div data-testid="modal-edit-bank-account">
        {account ? (
          <>
            <Descriptions
              column={1}
              bordered={true}
              size="small"
              style={{ marginBottom: 'var(--spacing)' }}
            >
              <Descriptions.Item label="Bank Name">
                {account.bankName}
              </Descriptions.Item>
              <Descriptions.Item label="Account Last Four">
                {account.numberLast4}
              </Descriptions.Item>
              <Descriptions.Item label="Routing Number">
                {account.routingNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Account Created">
                {formatDate(account.createdDate)}
              </Descriptions.Item>
            </Descriptions>

            <Form
              form={statusForm}
              initialValues={{ updatedStatus: '' }}
              layout="vertical"
            >
              <Form.Item
                label="Account Status"
                name="updatedStatus"
                rules={[
                  {
                    validator: (_, updatedStatus) =>
                      updatedStatus !== account.status
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error('Bank account status is already applied'),
                          ),
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical" size={0}>
                    <Radio
                      data-testid="option-non-operational"
                      value={ACCOUNT_STATUS.NON_OPERATIONAL}
                    >
                      Non-Operational
                    </Radio>
                    <Radio
                      data-testid="option-disabled"
                      value={ACCOUNT_STATUS.DISABLED}
                    >
                      Remove
                    </Radio>
                    <Radio
                      data-testid="option-enabled"
                      value={ACCOUNT_STATUS.ENABLED}
                    >
                      Enable
                    </Radio>
                    <Radio
                      data-testid="option-default"
                      value={ACCOUNT_STATUS.DEFAULT}
                    >
                      Default
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Form>
          </>
        ) : null}

        {showFailure && (
          <Alert
            banner
            type="error"
            message="Failed to update bank account status. Please try again."
          />
        )}
      </div>
    </Modal>
  );
};

export default EditFundingAccountModal;
