import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Flex, Form, Modal } from 'antd';
import dayjs from 'dayjs';

import client from 'apollo/client';

import AddFundingAccountModal from 'components/AddFundingAccountModal/AddFundingAccountModal';
import useModalControls from 'hooks/useModalControls';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { ONE_TIME_PAYMENT } from 'queries/payments';
import {
  CREATE_SETTLEMENT_PAYMENT_PLAN,
  UPDATE_SETTLEMENT_FUNDING_ACCOUNT,
} from 'mutations/settlements';
import { getFundingAccountName, getEftaText } from 'utilities/helpers';

import SelectFundingAccount from '../SelectFundingAccount';
import EFTAForm from './EFTAForm';
import {
  resetRecoveriesState,
  setFundingAccount,
  useRecoveriesState,
} from './recoveriesState';

const ChangeFundingAccount = ({ onSuccess, thirdParty }) => {
  const { customerId, accountId } = useParams();
  const { eftaDetails, fundingAccount, paymentPlan, offer } =
    useRecoveriesState();

  const [form] = Form.useForm();

  const { name: thirdPartyName, email: thirdPartyEmail } = thirdParty;
  const { cardLast4, customerName, email } = eftaDetails;

  const addFundingAccountModal = useModalControls();
  const eftaModal = useModalControls();

  const [localFundingAccountId, setLocalFundingAccountId] = useState('');
  const [localFundingAccountName, setLocalFundingAccountName] = useState('');

  const [allFundingAccounts, setAllFundingAccounts] = useState([]);

  const [emptyPaymentPlanError, setEmptyPaymentPlanError] = useState('');
  const [submitFundingAccountErrorMsg, setSubmitFundingAccountErrorMsg] =
    useState('');
  const [submitPaymentPlanErrorMsg, setSubmitPaymentPlanErrorMsg] =
    useState('');

  const [submitSuccessMsg, setSubmitSuccessMsg] = useTimeoutValue('', 3000);

  useQuery(ONE_TIME_PAYMENT, {
    notifyOnNetworkStatusChange: true,
    variables: { customerId, accountId },
    onCompleted: (data) => {
      setAllFundingAccounts(data?.fundingAccounts);
    },
  });

  const [submitNewFundingAccount, { loading: submitNewFundingAccountLoading }] =
    useMutation(UPDATE_SETTLEMENT_FUNDING_ACCOUNT, {
      onCompleted: () => {
        setFundingAccount(localFundingAccountId, localFundingAccountName);
      },
      onError: () => {
        setSubmitFundingAccountErrorMsg(
          'Failed to update new funding account. Please try again.',
        );
      },
    });

  const [createSettlementPaymentPlan, { loading: submitPaymentPlanLoading }] =
    useMutation(CREATE_SETTLEMENT_PAYMENT_PLAN, {
      onError: () => {
        setSubmitPaymentPlanErrorMsg(
          'Failed to create payment plan. Please try again.',
        );
      },
    });

  const onSelectFundingAccount = (accountId) => {
    const displayName = getFundingAccountName(allFundingAccounts, accountId);
    setLocalFundingAccountId(accountId);
    setLocalFundingAccountName(displayName);
  };

  const onFundingAccountConfirmation = async () => {
    await form.validateFields();

    form.resetFields();
    onSuccess && onSuccess();
    eftaModal.show();
  };

  const fundingAccountLast4 = localFundingAccountName?.substring(
    localFundingAccountName.length - 4,
    localFundingAccountName.length,
  );

  const onSubmitNewFundingAccount = async () => {
    setSubmitFundingAccountErrorMsg('');
    setSubmitPaymentPlanErrorMsg('');

    const updatedPaymentSchedule = paymentPlan?.updatedFuture
      ?.filter((payment) => payment.status !== 'delete')
      .map((payment) => ({
        amount: payment.amount,
        date: payment.date,
      }));

    const paymentSchedule = paymentPlan?.updatedFuture?.length
      ? paymentPlan?.updatedFuture
      : paymentPlan?.future;

    const eftaText = getEftaText({
      eftaDetails: { cardLast4, customerName, fundingAccountLast4, email },
      todaysDate: dayjs().format('L'),
      paymentPlanSchedule: paymentSchedule.length ? paymentSchedule : [],
    });

    const variables = {
      customerId,
      authorizationText: eftaText,
      fundingAccountId: localFundingAccountId,
      offerId: offer.offerId,
    };

    const doesNotHaveCurrentPaymentPlan = !paymentPlan?.updatedFuture?.every(
      (payment) => payment.type === 'add' || payment.type === 'edit',
    );

    const resetAfterSubmit = () => {
      form.resetFields();
      resetRecoveriesState();
      setTimeout(eftaModal.hide, 2500);
    };

    try {
      await submitNewFundingAccount({
        variables,
      });
    } catch (error) {
      return setSubmitFundingAccountErrorMsg(
        'Failed to submit new funding account. Please try again.',
      );
    }

    // we only want to create a new payment plan if there is no current payment plan
    if (doesNotHaveCurrentPaymentPlan) {
      if (!updatedPaymentSchedule.length) {
        return setEmptyPaymentPlanError(
          'Payment Plan cannot be empty. Please add payments and try again.',
        );
      }

      variables.transactions = updatedPaymentSchedule;

      try {
        await createSettlementPaymentPlan({
          variables,
        });
      } catch (error) {
        return setSubmitPaymentPlanErrorMsg(
          'Failed to create payment plan. Please try again using the Confirm New Schedule button.',
        );
      }
    }

    setSubmitSuccessMsg(
      'Funding account and payment plan updated successfully.',
    );
    resetAfterSubmit();
    await client.refetchQueries({
      include: ['GetSettlementOffers', 'MakePaymentQuery'],
    });
  };

  return (
    <>
      <Form
        form={form}
        name="fundingAccountForm"
        layout="vertical"
        initialValues={{
          fundingAccount: fundingAccount.id || null,
        }}
        onFinish={onFundingAccountConfirmation}
        data-testid="funding-account-form"
      >
        <SelectFundingAccount
          label="Bank Account"
          name="fundingAccount"
          fundingAccounts={allFundingAccounts}
          onChange={onSelectFundingAccount}
          fundingAccountId={localFundingAccountId}
          bankDisplayName={`${localFundingAccountName}`}
        />
        <Flex justify="flex-end" gap="var(--spacing-sm)">
          <Button onClick={addFundingAccountModal.show}>
            Add New Funding Account
          </Button>
          <Button type="primary" onClick={onFundingAccountConfirmation}>
            Confirm Funding Account
          </Button>
        </Flex>
        <AddFundingAccountModal
          {...addFundingAccountModal}
          onComplete={(newFundingAccount) => {
            const displayName = getFundingAccountName(
              allFundingAccounts,
              newFundingAccount?.id,
            );
            setLocalFundingAccountId(newFundingAccount?.id);
            setLocalFundingAccountName(displayName);
            setFundingAccount(newFundingAccount?.id, displayName);
          }}
          refetchQueries={['MakePaymentQuery']}
        />
      </Form>
      <Modal {...eftaModal} width={600} footer={[]} destroyOnClose={true}>
        <EFTAForm
          eftaDetails={{
            cardLast4,
            customerName: thirdPartyName ?? customerName,
            email: thirdPartyEmail ?? email,
            fundingAccountLast4,
          }}
          onFinish={onSubmitNewFundingAccount}
          mutation={{
            loading: submitNewFundingAccountLoading || submitPaymentPlanLoading,
            error: submitFundingAccountErrorMsg || submitPaymentPlanErrorMsg,
            success: submitSuccessMsg,
          }}
          resetErrorMessage={
            submitFundingAccountErrorMsg || setSubmitPaymentPlanErrorMsg
          }
          resetSuccessMessage={setSubmitSuccessMsg}
          paymentPlanError={[emptyPaymentPlanError, setEmptyPaymentPlanError]}
        />
      </Modal>
    </>
  );
};

export default ChangeFundingAccount;
