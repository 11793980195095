import { createContext, useContext, useEffect, useState } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { Divider, Flex } from 'antd';

// Intended for use inside of sidebars
export const SidebarDivider = styled(Divider)`
  margin: var(--spacing-sm) 0;
  padding-right: var(--spacing);
`;

export const SidebarContainer = styled.div`
  margin-right: var(--spacing);
`;

export const SidebarScrollContainer = ({ children }) => {
  return (
    <Flex
      style={{
        minHeight: '0px',
      }}
    >
      <div style={{ flex: 1, overflowY: 'scroll' }}>
        <SidebarContainer>{children}</SidebarContainer>
      </div>
    </Flex>
  );
};

// Sidebar implementation
const StyledSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
  padding-left: var(--spacing-lg);
`;

const SidebarContext = createContext([]);
const sidebarPortalVar = makeVar(false);

export const useSidebarHasContent = () => {
  return useReactiveVar(sidebarPortalVar);
};

export const SidebarProvider = (props) => {
  const sidebarState = useState(null);
  return <SidebarContext.Provider value={sidebarState} {...props} />;
};

const Sidebar = () => {
  const [, setSidebarContainer] = useContext(SidebarContext);
  const sidebarHasContent = useSidebarHasContent(sidebarPortalVar);

  return sidebarHasContent ? (
    <StyledSidebar>
      <Flex
        ref={setSidebarContainer}
        vertical={true}
        style={{ height: '100%' }}
      />
    </StyledSidebar>
  ) : null;
};

export const SidebarPortal = ({ children }) => {
  const [sideBarContainer] = useContext(SidebarContext);

  useEffect(() => {
    sidebarPortalVar(true);
    return () => {
      sidebarPortalVar(false);
    };
  }, []);

  return sideBarContainer ? createPortal(children, sideBarContainer) : null;
};

export default Sidebar;
