import { Redirect, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import NoteBox from 'components/NoteBox/NoteBox';
import ApplicationLayout from 'layouts/ApplicationLayout/ApplicationLayout';
import CustomerDirectLinkSearch from 'components/CustomerDirectLinkSearch/CustomerDirectLinkSearch';
import CustomerLayout from 'layouts/CustomerLayout/CustomerLayout';
import CustomerSearchPage from 'pages/CustomerSearchPage/CustomerSearchPage';
import ApplicationSearchPage from 'pages/ApplicationSearchPage/ApplicationSearchPage';
import FraudFilesSearchPage from 'pages/FraudFilesSearchPage/FraudFilesSearchPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import ThemeTestPage from 'pages/ThemeTestPage/ThemeTestPage';

import { SentryRoute } from 'services/sentry';
import { styledWithTransientProps } from 'utilities/styles';

import Header from './Header';
import Softphone, { useSoftphone } from './Softphone';
import Sidebar, { SidebarProvider, useSidebarHasContent } from './Sidebar';

const Grid = styledWithTransientProps('div')(({
  $isSearchPage,
  $sidebarHasContent,
  $softphoneOpen,
}) => {
  const contentBackgroundColor = $isSearchPage
    ? 'var(--color-background)'
    : 'var(--neutral-1)';

  const sidebarWidth = $sidebarHasContent ? 'var(--sidebar-width)' : '0px';
  const softphoneWidth = $softphoneOpen ? 'var(--softphone-width)' : '0px';

  return `
      height: 100vh;
      display: grid;
      grid-template-areas:
        "header header header"
        "sidebar content softphone";
      grid-template-columns: ${sidebarWidth} 1fr ${softphoneWidth};
      grid-template-rows: var(--header-height) 1fr;
      transition: all .3s ease;

      .header-container {
        grid-area: header;
        z-index: 1;
      }

      .content-container {
        background-color: ${contentBackgroundColor};
        grid-area: content;
        overflow-y: scroll;
        padding: var(--spacing-sm);
      }

      .sidebar-container {
        grid-area: sidebar;
        background-color: var(--color-background);
        border-right: var(--border-secondary);
        overflow-x: hidden;
      }

      .softphone-container {
        grid-area: softphone;
        background-color: var(--color-background);
        border-left: var(--border-secondary);
        overflow-x: hidden;
      }
    `;
});

const DashboardLayout = () => {
  const sidebarHasContent = useSidebarHasContent();
  const { softphoneOpen } = useSoftphone();

  // Temporary.
  // Account/application pages would need some layout changes befor things would look good.
  // so it's easiest to just keep the background grey and make limited changes.
  const isSearchPage = useRouteMatch('/*-search');

  return (
    <SidebarProvider>
      <Grid
        $isSearchPage={isSearchPage}
        $sidebarHasContent={sidebarHasContent}
        $softphoneOpen={softphoneOpen}
      >
        <div className="header-container">
          <Header />
        </div>

        <div className="sidebar-container">
          <Sidebar />
        </div>

        <div className="softphone-container">
          <Softphone />
        </div>

        <div className="content-container">
          <Switch>
            <Redirect exact from="/" to="/customers" />
            <Redirect exact from="/customers/" to="/customer-search" />
            <Redirect exact from="/applications/" to="/application-search" />

            <SentryRoute
              exact
              path="/customer-search"
              component={CustomerSearchPage}
            />

            <SentryRoute
              exact
              path="/application-search"
              component={ApplicationSearchPage}
            />

            <SentryRoute
              path="/fraud-files-search"
              exact
              component={FraudFilesSearchPage}
            />

            <SentryRoute
              path="/search-email"
              exact
              component={CustomerDirectLinkSearch}
            />

            <SentryRoute
              path="/applications/:applicationId"
              component={ApplicationLayout}
            />
            <SentryRoute
              path="/customers/:customerId"
              component={CustomerLayout}
            />

            <SentryRoute path="/themetest" component={ThemeTestPage} />

            <SentryRoute component={NotFoundPage} />
          </Switch>
        </div>
      </Grid>
      <NoteBox />
    </SidebarProvider>
  );
};

export default DashboardLayout;
