import { Row, Descriptions } from 'antd';

import { formatDate } from 'utilities/datesAndTimes';
import { currencyFormatter, formatDollarsFromCents } from 'utilities/helpers';

const { Item } = Descriptions;

const StyledDescriptions = ({ children, style, labelStyle, ...rest }) => {
  return (
    <Descriptions
      {...rest}
      bordered={true}
      column={1}
      size="small"
      style={{ width: '100%', ...style }}
      labelStyle={{ width: '50%', ...labelStyle }}
    >
      {children}
    </Descriptions>
  );
};

const StatementMeta = ({ data }) => {
  if (!data || !data.statementSummary) {
    return <></>;
  }

  const { statementSummary: summary } = data;

  return (
    <>
      <Row gutter={[5, 5]}>
        <StyledDescriptions title="Summary of Account Activity">
          <Item label="Previous Balance">
            {formatDollarsFromCents(summary.balanceForward)}
          </Item>
          <Item label="Payments">
            {formatDollarsFromCents(summary.payments)}
          </Item>
          <Item label="Other Credits">
            {formatDollarsFromCents(summary.otherCredits)}
          </Item>
          <Item label="Other Debits">
            {formatDollarsFromCents(summary.otherDebits)}
          </Item>
          <Item label="Finance Charges">
            {formatDollarsFromCents(summary.totalFinanceCharge)}
          </Item>
          <Item label="New Balance">
            {formatDollarsFromCents(summary.totalNewBalance)}
          </Item>
        </StyledDescriptions>
        <StyledDescriptions style={{ margin: '1rem 0' }}>
          <Item label="Credit Limit">
            {currencyFormatter(summary.creditLimit)}
          </Item>
          <Item label="Statement Closing Date">
            {formatDate(summary.periodEndDate)}
          </Item>
        </StyledDescriptions>
        <StyledDescriptions title="Payment Information">
          <Item label="Total Minimum Payment Due">
            {currencyFormatter(summary.minimumDue)}
          </Item>
          <Item label="Payment Due Date">
            {formatDollarsFromCents(summary.paymentDueDate)}
          </Item>
        </StyledDescriptions>
      </Row>
    </>
  );
};

export default StatementMeta;
