import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Checkbox, Form } from 'antd';
import { useMutation } from '@apollo/client';

import StepContainer from '../StepContainer';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import { CREATE_OFFER_WITHOUT_PAYMENT_PLAN } from 'mutations/settlements';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { formatDollarsFromCents } from 'utilities/helpers';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
  SIZE,
  STATUS,
} from 'utilities/constants';
import {
  RECOVERIES_STEPS,
  resetRecoveriesState,
  setNextStep,
  useRecoveriesState,
} from './recoveriesState';
const { Item } = Form;

const DisclosureStep = ({ settlementsQuery, thirdParty }) => {
  const { accountId } = useParams();
  const { offer, paymentPlan } = useRecoveriesState();

  const [createOfferSuccess, setCreateOfferSuccess] = useTimeoutValue(
    false,
    3000,
  );
  const [createOfferError, setCreateOfferError] = useState(false);

  const [form] = Form.useForm();

  const onCreateOfferCompletion = () => {
    setCreateOfferSuccess(true);
    setCreateOfferError(false);
    setTimeout(() => {
      resetRecoveriesState();
      settlementsQuery.refetch();
    }, 2500);
  };

  const [
    createOfferWithoutPaymentPlan,
    { loading: createOfferWithoutPaymentPlanLoading },
  ] = useMutation(CREATE_OFFER_WITHOUT_PAYMENT_PLAN, {
    onCompleted: () => onCreateOfferCompletion(),
    onError: () => setCreateOfferError(true),
  });

  const isOptionalPaymentPlan = offer.offerType?.includes(
    'OptionalPaymentPlan',
  );

  const onFinish = async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    if (isOptionalPaymentPlan) {
      return await createOfferWithoutPaymentPlan({
        variables: { accountId, selectedOfferId: offer.offerId },
      });
    }

    setNextStep(RECOVERIES_STEPS.EFTA);
  };

  const isPifOffer = offer.offerType?.includes(SETTLEMENT_OFFER_TYPES.PIF);
  const settlementOrProgram = isPifOffer ? 'program' : 'settlement';
  const debtPaidStatus = isPifOffer ? 'Paid in Full' : 'Settled in Full';

  const getPaymentMethodText = () => {
    const isOneTimeDebit =
      paymentPlan.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT;
    const settlementBalance = formatDollarsFromCents(
      offer.balance.settlementBalance,
    );

    if (isOneTimeDebit) {
      return `a Debit Card ${settlementOrProgram} of ${settlementBalance}.`;
    }
    if (isOptionalPaymentPlan) {
      return `a ${settlementOrProgram} of ${settlementBalance}, due by ${offer.expiration.format('LL')}.`;
    }

    return `an ACH ${settlementOrProgram} of ${settlementBalance}.`;
  };

  return (
    <StepContainer
      title="Read Program Disclosures"
      onContinue={onFinish}
      buttonProps={{
        disabled: createOfferWithoutPaymentPlanLoading,
        loading: createOfferWithoutPaymentPlanLoading,
      }}
    >
      <Form name="disclosures" form={form} layout="vertical">
        <Item
          name="disclosure1"
          valuePropName="checked"
          rules={[
            {
              /* eslint-disable no-unused-vars */
              validator: (_, value) => {
                return new Promise((resolve, reject) => {
                  if (!value) {
                    reject();
                  }
                  resolve();
                });
              },
              message: 'Customer must acknowledge and accept to continue.',
            },
          ]}
        >
          <Checkbox
            data-testid="disclosure1"
            style={{
              textAlign: 'justify',
              textJustify: 'inter-word',
            }}
          >
            You’re accepting <strong>{getPaymentMethodText()}</strong> Once the{' '}
            {settlementOrProgram} balance is paid off, it will take up to 10
            business days to consider the account{' '}
            <strong>{debtPaidStatus}</strong>, 10 business days to update the
            account status, and up to 30 days from the following statement cycle
            for the credit bureaus to adjust the credit reporting for the
            account. Failure to pay the total {settlementOrProgram} amount by
            the {!isOptionalPaymentPlan ? 'final' : ''} due date will result in
            the {settlementOrProgram} being canceled and the entire remaining
            balance on the account being due at that point.
          </Checkbox>
        </Item>
        {!isPifOffer && (
          <Item
            name="disclosure2"
            valuePropName="checked"
            rules={[
              {
                /* eslint-disable no-unused-vars */
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (!value) {
                      reject();
                    }
                    resolve();
                  });
                },
                message: 'Customer must acknowledge and accept to continue.',
              },
            ]}
          >
            <Checkbox
              data-testid="disclosure2"
              style={{
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              If you make a payment that’s more than the settlement amount,
              we’ll{' '}
              <strong>
                apply the remainder to the outstanding balance on the account.
              </strong>{' '}
              There may be tax consequences as a result of a settlement, so
              please consult a tax professional if you have questions or need
              tax advice.{' '}
              {thirdParty.email
                ? ''
                : 'If you change your mailing address before the next tax year, please also update your address with us.'}
            </Checkbox>
          </Item>
        )}
        {createOfferError && (
          <Alert
            banner
            type="error"
            message={'Error creating program. Please try again.'}
            style={{ marginTop: 'var(--spacing-sm)' }}
          />
        )}
        {createOfferSuccess ? (
          <SectionMessage
            data-testid="settlements-success-message"
            cover={true}
            status={STATUS.SUCCESS}
            size={SIZE.MD}
            text="Program successfully created!"
            visible={createOfferSuccess}
          />
        ) : null}
      </Form>
    </StepContainer>
  );
};

export default DisclosureStep;
