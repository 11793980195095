import { Flex, Skeleton, Typography } from 'antd';
import { CopyOutlined, LikeOutlined } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';

const AccountTitleContainer = styled('div')(({ level }) => {
  let skeletonHeight = `var(--heading-height-${level})`;

  return `
    margin: calc(var(--spacing-lg) * -1);
    margin-bottom: 0;
    padding: var(--spacing-sm) var(--spacing-lg);
    border-bottom: var(--border-secondary);
    .ant-typography {
      margin: 0;
    }
    .ant-skeleton-title {
      margin: 0;
      height: ${skeletonHeight};
    }
    .tsys-id {
      line-height: ${skeletonHeight};
    }
  `;
});

const AccountTitle = ({
  children,
  level = 5,
  loading,
  tsysAccountId,
  tsysCustomerId,
  cardLast4,
}) => {
  const { showTsysCustomerId } = useFlags();
  const showCopyIcon = cardLast4
    ? {
        text: `${cardLast4}`,
        tooltips: [
          'Copy Card Last 4',
          <span key="copied-message">
            Card Last 4 Copied <LikeOutlined />
          </span>,
        ],
        icon: [<CopyOutlined />, <CopyOutlined disabled={true} />],
      }
    : false;

  return (
    <AccountTitleContainer level={level}>
      <Flex justify="space-between" gap="var(--spacing)">
        {loading ? (
          <Skeleton loading={true} active={true} paragraph={false}></Skeleton>
        ) : (
          <Typography.Title
            className="account-name"
            level={level}
            copyable={showCopyIcon}
          >
            {children}
          </Typography.Title>
        )}
        <Flex gap={'var(--spacing-sm)'} align="flex-end">
          {tsysAccountId ? (
            <Typography.Text
              className="tsys-account-id"
              copyable={{
                text: `${tsysAccountId}`,
                tooltips: [
                  'Copy TSYS Account ID',
                  <span key="copied-message">
                    Copied TSYS Account ID <LikeOutlined />
                  </span>,
                ],
                icon: [<CopyOutlined />, <CopyOutlined disabled={true} />],
              }}
            >
              TSYS AccountID: {tsysAccountId}
            </Typography.Text>
          ) : null}
          {showTsysCustomerId && tsysCustomerId ? (
            <Typography.Text
              className="tsys-customer-id"
              copyable={{
                text: `${tsysCustomerId}`,
                tooltips: [
                  'Copy TSYS Customer ID',
                  <span key="copied-message">
                    Copied TSYS Customer ID <LikeOutlined />
                  </span>,
                ],
                icon: [<CopyOutlined />, <CopyOutlined disabled={true} />],
              }}
            >
              TSYS CustomerID: {tsysCustomerId}
            </Typography.Text>
          ) : null}
        </Flex>
      </Flex>
    </AccountTitleContainer>
  );
};

export default AccountTitle;
