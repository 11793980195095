import { lazy } from 'react';

import StepLabel from 'components/StepLabel/StepLabel';

import {
  createReactiveState,
  createAddressReviewHandlers,
  createFundingAccountHandlers,
  createStepHandlers,
  createSubmissionHandlers,
  getAddressReviewProperties,
  getFundingAccountProperties,
  getStepProperties,
  getSubmissionProperties,
} from 'utilities/stateHelpers';

import AddressReviewStepWrapper from './AddressReviewStepWrapper';
import BadCheckInquiryStep from './BadCheckInquiryStep';
import FundingAccountManagementStep from './FundingAccountManagementStep';
import SubmissionStep from './SubmissionStep';

const ADDRESS_REVIEW_STEP = {
  title: 'Address Review',
  component: AddressReviewStepWrapper,
  renderPreprocess: () => {
    return <StepLabel title="Reviewing customer address" />;
  },
  renderPostprocess: () => {
    const { addressIsCurrent } = creditBalanceRefundStateVar();
    return (
      <StepLabel
        status="positive"
        title={addressIsCurrent ? 'Address is current' : 'Address updated'}
      />
    );
  },
};

const BAD_CHECKS_INQUIRY_STEP = {
  title: 'Bad Checks Inquiry',
  component: BadCheckInquiryStep,
  renderPreprocess: () => {
    const { badCheckExists } = creditBalanceRefundStateVar();
    const title = 'Bad check inquiry';
    if (badCheckExists) {
      return <StepLabel status="blocked" title={title} />;
    }
    return <StepLabel title={title} />;
  },
  renderPostprocess: () => {
    return <StepLabel status="positive" title="No bad checks" />;
  },
};

const FUNDING_ACCOUNT_MANAGEMENT_STEP = {
  title: 'Manage Non-valid Funding Accounts',
  component: FundingAccountManagementStep,
  renderPreprocess: () => {
    const { fundingAccountVerified } = creditBalanceRefundStateVar();
    const title = 'Managing funding account';
    if (fundingAccountVerified === false) {
      return <StepLabel status="blocked" title={title} />;
    }
    return <StepLabel title={title} />;
  },
  renderPostprocess: () => {
    return <StepLabel status="positive" title="Funding accounts verified" />;
  },
};

const SUBMISSION_STEP = {
  title: 'Request Credit Balance Refund',
  component: SubmissionStep,
  renderPreprocess: () => {
    const { submissionCompleted } = creditBalanceRefundStateVar();
    return submissionCompleted ? (
      <StepLabel status="positive" title="Refund requested" />
    ) : (
      <StepLabel title="Request refund" />
    );
  },
  renderPostprocess: () => {},
};

export const CREDIT_BALANCE_REFUND_STEPS = Object.freeze({
  ADDRESS_REVIEW_STEP: ADDRESS_REVIEW_STEP,
  BAD_CHECKS_INQUIRY_STEP: BAD_CHECKS_INQUIRY_STEP,
  FUNDING_ACCOUNT_MANAGEMENT_STEP: FUNDING_ACCOUNT_MANAGEMENT_STEP,
  SUBMISSION_STEP: SUBMISSION_STEP,
});

export const CREDIT_BALANCE_REFUND_STEPS_LIST = [
  CREDIT_BALANCE_REFUND_STEPS.FUNDING_ACCOUNT_MANAGEMENT_STEP,
  CREDIT_BALANCE_REFUND_STEPS.ADDRESS_REVIEW_STEP,
  CREDIT_BALANCE_REFUND_STEPS.BAD_CHECKS_INQUIRY_STEP,
  CREDIT_BALANCE_REFUND_STEPS.SUBMISSION_STEP,
];

const initializeState = () => {
  return {
    ...getStepProperties(
      CREDIT_BALANCE_REFUND_STEPS.FUNDING_ACCOUNT_MANAGEMENT_STEP,
    ),
    ...getAddressReviewProperties(),
    ...getFundingAccountProperties(),
    ...getSubmissionProperties(),
    badCheckExists: undefined,
  };
};

const creditBalanceRefundState = createReactiveState(initializeState);
const { createPropertySetter } = creditBalanceRefundState;
export const {
  resetState: resetCreditBalanceRefundState,
  stateVar: creditBalanceRefundStateVar,
  useState: useCreditBalanceRefundState,
} = creditBalanceRefundState;

export const { setNextStep, goToPreviousStep } = createStepHandlers(
  creditBalanceRefundState,
  Object.values(CREDIT_BALANCE_REFUND_STEPS),
);

export const { setAddressIsCurrent, setAddressUpdated, setInitialAddressInfo } =
  createAddressReviewHandlers(creditBalanceRefundState);

export const { setSubmissionInitiated, setSubmissionCompleted } =
  createSubmissionHandlers(creditBalanceRefundState);

export const { setFundingAccount } = createFundingAccountHandlers(
  creditBalanceRefundState,
);

export const setBadCheckExists = createPropertySetter('badCheckExists');
