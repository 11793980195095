import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Divider, Flex, Radio, Space, Typography } from 'antd';
import Icon, {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import StepContainer from '../StepContainer';
import {
  setSubmissionCompleted,
  setSubmissionInitiated,
  resetSendCardState,
  useSendCardState,
} from './sendCardState';

import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAgent from 'hooks/useAgent';
import useAnalytics from 'hooks/useAnalytics';
import {
  formatDateToSubmitIsoUtcOffset,
  isWithinSixMonths,
} from 'utilities/datesAndTimes';
import {
  ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE,
  ANALYTICS_EVENTS,
  REQUEST_REASON,
  STATUS,
  SIZE,
} from 'utilities/constants';

import {
  GET_ACCOUNT_CARD_LAST_4,
  GET_ACCOUNT_CARD_NUMBER,
} from 'queries/account';
import {
  ACCOUNT_CARD_ALERT,
  REPLACE_ACCOUNT_CARD,
  TRANSFER_ACCOUNT_CARD,
} from 'mutations/sendCard';

const { Paragraph, Text, Title } = Typography;

const SubmissionStep = ({ account = {}, contactInfo = {} }) => {
  const agent = useAgent();
  const { trackEvent } = useAnalytics();
  const { accountId, customerId } = useParams();
  const {
    customerHasCard,
    cardLossDetails,
    reissueDecline,
    submissionCompleted,
    submissionInitiated,
  } = useSendCardState();

  const [expediteShipping, setExpediteShipping] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [failureMessage, setFailureMessage] = useState();
  const [processingTransfer, setprocessingTransfer] = useState(false);

  // force reissue only if expiration date is within 6 months
  const forceReissue =
    Boolean(account?.cardDetails?.expirationDate) &&
    isWithinSixMonths(account?.cardDetails?.expirationDate);

  const requestType = useMemo(() => {
    return customerHasCard ? 'Replace' : 'Lost/Stolen';
  }, [customerHasCard]);

  const initialCardLast4 = useMemo(() => {
    return account?.cardDetails?.last4;
  }, [account]);

  const [getAccountCardLast4, cardLast4Query] = useLazyQuery(
    GET_ACCOUNT_CARD_LAST_4,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [getAccountCardNumber, cardNumberQuery] = useLazyQuery(
    GET_ACCOUNT_CARD_NUMBER,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [accountCardAlert, accountCardAlertQuery] =
    useMutation(ACCOUNT_CARD_ALERT);
  const [replaceAccountCard, replaceAccountCardQuery] =
    useMutation(REPLACE_ACCOUNT_CARD);
  const [transferAccountCard, transferAccountCardQuery] = useMutation(
    TRANSFER_ACCOUNT_CARD,
    {
      refetchQueries: ['GetCustomerOverview', 'GetCustomerWithFullAccounts'], //only calls on success
      onError: () => {
        /**
         * TSYS has a bug that often returns an error even on
         * successful transfer requests. This is a dummy error
         * handler to ensure the request flow continues.
         */
      },
    },
  );

  const endRequest = (success = false) => {
    trackProgress(ANALYTICS_EVENTS.SEND_CARD_REQUEST_END);
    setProcessing(false);
    setprocessingTransfer(false);
    if (success) {
      setSubmissionCompleted(true);
    }
  };

  const trackProgress = (event, data = {}) => {
    const defaultData = {
      requestType,
      customerId,
      accountId,
    };
    trackEvent(event, Object.assign(defaultData, data));
  };

  const handleFailure = (message, event, data = {}) => {
    if (event) {
      trackProgress(event, Object.assign({ success: false }, data));
    }
    setFailureMessage(message);
    endRequest();
  };

  const handleCardReplacement = async () => {
    if (!processing) {
      setProcessing(true);
    }

    const deliveryMethod = expediteShipping ? 'FEDX_EXP_NXTDAY' : 'NORMAL';
    try {
      await replaceAccountCard({
        variables: {
          accountId,
          customerId,
          numberOfCards: 1,
          deliveryMethod: expediteShipping ? 'FEDX_EXP_NXTDAY' : 'NORMAL',
          changeExpirationDate: forceReissue,
          expeditedFee: false,
          requestReason: customerHasCard
            ? REQUEST_REASON.SPECIAL
            : REQUEST_REASON.TRANSFER,
        },
      });
      trackProgress(ANALYTICS_EVENTS.SEND_CARD_REPLACEMENT, {
        success: true,
        deliveryMethod,
      });
    } catch (error) {
      handleFailure(
        "We've encountered an error while requesting a new card, please use Transfer to::Reissue Decline/SF Block Macro in Zendesk to have card sent.",
        ANALYTICS_EVENTS.SEND_CARD_REPLACEMENT,
        { deliveryMethod },
      );
      return;
    }

    endRequest(true);
  };

  const handleAccountTransfer = async () => {
    setProcessing(true);

    const phone = (contactInfo?.rawPhoneNumber || '').replace('+1', '');
    const { reason, dateTimeDiscovered, lostStolenCircumstances, reporter } =
      cardLossDetails;
    const genericError =
      'Please review the remediations steps within the appropriate Wiki on how to proceed.';

    if (!accountCardAlertQuery.data) {
      try {
        await accountCardAlert({
          variables: {
            accountId,
            customerId,
            reason,
            dateTimeDiscovered:
              formatDateToSubmitIsoUtcOffset(dateTimeDiscovered),
            dateTimeReported: formatDateToSubmitIsoUtcOffset(dayjs()),
            fraudOnAccount: true, // hardcoding per https://app.asana.com/0/1201913907706105/1208668745624849/f
            isCardLost: !customerHasCard,
            isPinLost: false, //double check on this
            lostStolenCircumstances,
            reporter,
            willAddOverride: false,
          },
        });

        trackProgress(ANALYTICS_EVENTS.SEND_CARD_SECURITY_REPORT, {
          success: true,
          reason,
        });
      } catch (error) {
        handleFailure(
          genericError,
          ANALYTICS_EVENTS.SEND_CARD_SECURITY_REPORT,
          {
            reason,
          },
        );
        return;
      }
    }

    setprocessingTransfer(true);

    let tokenizedCardNumber =
      cardNumberQuery?.data?.account?.tokenizedCardNumber;
    if (!tokenizedCardNumber) {
      try {
        const cardNumberResponse = await getAccountCardNumber({
          variables: {
            accountId,
            customerId,
            phone,
          },
        });
        tokenizedCardNumber =
          cardNumberResponse.data.account.tokenizedCardNumber;
        trackProgress(ANALYTICS_EVENTS.SEND_CARD_GET_CARD_NUMBER, {
          success: true,
        });
      } catch (error) {
        handleFailure(genericError, ANALYTICS_EVENTS.SEND_CARD_GET_CARD_NUMBER);
        return;
      }
    }

    //also check card last 4 query data against initial last 4
    if (!transferAccountCardQuery.data) {
      try {
        const statusType = Object.keys(ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE).find(
          (key) => ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE[key] === reason,
        );

        const transferResponse = await transferAccountCard({
          variables: {
            accountId,
            customerId,
            tokenizedCardNumber,
            fraudStatusCode: reason,
            isFraudulent: true, // hardcoding per https://app.asana.com/0/1201913907706105/1208668745624849/f
            requestedCard: !reissueDecline,
            statusType,
          },
        });

        const transferSuccess =
          !transferAccountCard.error && !transferResponse.errors;
        trackProgress(ANALYTICS_EVENTS.SEND_CARD_ACCOUNT_TRANSFER, {
          success: transferSuccess,
        });

        if (!transferSuccess) {
          //allow time for the card number to update
          await new Promise((resolve) => {
            setTimeout(resolve, 5000);
          });

          /**
           * Due to TSYS bug, this request above will almost certainly fail.
           * Our hacky solution to see if the account was succesfully
           * transfered is to see if the card's last 4 digits were updated.
           */
          const cardLast4Response = await getAccountCardLast4({
            variables: {
              accountId,
              customerId,
            },
          });

          const requestedCardLast4 =
            cardLast4Response?.data?.account?.cardDetails?.last4;
          const last4Updated = requestedCardLast4 !== initialCardLast4;

          trackProgress(ANALYTICS_EVENTS.SEND_CARD_COMPARE_LAST_4, {
            success: last4Updated,
            initialCardLast4,
            requestedCardLast4,
          });

          if (!last4Updated) {
            throw new Error(
              'Card last 4 not updated. Account transfer failed.',
            );
          }
        }
      } catch (error) {
        handleFailure(
          'Error issuing card - please review the remediation steps within the Issuing a Lost/Stolen Card Wiki on how to proceed.',
        );
        return;
      }

      setprocessingTransfer(false);
    }

    if (!reissueDecline) {
      handleCardReplacement();
    } else {
      endRequest(true);
    }
  };

  const handleSubmit = async () => {
    setSubmissionInitiated(true);
    setFailureMessage(undefined);

    trackProgress(ANALYTICS_EVENTS.SEND_CARD_REQUEST_START, {
      reissueDecline,
    });

    if (customerHasCard) {
      handleCardReplacement();
    } else {
      handleAccountTransfer();
    }
  };

  const handleExpediteSelection = (event) => {
    setExpediteShipping(event.target.value);
  };

  const getExpirationText = () => {
    const expirationDate = account?.cardDetails?.expirationDate;
    let expirationText = '';

    if (
      expirationDate &&
      dayjs(expirationDate).isBefore(dayjs().add(90, 'day'))
    ) {
      expirationText = 'The new card will have a new expiration date.';
    }

    return expirationText;
  };

  const renderFinalizationMessage = () => {
    let message = ['Once you click "Send Card",'];
    let shipTime = `${expediteShipping ? '1 to 3 business' : '7 to 10 calendar'} days`;

    if (customerHasCard) {
      message[0] = `${
        message[0]
      } the customer should receive their card in ${shipTime}.${getExpirationText()}`;
    } else {
      message[0] = `${message[0]} the existing card will be blocked and transferred.`;
      if (reissueDecline) {
        message[1] = `Due to the Reissue Decline (RD) status on the account, you will need to contact the Escalations team to send the new card.`;
      } else {
        message[1] = `We will then issue a new card with a new card number, which should arrive in ${shipTime}.`;
      }
    }

    return message.map((line, index) => (
      <Paragraph key={`finalization-${index}`}>{line}</Paragraph>
    ));
  };

  const renderDetails = () => {
    return (
      <>
        {agent.isSuperUser && forceReissue && (
          <Alert
            type="warning"
            banner
            message="Expedited shipping is no longer available if the card's expiration date is within 6 months."
            data-testid="expedited-unavailable-alert"
          />
        )}
        {agent.isSuperUser && !forceReissue && (
          <>
            <Alert
              type="warning"
              banner
              message="As a super user, you may offer the customer FedEx Next Day Delivery as a delivery option. Expedited cards should be delivered within 1-3 business days. Day 1 is considered the next day after the card has been requested. This option should be reserved for customers that are eligible and request it."
              style={{ marginBottom: 'var(--spacing)' }}
            />
            <Paragraph>Expedite shipping?</Paragraph>
            <Radio.Group
              onChange={handleExpediteSelection}
              value={expediteShipping}
            >
              <Space direction="vertical">
                <Radio value={false}>No, standard shipping is OK.</Radio>
                <Radio data-testid="expedite-radio" value={true}>
                  Yes, expedite shipping.
                </Radio>
              </Space>
            </Radio.Group>
          </>
        )}

        <Divider />
        <Title level={5}>Request Details</Title>
        {renderFinalizationMessage()}
      </>
    );
  };

  const renderStepProgress = (queries, text) => {
    let icon = EllipsisOutlined;
    let color = 'var(--color-text-secondary)';

    const complete = queries.every((query) => {
      if (query === transferAccountCardQuery) {
        if (query.data || query.error) {
          return true;
        }
      }
      return query.data;
    });

    const loading = queries.some((query) => {
      if (
        query === cardNumberQuery ||
        query === transferAccountCardQuery ||
        query === cardLast4Query
      ) {
        return processingTransfer;
      }
      return query.loading;
    });

    const error = queries.some((query) => {
      if (query === transferAccountCardQuery) {
        return false;
      }
      if (query === cardLast4Query && query.data) {
        const updatedCardLast4 = query.data.account.cardDetails.last4;
        return updatedCardLast4 === initialCardLast4;
      }
      return query.error;
    });

    if (loading) {
      icon = LoadingOutlined;
      color = 'var(--color-text)';
    } else if (error) {
      icon = CloseCircleOutlined;
      color = 'var(--color-error)';
    } else if (complete) {
      icon = CheckCircleOutlined;
      color = 'var(--color-success)';
    }

    return (
      <Flex gap="var(--spacing)" align="center">
        <Icon
          component={icon}
          style={{ color, fontSize: 'var(--font-size-lg)' }}
        />
        <Text size="large" style={{ color, fontSize: 'var(--font-size-lg)' }}>
          {text}
        </Text>
        {error ? <Button onClick={handleSubmit}>Retry Step</Button> : null}
      </Flex>
    );
  };

  const renderProgress = () => {
    const transferQueries = [cardNumberQuery, transferAccountCardQuery];
    if (transferAccountCardQuery.error || transferAccountCardQuery.errors) {
      transferQueries.push(cardLast4Query);
    }

    return (
      <div style={{ position: 'relative' }}>
        {submissionCompleted && !failureMessage ? (
          <SectionMessage
            data-testid="send-card-success-message"
            cover={true}
            status={STATUS.SUCCESS}
            size={SIZE.MD}
            text={'Send card request successful'}
          />
        ) : null}
        <Flex
          vertical={true}
          align="center"
          justify="center"
          gap="var(--spacing)"
          style={{
            height: '320px',
          }}
        >
          {!customerHasCard
            ? renderStepProgress([accountCardAlertQuery], 'Security Report')
            : null}
          {!customerHasCard
            ? renderStepProgress(transferQueries, 'Account Transfer')
            : null}
          {!reissueDecline
            ? renderStepProgress([replaceAccountCardQuery], 'Request Card')
            : null}
          {failureMessage ? (
            <Alert type="error" banner message={failureMessage} />
          ) : null}
        </Flex>
      </div>
    );
  };

  return (
    <StepContainer
      title="Submit Send Card Request"
      onContinue={submissionInitiated ? undefined : handleSubmit}
      onExit={resetSendCardState}
      buttonText="Send Card"
      buttonProps={{
        loading: processing,
        disabled: failureMessage || submissionInitiated,
      }}
    >
      {submissionInitiated ? renderProgress() : renderDetails()}
    </StepContainer>
  );
};

export default SubmissionStep;
