import { Form, Select } from 'antd';

const { Option } = Select;

const SelectFundingAccount = ({
  label,
  name,
  fundingAccounts,
  onChange,
  fundingAccountId = null,
  bankDisplayName = '',
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          message: 'Please select a funding account!',
        },
      ]}
      data-testid="funding-account-select"
    >
      <Select value={fundingAccountId} onChange={onChange}>
        {fundingAccounts
          .filter(({ status }) => status === 'ENABLED' || status === 'DEFAULT')
          .map(({ id, bankName, numberLast4, isDefault }, index) => (
            <Option value={id} key={index}>
              {bankDisplayName ||
                `${bankName} - ${numberLast4} - ${id}${isDefault ? ' (default)' : ''}`}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default SelectFundingAccount;
