import { useQuery } from '@apollo/client';
import { ACCOUNT_BIN } from 'queries/account';
import { useParams } from 'react-router-dom';

import Pill from './Pill';

export default function DigitalWalletPill({ accountId }) {
  const { customerId } = useParams();
  const digitalWalletBins = ['431335'];

  const { data: { account } = {}, error } = useQuery(ACCOUNT_BIN, {
    variables: { accountId, customerId },
  });

  return (
    <>
      {error && null}
      {account && digitalWalletBins.includes(account?.bin) && (
        <Pill
          data-testid="digital-wallet-bin-indicator"
          name={'Digital Wallet Eligible'}
          color={'var(--blue-4)'}
        />
      )}
    </>
  );
}
