import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Checkbox, Col, Form, Modal, Row } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import PaymentAuthorizationMessage from '../PaymentAuthorizationMessage';
import SelectFundingAccount from '../SelectFundingAccount';
import SelectPaymentAmount from '../SelectPaymentAmount';
import DatePicker from 'components/DatePicker/DatePicker';
import QueryResult from 'components/QueryResult/QueryResult';

import useAnalytics from 'hooks/useAnalytics';
import { CANCEL_PAYMENT, SCHEDULE_PAYMENT } from 'mutations/payments';
import { ONE_TIME_PAYMENT } from 'queries/payments';
import { ANALYTICS_EVENTS } from 'utilities/constants';
import { getAccountLast4, transformPaymentData } from 'utilities/helpers';
import {
  dateIsWithin90Days,
  formatDateToSubmit,
} from 'utilities/datesAndTimes';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const EditPaymentModal = ({ hide, payment, ...modalProps }) => {
  const [schedulePaymentForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useAnalytics();

  const [scriptAmount, setScriptAmount] = useState(null);
  const [scriptAccount, setScriptAccount] = useState(null);
  const [scriptDate, setScriptDate] = useState(dayjs());

  const { accountId, customerId } = useParams();

  const initialAmount = Math.abs(payment?.amount / 100).toFixed(2);

  const [getPaymentInfo, paymentQuery] = useLazyQuery(ONE_TIME_PAYMENT);

  const data = transformPaymentData(paymentQuery?.data);

  useEffect(() => {
    if (customerId && accountId && open) {
      getPaymentInfo({
        variables: { accountId: accountId, customerId: customerId },
      });
    }
  }, [customerId, accountId, open, getPaymentInfo]);

  useEffect(() => {
    if (payment && paymentQuery.data) {
      const values = {
        paymentAmount: initialAmount,
        fundingAccount: payment.bankAccount.id,
        paymentDate: dayjs(payment.date),
      };
      schedulePaymentForm.setFieldsValue(values);
      setScriptAmount(values.paymentAmount);
      setScriptAccount(values.fundingAccount);
      setScriptDate(values.paymentDate);
    }
  }, [payment, paymentQuery.data]);

  const [cancelPayment, { error: cancelError }] = useMutation(CANCEL_PAYMENT);
  const [schedulePayment, { error: scheduleError }] = useMutation(
    SCHEDULE_PAYMENT,
    {
      refetchQueries: ['PaymentsQuery'],
    },
  );

  const updateScheduledPayment = async () => {
    setLoading(true);
    try {
      const values = await schedulePaymentForm.validateFields();
      trackEvent(ANALYTICS_EVENTS.PAYMENT_EDIT_SAVED);
      await cancelPayment({
        variables: {
          customerId,
          scheduleId: payment.id,
        },
      });
      await schedulePayment({
        variables: {
          customerId,
          accountId,
          amount: Math.round(values.paymentAmount * 100),
          fundingAccountId: values.fundingAccount,
          paymentDate: formatDateToSubmit(values.paymentDate),
        },
      });
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
    }
  };

  const closeModal = () => {
    hide();
    schedulePaymentForm.resetFields();
    trackEvent(ANALYTICS_EVENTS.PAYMENT_EDIT_CLOSED);
  };

  return (
    <Modal
      {...modalProps}
      title="Modify scheduled payment"
      width={800}
      onOk={updateScheduledPayment}
      onCancel={closeModal}
      okText="Update payment"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <QueryResult {...paymentQuery}>
        <Form form={schedulePaymentForm} name="schedulePayment" {...layout}>
          <SelectPaymentAmount
            label="Payment Amount"
            name="paymentAmount"
            paymentOptions={data.paymentOptions}
            initialAmount={initialAmount}
            setAmount={setScriptAmount}
            form={schedulePaymentForm}
          />

          <SelectFundingAccount
            label="Bank Account"
            name="fundingAccount"
            fundingAccounts={data?.fundingAccounts}
            onChange={setScriptAccount}
          />

          <Form.Item
            label="Select Date"
            name="paymentDate"
            rules={[
              {
                required: true,
                message: 'Please select a funding account!',
              },
            ]}
          >
            <DatePicker
              onChange={setScriptDate}
              disabledDate={(d) => !d || !dateIsWithin90Days(d)}
              data-testid="paymentDatePicker"
            />
          </Form.Item>

          <Row gutter={[10, 0]}>
            <Col span={8} style={{ textAlign: 'right' }}>
              Payment Authorization:
            </Col>
            <Col span={16}>
              <PaymentAuthorizationMessage
                cardLast4={data.cardLast4}
                amount={scriptAmount}
                account={getAccountLast4(data?.fundingAccounts, scriptAccount)}
                date={scriptDate}
                isScheduledPayment={true}
              />
              <Form.Item
                name="authorizePayment"
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox>Acknowledge customer authorization</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </QueryResult>
      {cancelError && (
        <Alert
          type="error"
          message={`Error cancelling existing payment: ${cancelError.message}`}
        />
      )}
      {scheduleError && (
        <Alert
          type="error"
          message={`Error scheduling new payment: ${scheduleError.message}`}
        />
      )}
    </Modal>
  );
};

export default EditPaymentModal;
