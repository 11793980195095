import { createContext } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { theme as antTheme, App, ConfigProvider } from 'antd';

import { DARK_THEME } from './darkTheme';
import { LIGHT_THEME } from './lightTheme';
import { COLORS_LIGHT_THEME, COLORS_DARK_THEME } from 'utilities/styles';

export const MC_THEME_KEY = 'mc-theme';
const mcThemeStorage = localStorage.getItem(MC_THEME_KEY);

export const ThemeContext = createContext(null);

export const themeSettingsVar = makeVar(
  mcThemeStorage
    ? JSON.parse(mcThemeStorage)
    : {
        isDark: false,
        isCompact: false, //future use
      },
);

const updateThemeSettings = (update) => {
  themeSettingsVar(update);
  localStorage.setItem(MC_THEME_KEY, JSON.stringify(update));
};

const toggleCompact = () => {
  const themeSettings = themeSettingsVar();
  const update = {
    ...themeSettings,
    isCompact: !themeSettings.isCompact,
  };
  updateThemeSettings(update);
};

const toggleDark = () => {
  const themeSettings = themeSettingsVar();
  const update = {
    ...themeSettings,
    isDark: !themeSettings.isDark,
  };
  updateThemeSettings(update);
};

const ThemeProvider = ({ children }) => {
  const { isCompact, isDark } = useReactiveVar(themeSettingsVar);

  const theme = isDark ? DARK_THEME : LIGHT_THEME;
  const colors = isDark ? COLORS_DARK_THEME : COLORS_LIGHT_THEME;

  return (
    <ThemeContext.Provider
      value={{
        colors,
        isCompact,
        isDark,
        toggleCompact,
        toggleDark,
        mcTokens: theme.mcTokens,
      }}
    >
      <ConfigProvider
        theme={{
          algorithm: isCompact ? antTheme.compactAlgorithm : undefined,
          ...theme,
        }}
      >
        <App>{children}</App>
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

const GlobalStyles = () => {};

export default ThemeProvider;
