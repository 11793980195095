import { gql } from '@apollo/client';

const EFA_CONTACT_PULL = gql`
  query EFA_Contact_Pull(
    $agentEmail: String!
    $applicationId: String!
    $city: String!
    $firstName: String!
    $fullSsn: String!
    $lastName: String!
    $state: String!
    $street1: String!
    $zip: String!
    $email: String!
    $sponsorBank: SponsorBank!
  ) {
    pullEFAContact(
      sponsorBank: $sponsorBank
      contactMeta: {
        address: $street1
        agentEmail: $agentEmail
        applicationId: $applicationId
        city: $city
        firstName: $firstName
        fullSsn: $fullSsn
        lastName: $lastName
        state: $state
        zip: $zip
        email: $email
      }
    ) {
      efaContact
    }
  }
`;

export { EFA_CONTACT_PULL };
