import { useEffect } from 'react';
import { useSpring, animated, easings } from 'react-spring';
import { useLocation } from 'react-router-dom';
import { useLocalStorage, useToggle } from 'react-use';
import styled from '@emotion/styled';
import { Button, Tabs } from 'antd';
import {
  FileTextOutlined,
  LeftOutlined,
  MinusOutlined,
  RightOutlined,
} from '@ant-design/icons';

import NoteForm from './NoteForm';
import StickyNote from './StickyNote';
import TagCall from './TagCall';

import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS } from 'utilities/constants';

const Container = styled(animated.div)`
  position: fixed;
  display: grid;
  min-width: 540px;
  bottom: 0px;
  left: 0px;
  z-index: 200;
  padding: 0 var(--spacing-sm);
`;

const TextAreaContainer = styled.div`
  position: relative;
  pointer-events: all;
  justify-self: end;
  width: 530px;
  box-shadow: var(--box-shadow-tertiary);
  border: var(--border-secondary);
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  padding: 0 var(--spacing);
  background-color: var(--color-fill-quaternary);
`;

const IconContainer = styled(animated.div)`
  display: grid;
  pointer-events: all;
  justify-self: start;
  padding: 1.5rem 1rem 1rem 1rem;
  background-color: var(--color-primary);
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  box-shadow: var(--box-shadow-tertiary);
  color: var(--color-text-light);
  font-size: 24px;
  cursor: pointer;
  width: 80px;
`;

const BOX_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

const NoteBox = () => {
  const { trackEvent } = useAnalytics();
  const [isNoteBoxOpen, toggleIsNoteBoxOpen] = useToggle(false);
  const [hovered, setHovered] = useToggle(false);
  const [boxPosition, setBoxPosition] = useLocalStorage(
    'note-box-position',
    BOX_POSITION.RIGHT,
  );

  const location = useLocation();
  const pathname = location?.pathname;
  const onSearchPage = pathname?.includes('search') || pathname === '/';

  const animatedStyles = useSpring({
    to: { transform: 'translateY(0px)' },
    from: { transform: 'translateY(40px)' },
    reverse: !hovered,
  });

  const [containerStyles, springApi] = useSpring(() => ({
    width: '100vw',
    pointerEvents: 'none',
    config: { duration: 500, easing: easings.easeInOutSine },
  }));

  useEffect(() => {
    switch (boxPosition) {
      case BOX_POSITION.LEFT:
        springApi.start({ width: '20vw' });
        break;
      case BOX_POSITION.RIGHT:
        springApi.start({ width: '100vw' });
        break;
    }
  }, [boxPosition]);

  const handleTabClick = (key) => {
    if (key === 'tagCall') {
      trackEvent(ANALYTICS_EVENTS.NOTE_BOX_TAG_CALL_CLICKED);
    }
  };

  const ArrowComponent =
    boxPosition === BOX_POSITION.LEFT ? RightOutlined : LeftOutlined;

  const tabItems = [
    {
      key: 'noteTab',
      label: 'Note',
      children: <NoteForm></NoteForm>,
    },
    {
      key: 'sticky',
      label: 'Sticky',
      children: <StickyNote></StickyNote>,
    },
    {
      key: 'tagCall',
      label: 'Tag Call',
      children: <TagCall></TagCall>,
    },
  ];

  const tabBarExtraContent = (
    <>
      <Button
        type="link"
        icon={<ArrowComponent />}
        onClick={() =>
          setBoxPosition(
            boxPosition === BOX_POSITION.RIGHT
              ? BOX_POSITION.LEFT
              : BOX_POSITION.RIGHT,
          )
        }
      />
      <Button
        type="link"
        data-testid="close-notebox"
        icon={<MinusOutlined />}
        onClick={() => toggleIsNoteBoxOpen(false)}
      />
    </>
  );

  return (
    <Container style={containerStyles}>
      {!onSearchPage && (
        <>
          {isNoteBoxOpen ? (
            <TextAreaContainer>
              <Tabs
                items={tabItems}
                tabBarExtraContent={tabBarExtraContent}
                onTabClick={handleTabClick}
              ></Tabs>
            </TextAreaContainer>
          ) : (
            <IconContainer
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              style={{
                ...animatedStyles,
                marginLeft: boxPosition === BOX_POSITION.RIGHT && 'auto',
              }}
              data-testid="notebox-trigger"
              onClick={() => {
                toggleIsNoteBoxOpen(true);
                setHovered(false);
              }}
            >
              <FileTextOutlined
                data-testid="note-icon"
                style={{ margin: 'auto' }}
              />
            </IconContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default NoteBox;
