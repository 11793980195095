import { Card, Flex, Typography } from 'antd';

import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import Translation from 'components/Translation/Translation';

const AgentScript = ({ title, translateKey, values }) => {
  return (
    <Card size="small" style={{ marginBottom: 'var(--spacing)' }}>
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: 'var(--spacing-sm)' }}
      >
        <Typography.Text strong>{`${title} Script: `}</Typography.Text>
        <LanguageSelector />
      </Flex>
      <Translation translateKey={translateKey} values={values} />
    </Card>
  );
};

export default AgentScript;
