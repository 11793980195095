import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, List, Typography } from 'antd';
import { CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import useAgent from 'hooks/useAgent';
import useAnalytics from 'hooks/useAnalytics';
import useTimeoutValue from 'hooks/useTimeoutValue';

import { UPDATE_STICKY_NOTE } from 'mutations/notes';
import { GET_NOTES_QUERY } from 'queries/notes';

import { ANALYTICS_EVENTS } from 'utilities/constants';
import { getDateFromJavaInstant, formatDate } from 'utilities/datesAndTimes';

const { Item } = List;

const CoverMessage = styled(Typography.Text, {
  shouldForwardProp: (propName) => propName !== 'visible',
})(({ visible }) => {
  let pointerEvents = visible ? 'auto' : 'none';
  let opacity = visible ? 0.9 : 0;
  return `
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: ${opacity};
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: ${pointerEvents};
    font-weight: normal;
  `;
});

const RemoveButton = ({ noteId, onClick, loading }) => {
  let closeButton = (
    <Button
      type="text"
      size="small"
      key={noteId}
      data-testid={`removeStickyNoteButton-${noteId}`}
      onClick={onClick}
      disabled={loading}
      loading={loading}
      icon={
        <CloseCircleFilled
          style={{
            color: 'var(--red-4)',
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
        />
      }
    />
  );

  return [closeButton];
};

const StickyNote = ({ note }) => {
  const agent = useAgent();
  const { trackEvent } = useAnalytics();

  const [removeFailure, setRemoveFailure] = useTimeoutValue(false);

  const [removeSticky, removeStickyQuery] = useMutation(UPDATE_STICKY_NOTE, {
    variables: {
      noteId: note.id,
      agentId: agent?.user?.requester_id,
      agentName: agent?.user?.name,
    },
    refetchQueries: ['GetAgentNotes'],
  });

  const removeStickyNote = async () => {
    try {
      trackEvent(ANALYTICS_EVENTS.SUPER_STICKY_REMOVED);
      await removeSticky();
    } catch (error) {
      setRemoveFailure(true);
    }
  };

  return note.message ? (
    <Item
      key={`sticky-item-${note.id}`}
      style={{
        position: 'relative',
        fontWeight: 'bold',
        backgroundColor: 'var(--yellow-10)',
      }}
      extra={
        <RemoveButton
          noteId={note.id}
          onClick={removeStickyNote}
          loading={removeStickyQuery.loading}
        />
      }
    >
      <CoverMessage type="danger" visible={removeFailure}>
        Failed to remove sticky note
      </CoverMessage>
      <InfoCircleOutlined
        style={{ color: 'var(--yellow-4)', marginRight: 'var(--spacing-xs)' }}
      />
      {formatDate(getDateFromJavaInstant(note.createdTz))} &bull;{' '}
      <strong>{note.agentName}</strong> &bull; {note.message}
    </Item>
  ) : null;
};

const StickyNotes = () => {
  const { applicationId, customerId } = useParams();
  const [stickyNotes, setStickyNotes] = useState([]);

  const [getNotes] = useLazyQuery(GET_NOTES_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.notesV3) {
        let notes = data.notesV3.filter((note) => note.isSticky === true) || [];
        setStickyNotes(notes);
      }
    },
  });

  useEffect(() => {
    if (applicationId || customerId) {
      getNotes({
        variables: {
          applicationId,
          customerId,
        },
      });
    }
  }, [applicationId, customerId]);

  return (
    <>
      {stickyNotes.length > 0 && (
        <Card
          size="small"
          style={{ marginBottom: 'var(--spacing-xs)' }}
          data-testid="stickyCardTest"
        >
          <List
            data-testid="stickyListTest"
            size="small"
            itemLayout="vertical"
            dataSource={stickyNotes}
            style={{ maxHeight: '120px', overflow: 'auto' }}
            renderItem={(note) => <StickyNote note={note} />}
          />
        </Card>
      )}
    </>
  );
};

export default StickyNotes;
