import styled from '@emotion/styled';

/**
 * Emotion does have the isPropValid method that seems to work most of the time
 * but in some cases invalid props still make it down to bare html elements
 * I found this thread about a 'transient prop' pattern that I think could be useful for us
 * https://github.com/emotion-js/emotion/issues/2193#issuecomment-1507061122
 */
export const withTransientProps = {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
};
export const styledWithTransientProps = (element) => {
  return styled(element, withTransientProps);
};

//https://atlassian.design/foundations/color-new/color-palette-new
export const COLOR_PALETTE = {
  blue1: '#E9F2FF',
  blue2: '#CCE0FF',
  blue3: '#85B8FF',
  blue4: '#579DFF',
  blue5: '#388BFF',
  blue6: '#1D7AFC',
  blue7: '#0C66E4',
  blue8: '#0055CC',
  blue9: '#09326C',
  blue10: '#1C2B41',

  green1: '#DCFFF1',
  green2: '#BAF3DB',
  green3: '#7EE2B8',
  green4: '#4BCE97',
  green5: '#2ABB7F',
  green6: '#22A06B',
  green7: '#1F845A',
  green8: '#1F845A',
  green9: '#164B35',
  green10: '#1C3329',

  lime1: '#EFFFD6',
  lime2: '#D3F1A7',
  lime3: '#B3DF72',
  lime4: '#94C748',
  lime5: '#82B536',
  lime6: '#6A9A23',
  lime7: '#5B7F24',
  lime8: '#4C6B1F',
  lime9: '#37471F',
  lime10: '#28311B',

  magenta1: '#FFECF8',
  magenta2: '#FDD0EC',
  magenta3: '#F797D2',
  magenta4: '#E774BB',
  magenta5: '#DA62AC',
  magenta6: '#CD519D',
  magenta7: '#AE4787',
  magenta8: '#943D73',
  magenta9: '#50253F',
  magenta10: '#3D2232',

  orange1: '#FFF3EB',
  orange2: '#FEDEC8',
  orange3: '#FEC195',
  orange4: '#FEA362',
  orange5: '#F38A3F',
  orange6: '#E56910',
  orange7: '#C25100',
  orange8: '#A54800',
  orange9: '#702E00',
  orange10: '#38291E',

  purple1: '#F3F0FF',
  purple2: '#DFD8FD',
  purple3: '#B8ACF6',
  purple4: '#9F8FEF',
  purple5: '#8F7EE7',
  purple6: '#8270DB',
  purple7: '#6E5DC6',
  purple8: '#5E4DB2',
  purple9: '#352C63',
  purple10: '#2B273F',

  red1: '#FFECEB',
  red2: '#FFD5D2',
  red3: '#FD9891',
  red4: '#F87168',
  red5: '#F15B50',
  red6: '#E2483D',
  red7: '#C9372C',
  red8: '#AE2E24',
  red9: '#5D1F1A',
  red10: '#42221F',

  teal1: '#E7F9FF',
  teal2: '#C6EDFB',
  teal3: '#9DD9EE',
  teal4: '#6CC3E0',
  teal5: '#42B2D7',
  teal6: '#2898BD',
  teal7: '#227D9B',
  teal8: '#206A83',
  teal9: '#164555',
  teal10: '#1E3137',

  yellow1: '#FFF7D6',
  yellow2: '#F8E6A0',
  yellow3: '#F5CD47',
  yellow4: '#E2B203',
  yellow5: '#CF9F02',
  yellow6: '#B38600',
  yellow7: '#946F00',
  yellow8: '#7F5F01',
  yellow9: '#533F04',
  yellow10: '#332E1B',

  'neutralDark-1': '#101214',
  neutralDark0: '#161A1D',
  neutralDark1: '#1D2125',
  neutralDark2: '#22272B',
  'neutralDark2.5': '#282E33',
  neutralDark3: '#2C333A',
  'neutralDark3.5': '#38414A',
  neutralDark4: '#454F59',
  neutralDark5: '#596773',
  neutralDark6: '#738496',
  neutralDark7: '#8C9BAB',
  neutralDark8: '#9FADBC',
  neutralDark9: '#B6C2CF',
  neutralDark10: '#C7D1DB',
  neutralDark11: '#DEE4EA',

  'neutralDarkAlpha-1': '#03040442',
  neutralDarkAlpha1: '#BCD6F00A',
  neutralDarkAlpha2: '#A1BDD914',
  'neutralDarkAlpha2.5': '#C8E1F91A',
  neutralDarkAlpha3: '#A6C5E229',
  'neutralDarkAlpha3.5': '#C3DEFE33',
  neutralDarkAlpha4: '#BFDBF847',
  neutralDarkAlpha5: '#9BB4CA80',

  neutralLight0: '#FFFFFF',
  neutralLight1: '#F7F8F9',
  neutralLight2: '#F1F2F4',
  neutralLight3: '#DCDFE4',
  neutralLight4: '#B3B9C4',
  neutralLight5: '#8590A2',
  neutralLight6: '#758195',
  neutralLight7: '#626F86',
  neutralLight8: '#44546F',
  neutralLight9: '#2C3E5D',
  neutralLight10: '#172B4D',
  neutralLight11: '#091E42',

  neutralLightAlpha1: '#091E4208',
  neutralLightAlpha2: '#091E420F',
  neutralLightAlpha3: '#091E4224',
  neutralLightAlpha4: '#091E424F',
  neutralLightAlpha5: '#091E427D',
};

/**
 * Atlassians color pallete is designed to be swapped at the midpoint of saturation
 * between light and dark mode. These map/lists should make it simpler to use colors
 * throughout the app while accessing at the same index despite light or dark mode being active
 *
 * If a specific color needs to be accessed that can still be done using the COLOR_PALETTE above
 *
 * Indexes of the colors run 1 - 10 to match up with the saturation scale
 * Indexes of the neutrals run 0 - 11
 */

const getColorList = (color, reverse = false, scale = 12) => {
  const colorList = new Array(scale)
    .fill()
    .map((_, index) => COLOR_PALETTE[`${color}${index}`]);
  return reverse ? colorList.reverse() : colorList;
};

export const COLORS_LIGHT_THEME = {
  blue: getColorList('blue', true),
  green: getColorList('green', true),
  lime: getColorList('lime', true),
  magenta: getColorList('magenta', true),
  orange: getColorList('orange', true),
  purple: getColorList('purple', true),
  red: getColorList('red', true),
  teal: getColorList('teal', true),
  yellow: getColorList('yellow', true),
  neutral: getColorList('neutralLight', false),
  neutralAlpha: getColorList('neutralLightAlpha', false, 6),
};

export const COLORS_DARK_THEME = {
  blue: getColorList('blue'),
  green: getColorList('green'),
  lime: getColorList('lime'),
  magenta: getColorList('magenta'),
  orange: getColorList('orange'),
  purple: getColorList('purple'),
  red: getColorList('red'),
  teal: getColorList('teal'),
  yellow: getColorList('yellow'),
  neutral: getColorList('neutralDark'),
  neutralAlpha: getColorList('neutralDarkAlpha', false, 6),
};

export const COLORS_CALABRIO = {
  blue: '#1d91ff',
  gray: '#c0c0c0',
  green: '#31c100',
  orange: '#fe8080',
  peach: '#ffc17f',
  pink: '#fa81ff',
  purple: '#daaae6',
  red: '#ff0000',
  teal: '#008080',
  yellow: '#ffd600',
};
