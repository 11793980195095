import { createContext } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';

import { CUSTOMER_SEARCH_FIELD } from 'utilities/constants';

export const AGENT_SETTINGS_KEY = 'mc-settings';

const INITIAL_SETTINGS = Object.freeze({
  defaultCustomerSearchFields: [
    CUSTOMER_SEARCH_FIELD.FIRST_NAME.name,
    CUSTOMER_SEARCH_FIELD.LAST_NAME.name,
    CUSTOMER_SEARCH_FIELD.PHONE_NUMBER.name,
    CUSTOMER_SEARCH_FIELD.EMAIL.name,
    CUSTOMER_SEARCH_FIELD.BIRTH_DATE.name,
    CUSTOMER_SEARCH_FIELD.LAST_4_SSN.name,
    CUSTOMER_SEARCH_FIELD.EXTERNAL_ACCOUNT_ID.name,
  ],
});

const agentSettingsStorage = localStorage.getItem(AGENT_SETTINGS_KEY);

const agentSettingsVar = makeVar(
  agentSettingsStorage
    ? JSON.parse(agentSettingsStorage)
    : Object.assign({}, INITIAL_SETTINGS),
);

const updateAgentSettings = (updatedSettings) => {
  agentSettingsVar(updatedSettings);
  localStorage.setItem(AGENT_SETTINGS_KEY, JSON.stringify(updatedSettings));
};

export const AgentSettingsContext = createContext(null);

const AgentSettingsProvider = ({ children }) => {
  const agentSettings = useReactiveVar(agentSettingsVar);

  return (
    <AgentSettingsContext.Provider
      value={{ agentSettings, updateAgentSettings }}
    >
      {children}
    </AgentSettingsContext.Provider>
  );
};

export default AgentSettingsProvider;
