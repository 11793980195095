import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';

const TooltipContainer = styled('div')`
  .ant-tooltip-inner {
    background: var(--color-error-background);
    border: 1px solid var(--color-error-border);
    color: var(--color-text);
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  .ant-tooltip-arrow {
    &::before {
      background: var(--color-error-border);
    }
  }
`;

const ErrorTooltip = ({ children, ...tooltipProps }) => {
  const containerRef = useRef();

  return (
    <>
      {createPortal(<TooltipContainer ref={containerRef} />, document.body)}
      <Tooltip
        getPopupContainer={() => {
          return containerRef.current;
        }}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    </>
  );
};

export default ErrorTooltip;
