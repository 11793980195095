import { useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Button, Card, Col, Divider, Flex, Row, Tabs as AntTabs } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';

import AccountStatusSection from './AccountStatusSection/AccountStatusSection';
import ActivateCardButton from './ActivateCardButton';
import CreditAccountSnapshot from './CreditAccountSnapshot';
import CreditBalanceRefundSection from './CreditBalanceRefundSection/CreditBalanceRefundSection';
import CreditDetailsSection from './CreditDetailsSection/CreditDetailsSection';
import CvvVerifyModal from './CvvVerifyModal';
import FullStoryButton from './FullStoryButton';
import MakePaymentModal from './MakePaymentModal';
import PaymentSection from './PaymentSection/PaymentSection';
import PaymentReturnSection from './PaymentReturnSection';
import RecoveriesSection from './RecoveriesSection/RecoveriesSection';
import SendCardSection from './SendCardSection/SendCardSection';
import StatementsSection from './StatementsSection/StatementsSection';
import TransactionSection from './TransactionSection/TransactionSection';

import AssetPill from 'components/Pill/AssetPill';
import AccountTitle from 'components/AccountTitle/AccountTitle';
import DisplayValue from 'components/DisplayValue/DisplayValue';

import useAccountQuery from 'hooks/useAccountQuery';
import useModalControls from 'hooks/useModalControls';
import {
  ASSET_SALE_TAGS,
  CARD_ISSUANCE_STATE_STATUS,
  DEFAULT_ASSET_SALE,
} from 'utilities/constants';
import { getCardDisplayName } from 'utilities/helpers';

const Tabs = styled(AntTabs)`
  .ant-tabs-nav {
    margin-bottom: var(--spacing-lg);
  }
`;

export const VIEWS = {
  ACCOUNT_STATUSES: 'account-statuses',
  CARD_DETAILS: 'card-details',
  CREDIT_BALANCE_REFUND: 'credit-balance-refund',
  FRAUD: 'fraud',
  PAYMENTS: 'payments',
  RETURNED_PAYMENTS: 'returned-payments',
  SEND_CARD: 'send-card',
  RECOVERIES: 'recoveries',
  STATEMENTS: 'statements',
  TRANSACTIONS: 'transactions',
};

const CreditAccountPage = () => {
  const {
    showActivateCard,
    showCreditBalanceRefund,
    enableAssetSale,
    showAssetSaleTag,
    showSecondAssetSaleTag,
  } = useFlags();

  const { accountId, customerId, view } = useParams();
  const routeMatch = useRouteMatch();
  const history = useHistory();

  const cvvVerifyModal = useModalControls();
  const makePaymentModal = useModalControls();

  const [cardName, setCardName] = useState('...');

  const [displayActivateCardButton, setDisplayActivateCardButton] =
    useState(false);

  const { accountQuery, account, simpleAccount } = useAccountQuery(
    customerId,
    accountId,
  );

  useEffect(() => {
    const cardIsActivated =
      account?.cardDetails?.issuanceState ===
      CARD_ISSUANCE_STATE_STATUS.ACTIVATED;
    const cardIsPreExpired =
      account?.cardDetails?.issuanceState ===
      CARD_ISSUANCE_STATE_STATUS.PRE_EXPIRED;

    setDisplayActivateCardButton(!cardIsActivated && !cardIsPreExpired);
  }, [account?.cardDetails?.issuanceState]);

  const tabItems = useMemo(() => {
    let tabs = [
      {
        label: 'Card Details',
        key: VIEWS.CARD_DETAILS,
        children: <CreditDetailsSection />,
      },
      {
        label: 'Account Statuses',
        key: VIEWS.ACCOUNT_STATUSES,
        children: <AccountStatusSection />,
      },
      {
        label: 'Transactions',
        key: VIEWS.TRANSACTIONS,
        children: <TransactionSection />,
      },
      {
        label: 'Payments',
        key: VIEWS.PAYMENTS,
        children: <PaymentSection />,
      },
      {
        label: 'Returned Payments',
        key: VIEWS.RETURNED_PAYMENTS,
        children: <PaymentReturnSection />,
      },
      {
        label: 'Statements',
        key: VIEWS.STATEMENTS,
        children: <StatementsSection />,
      },
      {
        label: 'Send Card',
        key: VIEWS.SEND_CARD,
        children: <SendCardSection />,
      },
      {
        label: 'Recoveries',
        key: VIEWS.RECOVERIES,
        children: <RecoveriesSection />,
      },
    ];

    if (showCreditBalanceRefund) {
      tabs.push({
        label: 'CBR',
        key: VIEWS.CREDIT_BALANCE_REFUND,
        children: <CreditBalanceRefundSection />,
      });
    }

    return tabs;
  }, [showCreditBalanceRefund]);

  useEffect(() => {
    setCardName(getCardDisplayName(account));
  }, [account]);

  const { eligible: eligibleForActivation, reasons } =
    account?.activationEligibilityV2 || {};

  const issuanceState = account?.cardDetails?.issuanceState;
  const cardIsNotMailedOrDelayed =
    issuanceState !== CARD_ISSUANCE_STATE_STATUS.MAILED &&
    issuanceState !== CARD_ISSUANCE_STATE_STATUS.DELAYED;

  let activationDisabledTooltipMessage = '';

  if (cardIsNotMailedOrDelayed) {
    activationDisabledTooltipMessage = `Card is ${account?.cardDetails?.issuanceState.toLowerCase()}`;
  } else if (reasons?.length) {
    activationDisabledTooltipMessage = reasons
      .map((reason) => `${reason.description} (${reason.reasonCode})`)
      .join(', ');
  }

  const isSecondAssetSaleAccount =
    account?.accountDetails?.accountTag === ASSET_SALE_TAGS.ACM002.value;

  const isAssetSaleAccount =
    !!ASSET_SALE_TAGS[account.accountDetails?.accountTag];

  const getAssetSaleTagDisplay = () => {
    if (!showSecondAssetSaleTag && isSecondAssetSaleAccount) {
      return '';
    }

    if (showAssetSaleTag && isAssetSaleAccount) {
      return ASSET_SALE_TAGS[account?.accountDetails?.accountTag].label;
    }
  };

  const assetSaleTagDisplay = getAssetSaleTagDisplay();

  const creditorDisplay =
    !showAssetSaleTag &&
    enableAssetSale &&
    account.accountDetails?.creditorDisplay !== DEFAULT_ASSET_SALE
      ? account.accountDetails?.creditorDisplay
      : '';

  return (
    <Card style={{ overflowY: 'hidden' }}>
      <CvvVerifyModal {...cvvVerifyModal} />
      <MakePaymentModal {...makePaymentModal} />
      <AccountTitle
        level={3}
        loading={accountQuery.loading}
        tsysAccountId={simpleAccount?.externalAccountId}
        tsysCustomerId={simpleAccount?.externalCustomerId}
        cardLast4={account?.cardDetails?.last4}
      >
        {cardName}
      </AccountTitle>
      <Flex
        vertical={true}
        gap="var(--spacing)"
        style={{ paddingTop: 'var(--spacing)' }}
      >
        <CreditAccountSnapshot />
        <Divider style={{ margin: 0 }} />
        <Row
          gutter={['var(--spacing)', 'var(--spacing-lg)']}
          style={{ alignItems: 'center' }}
        >
          <Col flex="3">
            <Flex gap="var(--spacing-lg)">
              <div>
                <DisplayValue label="Servicing" canCopy={true}>
                  (855) 790 8860
                </DisplayValue>
              </div>
              <div>
                <DisplayValue label="Recoveries" canCopy={true}>
                  (888) 695 8536
                </DisplayValue>
              </div>
              <div>
                <DisplayValue label="Collections" canCopy={true}>
                  (619) 937 3483
                </DisplayValue>
              </div>
              {(assetSaleTagDisplay || creditorDisplay) && (
                <div>
                  <DisplayValue label="Creditor">
                    <AssetPill>
                      {assetSaleTagDisplay || creditorDisplay}
                    </AssetPill>
                  </DisplayValue>
                </div>
              )}
            </Flex>
          </Col>
          <Col flex="1" style={{ justifyContent: 'end' }}>
            <Flex gap="var(--spacing-sm)" justify="end">
              {displayActivateCardButton && showActivateCard && (
                <ActivateCardButton
                  cardLast4={account?.cardDetails?.last4}
                  eligibleForActivation={eligibleForActivation}
                  activationDisabledTooltipMessage={
                    activationDisabledTooltipMessage
                  }
                />
              )}
              <Button onClick={cvvVerifyModal.show}>Verify CVV</Button>
              <Button onClick={makePaymentModal.show}>Make Payment</Button>
            </Flex>
          </Col>
        </Row>
        <Tabs
          activeKey={view || VIEWS.CARD_DETAILS}
          defaultActiveKey={VIEWS.CARD_DETAILS}
          onTabClick={(viewKey) => {
            history.replace(
              generatePath(routeMatch.path, {
                accountId,
                customerId,
                view: viewKey,
              }),
            );
          }}
          tabBarExtraContent={<FullStoryButton />}
          items={tabItems}
        ></Tabs>
      </Flex>
    </Card>
  );
};

export default CreditAccountPage;
