import { Global } from '@emotion/react';
import { css } from '@emotion/react';

import useTheme from 'hooks/useTheme';

const StylesProvider = ({ children }) => {
  const { isDark, tokens, colors } = useTheme();

  return (
    <>
      <Global
        styles={css`
          :root {
            height: 100vh;
            overflow: hidden;
            color-scheme: ${isDark ? 'dark' : 'light'};

            --header-height: 48px;
            --sidebar-width: 330px;
            --softphone-width: 330px;

            --border: 1px solid ${tokens.colorBorder};
            --border-secondary: 1px solid ${tokens.colorBorderSecondary};
            --border-radius: ${tokens.borderRadius}px;

            --box-shadow: ${tokens.boxShadow};
            --box-shadow-secondary: ${tokens.boxShadowSecondary};
            --box-shadow-tertiary: ${tokens.boxShadowTertiary};

            --font-size-xl: ${tokens.fontSizeXL}px;
            --font-size-lg: ${tokens.fontSizeLG}px;
            --font-size: ${tokens.fontSize}px;
            --font-size-sm: ${tokens.fontSizeSM}px;

            --heading-size-5: ${tokens.fontSizeHeading5}px;
            --heading-height-5: calc(var(--heading-size-5) * 1.5);

            --heading-size-4: ${tokens.fontSizeHeading4}px;
            --heading-height-4: calc(var(--heading-size-4) * 1.4);

            --heading-size-3: ${tokens.fontSizeHeading3}px;
            --heading-height-3: calc(var(--heading-size-3) * 1.35);

            --heading-size-2: ${tokens.fontSizeHeading2}px;
            --heading-height-2: calc(var(--heading-size-2) * 1.35);

            --heading-size-1: ${tokens.fontSizeHeading1}px;
            --heading-height-1: calc(var(--heading-size-1) * 1.23);

            --spacing-xl: ${tokens.paddingXL}px;
            --spacing-lg: ${tokens.paddingLG}px;
            --spacing-md: ${tokens.paddingMD}px;
            --spacing: ${tokens.padding}px;
            --spacing-sm: ${tokens.paddingSM}px;
            --spacing-xs: ${tokens.paddingXS}px;
            --spacing-xxs: ${tokens.paddingXXS}px;

            --color-background: ${tokens.colorBgBase};

            --color-border: ${tokens.colorBorder};
            --color-border-secondary: ${tokens.colorBorderSecondary};

            --color-fill: ${tokens.colorFill};
            --color-fill-secondary: ${tokens.colorFillSecondary};
            --color-fill-tertiary: ${tokens.colorFillTertiary};
            --color-fill-quaternary: ${tokens.colorFillQuaternary};

            --color-text: ${tokens.colorText};
            --color-text-secondary: ${tokens.colorTextSecondary};
            --color-text-tertiary: ${tokens.colorTextTertiary};
            --color-text-quaternary: ${tokens.colorTextQuaternary};
            --color-text-light: ${tokens.colorTextLightSolid};

            --color-error: ${tokens.colorError};
            --color-error-background: ${tokens.colorErrorBg};
            --color-error-border: ${tokens.colorErrorBorder};
            --color-error-hover: ${tokens.colorErrorHover};

            --color-info: ${tokens.colorInfo};
            --color-info-background: ${tokens.colorInfoBg};
            --color-info-border: ${tokens.colorInfoBorder};
            --color-info-hover: ${tokens.colorInfoHover};

            --color-primary: ${tokens.colorPrimary};
            --color-primary-background: ${tokens.colorPrimaryBg};
            --color-primary-border: ${tokens.colorPrimaryBorder};
            --color-primary-hover: ${tokens.colorPrimaryHover};

            --color-success: ${tokens.colorSuccess};
            --color-success-background: ${tokens.colorSuccessBg};
            --color-success-border: ${tokens.colorSuccessBorder};
            --color-success-hover: ${tokens.colorSuccessHover};

            --color-warning: ${tokens.colorWarning};
            --color-warning-background: ${tokens.colorWarningBg};
            --color-warning-border: ${tokens.colorWarningBorder};
            --color-warning-hover: ${tokens.colorWarningHover};

            --blue-1: ${colors.blue[1]};
            --blue-2: ${colors.blue[2]};
            --blue-3: ${colors.blue[3]};
            --blue-4: ${colors.blue[4]};
            --blue-5: ${colors.blue[5]};
            --blue-6: ${colors.blue[6]};
            --blue-7: ${colors.blue[7]};
            --blue-8: ${colors.blue[8]};
            --blue-9: ${colors.blue[9]};
            --blue-10: ${colors.blue[10]};

            --green-1: ${colors.green[1]};
            --green-2: ${colors.green[2]};
            --green-3: ${colors.green[3]};
            --green-4: ${colors.green[4]};
            --green-5: ${colors.green[5]};
            --green-6: ${colors.green[6]};
            --green-7: ${colors.green[7]};
            --green-8: ${colors.green[8]};
            --green-9: ${colors.green[9]};
            --green-10: ${colors.green[10]};

            --lime-1: ${colors.lime[1]};
            --lime-2: ${colors.lime[2]};
            --lime-3: ${colors.lime[3]};
            --lime-4: ${colors.lime[4]};
            --lime-5: ${colors.lime[5]};
            --lime-6: ${colors.lime[6]};
            --lime-7: ${colors.lime[7]};
            --lime-8: ${colors.lime[8]};
            --lime-9: ${colors.lime[9]};
            --lime-10: ${colors.lime[10]};

            --magenta-1: ${colors.magenta[1]};
            --magenta-2: ${colors.magenta[2]};
            --magenta-3: ${colors.magenta[3]};
            --magenta-4: ${colors.magenta[4]};
            --magenta-5: ${colors.magenta[5]};
            --magenta-6: ${colors.magenta[6]};
            --magenta-7: ${colors.magenta[7]};
            --magenta-8: ${colors.magenta[8]};
            --magenta-9: ${colors.magenta[9]};
            --magenta-10: ${colors.magenta[10]};

            --neutral-0: ${colors.neutral[0]};
            --neutral-1: ${colors.neutral[1]};
            --neutral-2: ${colors.neutral[2]};
            --neutral-3: ${colors.neutral[3]};
            --neutral-4: ${colors.neutral[4]};
            --neutral-5: ${colors.neutral[5]};
            --neutral-6: ${colors.neutral[6]};
            --neutral-7: ${colors.neutral[7]};
            --neutral-8: ${colors.neutral[8]};
            --neutral-9: ${colors.neutral[9]};
            --neutral-10: ${colors.neutral[10]};
            --neutral-11: ${colors.neutral[11]};

            --orange-1: ${colors.orange[1]};
            --orange-2: ${colors.orange[2]};
            --orange-3: ${colors.orange[3]};
            --orange-4: ${colors.orange[4]};
            --orange-5: ${colors.orange[5]};
            --orange-6: ${colors.orange[6]};
            --orange-7: ${colors.orange[7]};
            --orange-8: ${colors.orange[8]};
            --orange-9: ${colors.orange[9]};
            --orange-10: ${colors.orange[10]};

            --purple-1: ${colors.purple[1]};
            --purple-2: ${colors.purple[2]};
            --purple-3: ${colors.purple[3]};
            --purple-4: ${colors.purple[4]};
            --purple-5: ${colors.purple[5]};
            --purple-6: ${colors.purple[6]};
            --purple-7: ${colors.purple[7]};
            --purple-8: ${colors.purple[8]};
            --purple-9: ${colors.purple[9]};
            --purple-10: ${colors.purple[10]};

            --red-1: ${colors.red[1]};
            --red-2: ${colors.red[2]};
            --red-3: ${colors.red[3]};
            --red-4: ${colors.red[4]};
            --red-5: ${colors.red[5]};
            --red-6: ${colors.red[6]};
            --red-7: ${colors.red[7]};
            --red-8: ${colors.red[8]};
            --red-9: ${colors.red[9]};
            --red-10: ${colors.red[10]};

            --teal-1: ${colors.teal[1]};
            --teal-2: ${colors.teal[2]};
            --teal-3: ${colors.teal[3]};
            --teal-4: ${colors.teal[4]};
            --teal-5: ${colors.teal[5]};
            --teal-6: ${colors.teal[6]};
            --teal-7: ${colors.teal[7]};
            --teal-8: ${colors.teal[8]};
            --teal-9: ${colors.teal[9]};
            --teal-10: ${colors.teal[10]};

            --yellow-1: ${colors.yellow[1]};
            --yellow-2: ${colors.yellow[2]};
            --yellow-3: ${colors.yellow[3]};
            --yellow-4: ${colors.yellow[4]};
            --yellow-5: ${colors.yellow[5]};
            --yellow-6: ${colors.yellow[6]};
            --yellow-7: ${colors.yellow[7]};
            --yellow-8: ${colors.yellow[8]};
            --yellow-9: ${colors.yellow[9]};
            --yellow-10: ${colors.yellow[10]};
          }
        `}
      />
      {children}
    </>
  );
};

export default StylesProvider;
