import { useMemo, useState } from 'react';
import { Button } from 'antd';

import BankAccountNumberDisplay from './BankAccountNumberDisplay';
import EditFundingAccountModal from './EditFundingAccountModal';

import AddFundingAccountModal from 'components/AddFundingAccountModal/AddFundingAccountModal';
import CondensedTable from 'components/CondensedTable/CondensedTable';
import Pill from 'components/Pill/Pill';
import useModalControls from 'hooks/useModalControls';
import { ACCOUNT_STATUS } from 'utilities/constants';
import { formatDateTime } from 'utilities/datesAndTimes';

const getTableColumns = (onEdit) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: 'Bank',
      dataIndex: 'bankName',
    },
    {
      title: 'Routing Number',
      dataIndex: 'routingNumber',
    },
    {
      title: 'Account Last 4',
      dataIndex: 'numberLast4',
      render: (numberLast4, record) => (
        <BankAccountNumberDisplay
          fundingAccountId={String(record.id)}
          numberLast4={numberLast4}
        />
      ),
    },
    {
      title: 'Date Added',
      dataIndex: 'createdDate',
      render: formatDateTime,
    },
    {
      title: 'Actions',
      render: (account) => {
        return (
          <Button
            data-testid={`edit-button-${account.id}`}
            size="small"
            onClick={() => onEdit(account)}
          >
            Edit
          </Button>
        );
      },
    },
  ];
};

const StatusTag = ({ status }) => {
  switch (status) {
    case ACCOUNT_STATUS.DEFAULT:
      return <Pill color="green">Default</Pill>;
    case ACCOUNT_STATUS.DISABLED:
      return <Pill color="red">Disabled</Pill>;
    case ACCOUNT_STATUS.NON_OPERATIONAL:
      return <Pill color="yellow">Non-Operational</Pill>;
    case ACCOUNT_STATUS.ENABLED:
      return <Pill color="blue">Enabled</Pill>;
    default:
      return <Pill>{status}</Pill>;
  }
};

const FundingAccountTable = ({
  fundingAccounts = [],
  canAddFundingAccount = false,
  loading,
}) => {
  const [selectedAccount, setSelectedAccount] = useState();
  const addFundingAccountModal = useModalControls();
  const editFundingAccountModal = useModalControls({
    afterClose: () => setSelectedAccount(undefined),
  });

  const columns = useMemo(() => {
    const handleEdit = (account) => {
      setSelectedAccount(account);
      editFundingAccountModal.show();
    };

    return getTableColumns(handleEdit);
  }, [fundingAccounts]);

  return (
    <>
      <AddFundingAccountModal
        {...addFundingAccountModal}
        refetchQueries={['GetCustomerFundingAccounts']}
      />
      <EditFundingAccountModal
        {...editFundingAccountModal}
        account={selectedAccount}
        refetchQueries={['GetCustomerFundingAccounts']}
      />
      <CondensedTable
        loading={loading}
        columns={columns}
        rowKey={(record) => record.id}
        data={fundingAccounts}
      />
      {canAddFundingAccount && (
        <Button
          type="primary"
          onClick={addFundingAccountModal.show}
          data-testid="add-funding-account-button"
          style={{ marginTop: 'var(--spacing)' }}
        >
          Add Funding Account
        </Button>
      )}
    </>
  );
};

export default FundingAccountTable;
