import { Tag } from 'antd';

import { styledWithTransientProps } from 'utilities/styles';

const StyledTag = styledWithTransientProps(Tag)(({ $textColor }) => {
  return `
    &.ant-tag {
      font-weight: 500;
      letter-spacing: 0.02rem;
      color: ${$textColor};
      .anticon {
        color: ${$textColor};
      }
    }
  `;
});

/**
 * @param {('blue'|'red'|'green'|'yellow'|'orange'|'purple'|'magenta'|'lime'|'teal')} color
 */

const Pill = ({ children, color, ...rest }) => {
  let tagColor, textColor;

  if (color) {
    tagColor = `var(--${color}-9)`;
    textColor = `var(--${color}-2)`;
  } else {
    tagColor = 'var(--neutral-3)';
    textColor = 'var(--neutral-9)';
  }

  return (
    <StyledTag $textColor={textColor} color={tagColor} {...rest}>
      {children}
    </StyledTag>
  );
};

export default Pill;
