import { useContext } from 'react';
import { theme } from 'antd';

import { ThemeContext } from 'providers/ThemeProvider/ThemeProvider';

const useTheme = () => {
  const themeContext = useContext(ThemeContext);
  const tokens = theme.useToken();
  return { ...themeContext, tokens: tokens.token };
};

export default useTheme;
