import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Alert, Flex, Form, Radio, Space, InputNumber } from 'antd';

import DatePicker from 'components/DatePicker/DatePicker';
import { GET_SETTLEMENT_PAYMENT_PLAN } from 'queries/settlements';
import {
  dateIsWithin30Days,
  formatDateToSubmit,
} from 'utilities/datesAndTimes';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';
import _isEqual from 'lodash/isEqual';

import EstimatedPayments from './EstimatedPayments';
import StepContainer from '../StepContainer';
import {
  RECOVERIES_STEPS,
  setNextStep,
  setPaymentDates,
  setPaymentPlan,
  useRecoveriesState,
} from './recoveriesState';

const { Item } = Form;

// customer service hours are  8:30am to 9pm EST so agents really shouldn't run into this
// but adding this check to prevent any issues
export const isAfterTodayPaymentCutoff = (date) => {
  const todayPaymentCutoffTimeUtc = dayjs().utc().endOf('day').format();
  return date.isSameOrBefore(todayPaymentCutoffTimeUtc);
};

const PaymentPlanDetailsStep = () => {
  const { offer, paymentDates, paymentPlan } = useRecoveriesState();
  const isPifOffer = offer?.offerType === SETTLEMENT_OFFER_TYPES.PIF;

  const [paymentDetailsForm] = Form.useForm();

  const [paymentPlanQueryLoading, setPaymentPlanQueryLoading] = useState(false);
  const [paymentPlanSchedule, setPaymentPlanSchedule] = useState([]);
  const [paymentPlanError, setPaymentPlanError] = useState(false);
  const [paymentStartDateResetMsg, setPaymentStartDateResetMsg] = useState('');

  const getDurationMonths = (cadence, planDuration) => {
    let duration;
    if (planDuration) {
      return planDuration;
    } else if (cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME) {
      duration = 1;
    } else {
      duration = offer.duration;
    }

    return duration;
  };

  const [getSettlementPaymentPlan, { loading: paymentPlanLoading }] =
    useLazyQuery(GET_SETTLEMENT_PAYMENT_PLAN, {
      onCompleted: ({ paymentPlanSchedule }) => {
        setPaymentPlanError(false);
        const paymentPlan = paymentPlanSchedule?.map((payment) => {
          return {
            date: payment.date,
            amount: payment.amount,
          };
        });

        const { paymentsBeforeExpiration, paymentsAfterExpiration } =
          paymentPlan.reduce(
            (acc, payment) => {
              if (dayjs(payment.date).isBefore(offer.expiration)) {
                acc.paymentsBeforeExpiration.push(payment);
              } else {
                acc.paymentsAfterExpiration.push(payment);
              }

              return acc;
            },
            { paymentsBeforeExpiration: [], paymentsAfterExpiration: [] },
          );

        if (paymentsAfterExpiration.length) {
          const amountToDistribute =
            paymentsAfterExpiration.reduce(
              (acc, payment) => acc + payment.amount,
              0,
            ) / paymentsBeforeExpiration.length;
          paymentsBeforeExpiration.forEach((payment) => {
            payment.amount = Math.round((payment.amount += amountToDistribute));
          });
        }

        setPaymentPlanSchedule(paymentsBeforeExpiration);
        setPaymentPlanQueryLoading(false);
      },
      onError: () => {
        setPaymentPlanError(true);
      },
    });

  const onDateChange = useCallback(
    (startDate) => {
      setPaymentStartDateResetMsg('');

      const durationMonths = getDurationMonths(
        paymentPlan.cadence,
        paymentPlan.planDuration,
      );

      if (startDate) {
        const endDate = dayjs().add(durationMonths, 'month');
        setPaymentDates(startDate.format('L'), endDate.format('L')); // MM/DD/YYYY
      }

      if (startDate && paymentPlan.cadence && durationMonths) {
        setPaymentPlanQueryLoading(true);
        getSettlementPaymentPlan({
          variables: {
            amount: offer.balance.settlementBalance,
            startDate: formatDateToSubmit(startDate),
            duration: durationMonths,
            cadence: paymentPlan.cadence,
          },
        });
      }
    },
    [paymentPlan.cadence, paymentDates.startDate, paymentPlan?.planDuration],
  );

  const onCadenceSelection = useCallback(
    ({ target: { value } }) => {
      setPaymentStartDateResetMsg('');
      setPaymentPlan({ ...paymentPlan, cadence: value });
      const durationMonths = getDurationMonths(value, paymentPlan.planDuration);

      if (
        value === SETTLEMENTS_CADENCE_VALUES.MONTHLY &&
        paymentDetailsForm.getFieldValue('firstPaymentDate')?.date() >= 29
      ) {
        paymentDetailsForm.setFieldValue('firstPaymentDate', undefined);
        setPaymentStartDateResetMsg(
          'Dates 29-31 are not valid for a MONTHLY cadence. Please select a new date.',
        );
      }

      if (paymentDates.startDate && durationMonths) {
        setPaymentPlanQueryLoading(true);
        getSettlementPaymentPlan({
          variables: {
            amount: offer.balance.settlementBalance,
            startDate: formatDateToSubmit(paymentDates.startDate),
            duration: durationMonths,
            cadence: value,
          },
        });
      }
    },
    [paymentPlan.cadence, paymentDates.startDate, paymentPlan?.planDuration],
  );

  const onPlanDurationChange = useCallback(
    (value) => {
      setPaymentPlan({
        ...paymentPlan,
        cadence: paymentPlan.cadence,
        planDuration: value,
        current: [],
      });

      const durationMonths = getDurationMonths(paymentPlan.cadence, value);
      if (paymentDates.startDate && paymentPlan.cadence) {
        setPaymentPlanQueryLoading(true);
        getSettlementPaymentPlan({
          variables: {
            amount: offer.balance.settlementBalance,
            startDate: formatDateToSubmit(paymentDates.startDate),
            duration: value ?? durationMonths,
            cadence: paymentPlan.cadence,
          },
        });
      }
    },
    [paymentPlan.cadence, paymentDates.startDate, paymentPlan?.planDuration],
  );

  useEffect(() => {
    const paymentPlanHasChanges = !_isEqual(
      paymentPlan?.current,
      paymentPlanSchedule,
    );

    const paymentSchedule = paymentPlanHasChanges
      ? paymentPlanSchedule
      : paymentPlan.current;

    if (!paymentPlanQueryLoading) {
      setPaymentPlan({
        cadence: paymentPlan.cadence,
        current: paymentSchedule,
      });
    }
  }, [paymentPlanQueryLoading, paymentPlanSchedule, paymentPlan.current]);

  const onFinish = async () => {
    try {
      await paymentDetailsForm.validateFields();
    } catch {
      return;
    }
    setNextStep(RECOVERIES_STEPS.FINALIZE_PAYMENT_PLAN);
  };

  const isDate29thTo31st = (selectedDate) => {
    const date = selectedDate.date();
    return date >= 29 && date <= 31;
  };

  const disabledDate = (d) => {
    return !d || !dateIsWithin30Days(d) || isAfterTodayPaymentCutoff(d);
  };

  const getPlanDurationInitialValue = () => {
    if (isPifOffer) {
      return paymentPlan.planDuration ?? offer.duration;
    } else {
      return null;
    }
  };

  return (
    <StepContainer
      title="Payment Plan Details"
      onContinue={onFinish}
      buttonProps={{ disabled: paymentPlanQueryLoading || paymentPlanError }}
    >
      <Flex justify="space-between">
        <Flex vertical={true}>
          <Form
            name="paymentDetailsForm"
            form={paymentDetailsForm}
            layout="vertical"
            initialValues={{
              planDuration: getPlanDurationInitialValue(),
              firstPaymentDate: paymentDates.startDate
                ? dayjs(paymentDates.startDate)
                : undefined,
              paymentCadence: paymentPlan.cadence ?? null,
            }}
          >
            <Item
              label="Select a Payment Cadence"
              name="paymentCadence"
              rules={[
                {
                  required: true,
                  message: 'Please select a payment cadence.',
                },
              ]}
            >
              <Radio.Group onChange={onCadenceSelection}>
                <Space direction="vertical">
                  <Radio
                    data-testid="monthlyCadence"
                    value={SETTLEMENTS_CADENCE_VALUES.MONTHLY}
                  >
                    Monthly
                  </Radio>
                  <Radio
                    data-testid="biweeklyCadence"
                    value={SETTLEMENTS_CADENCE_VALUES.BIWEEKLY}
                  >
                    Every 2 Weeks
                  </Radio>
                  <Radio
                    data-testid="weeklyCadence"
                    value={SETTLEMENTS_CADENCE_VALUES.WEEKLY}
                  >
                    Weekly
                  </Radio>
                  {isPifOffer && (
                    <Radio
                      data-testid="onceTimeCadence"
                      value={SETTLEMENTS_CADENCE_VALUES.ONE_TIME}
                    >
                      Lump Sum
                    </Radio>
                  )}
                </Space>
              </Radio.Group>
            </Item>
            <Item
              label={
                isPifOffer
                  ? 'Enter Plan Duration (in months)'
                  : 'Enter Payment Schedule Duration'
              }
              name="planDuration"
              rules={[
                ...(isPifOffer
                  ? [
                      {
                        required: true,
                        message: 'Please enter a plan duration in months.',
                      },
                    ]
                  : []),
                {
                  /* eslint-disable no-unused-vars */
                  validator: (_, value) => {
                    return new Promise((resolve, reject) => {
                      if (Number(value) > offer.duration) {
                        reject();
                      }
                      resolve();
                    });
                  },
                  message: `Max duration is ${offer.duration} months. Please re-enter.`,
                },
              ]}
            >
              <InputNumber
                data-testid="planDurationInput"
                onChange={onPlanDurationChange}
                style={{ width: 138, marginBottom: 'var(--spacing-sm)' }}
              />
            </Item>
            <Item
              label="Select a First Payment Date"
              name="firstPaymentDate"
              rules={[
                {
                  required: true,
                  message: 'Please select a first payment date.',
                },
              ]}
              style={{ marginBottom: '0px' }}
            >
              <DatePicker
                data-testid="paymentDatePicker"
                onChange={onDateChange}
                disabledDate={(d) => {
                  if (
                    paymentDetailsForm.getFieldValue('paymentCadence') ===
                    SETTLEMENTS_CADENCE_VALUES.MONTHLY
                  ) {
                    return disabledDate(d) || isDate29thTo31st(d);
                  }
                  return disabledDate(d);
                }}
                style={{ marginBottom: 'var(--spacing-sm)' }}
              />
            </Item>
            {paymentStartDateResetMsg && (
              <Alert
                banner
                type="warning"
                message={paymentStartDateResetMsg}
                style={{ width: '300px' }}
              />
            )}
            {paymentPlanError && (
              <Alert
                banner
                type="error"
                message="Failed to retrieve payment plan. Please try again."
                style={{ width: '250px' }}
              />
            )}
          </Form>
        </Flex>

        <EstimatedPayments loadingQuery={paymentPlanLoading} />
      </Flex>
    </StepContainer>
  );
};

export default PaymentPlanDetailsStep;
