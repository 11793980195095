import { Alert, Button, Flex, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import { VIEWS } from './CreditAccountPage';

const { Title } = Typography;

const StepContainer = ({
  children,
  buttonProps = {},
  buttonText = 'Continue',
  information = '',
  errorMessage = '',
  onContinue,
  onExit,
  title = '',
  ...rest
}) => {
  const history = useHistory();

  const exitFlow = () => {
    onExit();
    history.push(`./${VIEWS.CARD_DETAILS}`);
  };

  return (
    <Flex vertical={true} {...rest}>
      {title ? (
        <Title
          data-testid="step-title"
          level={5}
          style={{ marginBottom: 'var(--spacing)' }}
        >
          {title}
        </Title>
      ) : null}

      {information ? (
        <Alert
          banner
          data-testid="step-info"
          type="info"
          message={information}
          style={{ marginBottom: 'var(--spacing)' }}
        ></Alert>
      ) : null}

      <div style={{ marginBottom: 'var(--spacing)' }}>{children}</div>

      <div style={{ marginTop: 'auto' }}>
        {errorMessage && (
          <Alert
            banner
            data-testid="step-error"
            type="error"
            message={errorMessage}
            style={{ marginBottom: 'var(--spacing)' }}
          />
        )}
        <Flex gap={'var(--spacing)'}>
          {onContinue ? (
            <Button
              data-testid="step-continue-button"
              type="primary"
              onClick={onContinue}
              disabled={buttonProps.loading}
              {...buttonProps}
            >
              {buttonText}
            </Button>
          ) : null}
          {onExit ? (
            <Button
              danger
              data-testid="step-exit-button"
              disabled={buttonProps.loading}
              onClick={exitFlow}
            >
              Exit Process
            </Button>
          ) : null}
        </Flex>
      </div>
    </Flex>
  );
};

export default StepContainer;
