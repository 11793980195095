import { Card as AntCard } from 'antd';
import { RightSquareFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const HoverIndicator = styled(RightSquareFilled)`
  position: absolute;
  font-size: 24px;
  pointer-events: none;
  color: var(--color-primary);
  bottom: var(--spacing);
  right: var(--spacing);
`;

const Card = styled(AntCard)`
  position: relative;
  overflow-y: hidden;
  :hover {
    box-sizing: border-box;
    .hover-indicator svg {
      transition: color 0.5s;
      color: var(--color-primary-hover);
    }
  }
`;

const LinkCard = ({
  children,
  enabled = false,
  to = '/',
  onClick = () => {},
  onNavigate = () => {},
}) => {
  const handleClick = () => {
    onClick();
  };

  const handleNavigate = () => {
    onNavigate();
  };

  const comp = (
    <Card data-testid="card-body" hoverable={enabled} onClick={handleClick}>
      {children}
      {enabled ? (
        <span className="hover-indicator">
          <HoverIndicator />
        </span>
      ) : null}
    </Card>
  );

  return enabled ? (
    <Link data-testid="link-wrapper" to={to} onClick={handleNavigate}>
      {comp}
    </Link>
  ) : (
    comp
  );
};

export default LinkCard;
