import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Dropdown, Flex, Tooltip, Typography } from 'antd';
import Icon, { WifiOutlined } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

import TransactionDetailModal from './TransactionDetailModal';
import ReverseAuthorizationModal from './ReverseAuthorizationModal';
import FeeWaiverModal from './FeeWaiverModal';
import CondensedTable from 'components/CondensedTable/CondensedTable';
import useAccountQuery from 'hooks/useAccountQuery';
import useAnalytics from 'hooks/useAnalytics';
import useModalControls from 'hooks/useModalControls';
import { ANALYTICS_EVENTS, TRANSACTION_TYPE } from 'utilities/constants';
import { formatDate, formatDateDescriptive } from 'utilities/datesAndTimes';
import { formatDollarsFromCents } from 'utilities/helpers';
import { getColumnSearchProps } from 'utilities/tableHelpers';

const ACTION_KEY = Object.freeze({
  REVERSE_AUTHORIZATION: 'REVERSE_AUTHORIZATION',
  WAIVE_FEE: 'WAIVE_FEE',
});

const FEE_DESCRIPTIONS = Object.freeze([
  'CASH ADVANCE FEE', // 0401
  'LATE FEE', // 0402
  'INTEREST CHARGE-PURCHASES', // 0403
  'INTEREST CHARGE-CASH', // 0404
  'MINIMUM CHARGE', // 0403, 0404
  'ANNUAL FEE', // 0405
  'RETURNED PAYMENT FEE', // 0421
  'INTERNATIONAL TRANS FEE', // 0855
]);

const searchFilter = (dataIndex, value, record) => {
  const indexValue = _get(record, dataIndex, '');
  if (Number(value)) {
    return Math.abs(indexValue) === Math.round(Number(value) * 100);
  }

  return indexValue?.toString().toLowerCase().includes(value.toLowerCase());
};

const getTableColumns = (
  transactions,
  {
    defaultAction,
    menuItemAction,
    showFeeWaiver,
    showDigitalWalletTransactions,
  },
) => {
  // If auth history entries are in the array it should be safe to assume
  // all entries are auth history
  const containsAuthHistory = transactions.find(
    (transaction) => transaction.isFraudAuthorization,
  );

  const stateFilterOptions = _uniqBy(
    transactions.map((entry) => ({
      text: entry.state,
      value: entry.state,
    })),
    'value',
  );

  const statusFilterOptions = _uniqBy(
    transactions.map((entry) => ({
      text: entry.activityStatus,
      value: entry.activityStatus,
    })),
    'value',
  );

  const typeFilterOptions = _uniqBy(
    transactions.map((entry) => ({
      text: entry.type,
      value: entry.type,
    })),
    'value',
  );

  let columns = [
    {
      title: 'Authorization Date',
      defaultSortOrder: 'descend',
      render: (entry) => {
        // As far as I can tell, date on standard transaction results need to be processed
        // as UTC but authorization history results do not. Very cool.
        return formatDateDescriptive(entry.date, !entry.isFraudAuthorization);
      },
      sorter: (a, b) => {
        return a.date - b.date;
      },
    },
    {
      title: 'Posted Date',
      render: (entry) => {
        return formatDateDescriptive(entry.postedAt, true, '-');
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.postedAt - b.postedAt,
    },
    {
      title: 'Merchant Channel',
      dataIndex: 'merchantChannel',
      ...getColumnSearchProps('merchantChannel', searchFilter),
    },
    {
      title: 'Amount',
      render: (transaction) => formatDollarsFromCents(transaction.amount),
      ...getColumnSearchProps('amount', searchFilter),
    },
  ];

  if (containsAuthHistory) {
    columns.push({
      title: 'Is Fraud',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      render: (entry) => (entry.fraud ? 'Yes' : 'No'),
      onFilter: (option, entry) => entry.fraud === option,
    });
  } else {
    const cardLast4FilterOptions = _uniqBy(
      transactions.map((entry) => ({
        text: entry.cardLast4 || '-',
        value: entry.cardLast4 || '-',
      })),
      'value',
    );
    columns.push(
      {
        title: 'Card Last 4',
        filters: cardLast4FilterOptions,
        render: (entry) => {
          return (
            <Flex align="center" gap="var(--spacing-xs)">
              <Typography.Text> {entry.cardLast4 || '-'} </Typography.Text>
              {entry.virtualCardLast4 && showDigitalWalletTransactions && (
                <Tooltip
                  title={'Virtual Card Last 4: ' + entry.virtualCardLast4}
                >
                  <Icon
                    component={WifiOutlined}
                    style={{
                      color: 'var(--green-4)',
                      fontSize: '0.75rem',
                    }}
                  ></Icon>
                </Tooltip>
              )}
            </Flex>
          );
        },
        onFilter: (option, entry) => {
          return (entry.cardLast4 || '-') === option;
        },
      },
      {
        title: 'Status',
        dataIndex: 'activityStatus',
        filters: statusFilterOptions,
        onFilter: (selectedStatus, entry) =>
          entry.activityStatus === selectedStatus,
      },
    );
  }

  columns = [
    ...columns,
    {
      title: 'State',
      dataIndex: 'state',
      filters: stateFilterOptions,
      onFilter: (selectedState, entry) => entry.state === selectedState,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: typeFilterOptions,
      onFilter: (selectedType, entry) => entry.type === selectedType,
    },
    {
      title: 'Detail',
      render: (transaction) => {
        if (transaction.sourceActivityType === TRANSACTION_TYPE.PAYMENT) {
          return null;
        }

        let additionalActions = [];

        if (transaction.isPendingAuthorization) {
          additionalActions.push({
            label: 'Reverse this authorization',
            key: ACTION_KEY.REVERSE_AUTHORIZATION,
          });
        }

        if (
          showFeeWaiver &&
          FEE_DESCRIPTIONS.includes(transaction.merchantChannel)
        ) {
          additionalActions.push({
            label: 'Waive this fee',
            key: ACTION_KEY.WAIVE_FEE,
          });
        }

        if (!additionalActions.length) {
          additionalActions.push({
            label: 'No Additional Actions',
            key: '0',
            disabled: true,
          });
        }

        return (
          <Dropdown.Button
            data-testid={`detail-button-${transaction.transactionId}`}
            size="small"
            menu={{
              items: additionalActions,
              onClick: (e) => menuItemAction(e, transaction),
            }}
            onClick={() => defaultAction(transaction)}
          >
            Detail
          </Dropdown.Button>
        );
      },
    },
  ];

  return columns;
};

const TransactionTable = ({
  showSelectionActions = true,
  length,
  rowSelection,
  transactions = [],
  ...rest
}) => {
  const { trackEvent } = useAnalytics();
  const [focusedTransaction, setFocusedTransaction] = useState();
  const { showFeeWaiver, showDigitalWalletTransactions } = useFlags();
  const { accountId, customerId } = useParams();
  const { account } = useAccountQuery(customerId, accountId);

  const unfocusTransaction = () => setFocusedTransaction(undefined);

  const transactionDetailModal = useModalControls({
    onHide: unfocusTransaction,
  });
  const reverseAuthorizationModal = useModalControls({
    onHide: unfocusTransaction,
  });
  const feeWaiverModal = useModalControls({
    onHide: unfocusTransaction,
  });

  const columns = useMemo(() => {
    const openDetailModal = (transaction) => {
      setFocusedTransaction(transaction);
      transactionDetailModal.show();
      trackEvent(ANALYTICS_EVENTS.TRANSACTION_DETAIL_OPENED);
    };

    const handleMenuItemClick = (e, transaction) => {
      switch (e.key) {
        case ACTION_KEY.REVERSE_AUTHORIZATION:
          setFocusedTransaction(transaction);
          reverseAuthorizationModal.show();
          break;
        case ACTION_KEY.WAIVE_FEE:
          setFocusedTransaction(transaction);
          feeWaiverModal.show();
          break;
        default:
      }
    };

    return getTableColumns(transactions, {
      defaultAction: openDetailModal,
      menuItemAction: handleMenuItemClick,
      showFeeWaiver,
      showDigitalWalletTransactions,
    });
  }, [transactions, showFeeWaiver]);

  const buildCopyableTransactionText = (selectedRows) => {
    const selectedRowData = selectedRows?.map((row) => {
      return {
        date: formatDate(row.date, true),
        channel: row.merchantChannel,
        amount: formatDollarsFromCents(row.amount),
        status: row.state,
        type: row.type,
      };
    });

    const formattedSingleRowData = selectedRowData.map((row) =>
      Object.values(row).join('   '),
    );
    return Object.values(formattedSingleRowData).join('\n');
  };
  return (
    <>
      <ReverseAuthorizationModal
        {...reverseAuthorizationModal}
        account={account}
        transaction={focusedTransaction}
      />

      <FeeWaiverModal
        {...feeWaiverModal}
        account={account}
        transaction={focusedTransaction}
      />

      <TransactionDetailModal
        {...transactionDetailModal}
        transaction={focusedTransaction}
      />

      <CondensedTable
        data-testid="transactionsTable"
        showSelectionActions={showSelectionActions}
        columns={columns}
        data={transactions}
        length={length || transactions?.length}
        rowSelection={rowSelection}
        onCopy={buildCopyableTransactionText}
        {...rest}
      />
    </>
  );
};

export default TransactionTable;
