import { gql } from '@apollo/client';

const APPLICATION = gql`
  query GetApplicationByID($applicationId: String!) {
    applicationV2(applicationId: $applicationId) {
      applicationId
      applicationType
      sponsorBank
      status
      statusReason
      submission {
        addOnProducts {
          creditProtectionProducts {
            attributes {
              selection
              selectionKey
            }
            name
          }
        }
        applicants {
          applicantId
          contactDetails {
            address {
              address1
              address2
              city
              state
              zip
            }
            emailAddress
            phoneNumber
          }
          dateOfBirth
          firstName
          middleName
          lastName
          last4Ssn
          fullSsn
          financialDetails {
            income
            monthlyHousingAmount
          }
        }
        applicationType
        initialStatus
        legalDocuments {
          documentId
          documentType
          documentUri
        }
        productDetails {
          inquiryId
          inviteCode
          offerId
          prequalBundleId
          prequalExperience
          prequalPresentedOffers {
            offerId
            rank
          }
          productCode
          subChannelContextId
        }
        snapshotDocuments {
          content
          type
        }
        source
      }
      submittedAt
      updatedAt
    }
    applicationDocuments(applicationId: $applicationId) {
      aan {
        contentType
        uri
      }
      cag {
        contentType
        uri
      }
      disclosure {
        contentType
        uri
      }
      snapshots {
        contentType
        documentId
        snapshotType
        uri
      }
    }
    bureauReports(applicationId: $applicationId) {
      fetchedAt
      pullType
      requestId
      subscriberCode
      vendor
    }
  }
`;

const APPLICATION_SEARCH = gql`
  query ApplicationSearch(
    $applicationSearchRequest: ApplicationV2SearchRequestBodyInput!
  ) {
    applicationsV2(applicationSearchRequest: $applicationSearchRequest) {
      applicationId
      applicationType
      status
      statusReason
      submission {
        applicants {
          applicantId
          contactDetails {
            address {
              state
            }
            emailAddress
            phoneNumber
          }
          dateOfBirth
          firstName
          lastName
          last4Ssn
          fullSsn
        }
      }
    }
  }
`;

export { APPLICATION, APPLICATION_SEARCH };
