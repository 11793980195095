import dayjs from 'dayjs';

import { STATES_ABBR } from './constants';

export const dateIsWithin30Days = (date) => {
  const endOfToday = dayjs().endOf('day');
  const thirtyDaysFromNow = dayjs().add(30, 'day').endOf('day');
  return date.isAfter(endOfToday) && date.isBefore(thirtyDaysFromNow);
};
export const dateIsWithin90Days = (date) => {
  const endOfToday = dayjs().endOf('day');
  const ninetyDaysFromNow = dayjs().add(90, 'day').endOf('day');
  return date.isAfter(endOfToday) && date.isBefore(ninetyDaysFromNow);
};

export const getAgeFromDate = (date) => {
  let age = 0;
  if (date) {
    age = parseInt(dayjs().diff(dayjs(date), 'year'));
  }
  return age;
};

export const getDateFromJavaInstant = (javaInstant) => {
  return dayjs.unix(parseFloat(javaInstant));
};

export const getDueDate = (paymentInfo) => {
  let dueDate;
  if (paymentInfo?.printDueDate) {
    dueDate = dayjs(paymentInfo.printDueDate);
  } else if (paymentInfo?.nextStatementCloseDate) {
    // Follows this logic in the case that an invalid date is provided.
    // From "Implementation Suggestion": https://docs.google.com/document/d/1qSRona346yb437MCFv6WLHaPwAz9QX9ZdoAV0ttpimw/edit
    const cycleDate = dayjs(paymentInfo.nextStatementCloseDate);
    const cycleDay = cycleDate.date();
    const dueDay = cycleDay - 3;

    if (dueDay > 0) {
      dueDate = cycleDate.date(dueDay);
    } else {
      dueDate = cycleDate.date(28 + dueDay).subtract(1, 'month');
    }
  }
  return formatDate(dueDate);
};

export const getOrdinalSuffix = (number) => {
  if (!parseInt(number, 10)) {
    return '';
  }
  let j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

/**
 * Date Formatting
 * https://day.js.org/docs/en/display/format
 */
const applyFormat = (date, utc = false, format, fallback) => {
  if (!date) {
    return fallback;
  }
  const toFormat = utc ? dayjs.utc(date) : dayjs(date);
  return toFormat.isValid() ? toFormat.format(format) : fallback;
};

// 5/23/1988
export const formatDate = (date, utc, fallback) => {
  return applyFormat(date, utc, 'M/D/YYYY', fallback || '--/--/----');
};

// 5/23/1988, 5:23 AM
export const formatDateTime = (date, utc, fallback) => {
  return applyFormat(
    date,
    utc,
    'M/D/YYYY, h:mm A',
    fallback || '--/--/----, --:-- --',
  );
};

// Wed 5/23/1988, 5:23 AM
export const formatDateTimeWithWeekDay = (date, utc, fallback) => {
  return applyFormat(
    date,
    utc,
    'ddd M/D/YYYY, h:mm A',
    fallback || '--- --/--/----, --:-- --',
  );
};

// May 23rd, 1988
export const formatDateDescriptive = (date, utc, fallback) => {
  return applyFormat(date, utc, 'MMM D, YYYY', fallback || '--- --, ----');
};

// May 23rd, 1988, 5:23 AM
export const formatDateTimeDescriptive = (date, utc, fallback) => {
  return applyFormat(
    date,
    utc,
    'MMM D, YYYY, h:mm A',
    fallback || '--- --, ----, --:-- --',
  );
};

// 1988-05-23T05:23:00
export const formatDateTimeIso = (date, utc, fallback) => {
  return applyFormat(date, utc, fallback || 'YYYY-MM-DDTHH:mm:ss', null);
};

// 5:23 AM
export const formatTime = (date, utc, fallback) => {
  return applyFormat(date, utc, 'h:mm A', fallback || '--:-- --');
};

// 1988-05-23
export const formatDateToSubmit = (date, utc, fallback) => {
  return applyFormat(date, utc, fallback || 'YYYY-MM-DD', null);
};

// 052388
export const formatDateToSubmitCompact = (date, utc, fallback) => {
  return applyFormat(date, utc, 'MMDDYY', fallback || '------');
};

// 1988-05-23T05:23:00-500
export const formatDateToSubmitIsoUtcOffset = (date, utc, fallback) => {
  return applyFormat(date, utc, fallback || 'YYYY-MM-DDTHH:mm:ssZ', null);
};

/**
 * Timezone
 */
const ABBR_LOOKUP_BY_STATE = Object.keys(STATES_ABBR).map((key) => {
  const { name } = STATES_ABBR[key];
  return [new RegExp(name, 'i'), key];
});

const TIMEZONES = {
  'America/New_York': [
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'IN',
    'KY',
    'ME',
    'MD',
    'MA',
    'MI',
    'NH',
    'NJ',
    'NY',
    'NC',
    'OH',
    'PA',
    'RI',
    'SC',
    'VT',
    'VA',
    'WV',
  ],
  'America/Chicago': [
    'AL',
    'AR',
    'IL',
    'IA',
    'KS',
    'LA',
    'MN',
    'MS',
    'MO',
    'NE',
    'ND',
    'OK',
    'SD',
    'TN',
    'TX',
    'WI',
  ],
  'America/Denver': ['CO', 'ID', 'MT', 'NM', 'UT', 'WY'],
  'America/Los_Angeles': ['CA', 'NV', 'OR', 'WA'],
  'America/Anchorage': ['AK'],
  'Pacific/Honolulu': ['HI'],
  'America/Phoenix': ['AZ'],
  'America/Puerto_Rico': ['PR'],
  'Etc/UTC': ['AA', 'AE', 'AP'],
};

const TIMEZONE_LOOKUP = Object.keys(TIMEZONES).reduce((tzMap, key) => {
  TIMEZONES[key].forEach((state) => {
    tzMap[state] = key;
  });

  return tzMap;
}, {});

export function getUserLocalTime(userStateAbbreviation) {
  const failedTimeParse = { isValid: false };

  if (!userStateAbbreviation) {
    return failedTimeParse;
  }
  let userTimezone = TIMEZONE_LOOKUP[userStateAbbreviation];

  if (!userTimezone) {
    const abbrFromState = (() => {
      for (let [regex, abbr] of ABBR_LOOKUP_BY_STATE) {
        if (regex.test(userStateAbbreviation)) {
          return abbr;
        }
      }

      return null;
    })();
    userTimezone = TIMEZONE_LOOKUP[abbrFromState];

    if (!userTimezone) {
      return failedTimeParse;
    }
  }

  return dayjs().tz(userTimezone);
}

export const isAfterCutOffTime = (date) => {
  const now = date ? dayjs(date) : dayjs();
  const cutOffTime = dayjs()
    .tz('America/New_York')
    .set('hour', 18)
    .set('minute', 0)
    .set('second', 0);

  return now.isAfter(cutOffTime);
};

// Fee waiver scheduled down time UTC 12:00 - 12:15 AM as per TSYS
export const isFeeWaiverScheduledDowntime = () => {
  // convert to utc when comparing to avoid timezone variations.
  return dayjs()
    .utc()
    .isBetween(
      dayjs().utc().set('hour', 0).set('minute', 0).set('second', 0),
      dayjs().utc().set('hour', 0).set('minute', 15).set('second', 0),
    );
};

// date variables are expected to be strings in the format 'YYYY-MM-DD'
export const isWithinSixMonths = (date) => {
  if (!date) {
    return false;
  }
  return dayjs(date).isBefore(dayjs().add(6, 'month').add(1, 'day'));
};
