import { Typography } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  RightOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const STEP_STATUS = Object.freeze({
  blocked: 'blocked',
  pending: 'pending',
  positive: 'positive',
  warning: 'warning',
});

/**
 *  For steps in flows (Send Card, CBR, etc)
 *
 * @param {keyof STEP_STATUS} status
 * @param {string} title
 * @param {string} secondary
 * @returns
 */
const StepLabel = ({ status = STEP_STATUS.PENDING, title, secondary }) => {
  let icon;
  switch (status) {
    case STEP_STATUS.positive:
      icon = <CheckCircleFilled style={{ color: 'var(--color-success)' }} />;
      break;
    case STEP_STATUS.warning:
      icon = (
        <ExclamationCircleFilled style={{ color: 'var(--color-warning)' }} />
      );
      break;
    case STEP_STATUS.blocked:
      icon = <CloseCircleFilled style={{ color: 'var(--color-warning)' }} />;
      title = title || 'Unable to proceed';
      break;
    default:
      icon = <RightOutlined />;
  }

  let textType;
  if (status === STEP_STATUS.PENDING) {
    textType = 'secondary';
  }

  return (
    <Text type={textType}>
      {icon}
      {` ${title}`}
      {secondary ? (
        <>
          <br />
          <Text type="secondary" italic>
            {secondary}
          </Text>
        </>
      ) : undefined}
    </Text>
  );
};

export default StepLabel;
