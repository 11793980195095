import { useParams } from 'react-router-dom';
import { Alert, Form, Radio, Space, Typography } from 'antd';

import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS } from 'utilities/constants';

import StepContainer from '../StepContainer';
import {
  setNextStep,
  setBadCheckExists,
  useCreditBalanceRefundState,
  resetCreditBalanceRefundState,
  CREDIT_BALANCE_REFUND_STEPS,
} from './creditBalanceRefundState';

const { Paragraph } = Typography;

const BadCheckInquiryStep = ({ containerStyles = {} }) => {
  const { accountId, customerId } = useParams();
  const [badCheckInquiryForm] = Form.useForm();
  const { badCheckExists } = useCreditBalanceRefundState();
  const { trackEvent } = useAnalytics();

  const handleContinue = async () => {
    try {
      await badCheckInquiryForm.validateFields();
    } catch {
      return;
    }

    trackEvent(ANALYTICS_EVENTS.CBR_BAD_CHECKS_INQUIRY, {
      customerId,
      accountId,
      badCheckInquiryForm,
    });

    setNextStep(CREDIT_BALANCE_REFUND_STEPS.SUBMISSION_STEP);
  };

  const handleBadCheckSelection = (event) => {
    setBadCheckExists(event.target.value);
  };

  return (
    <StepContainer
      title="Returned or Non-Cashed Check?"
      buttonProps={{ disabled: badCheckExists }}
      onContinue={handleContinue}
      onExit={resetCreditBalanceRefundState}
      style={{ maxWidth: '650px', ...containerStyles }}
    >
      <Form
        form={badCheckInquiryForm}
        name="badCheckInquiry"
        layout="vertical"
        initialValues={{ badCheckExists }}
      >
        <Form.Item
          label="Is there a sticky or note for this customer informing you the most recent credit balance refund was either returned or not cashed?"
          name="badCheckExists"
          rules={[{ required: true, message: 'Please make a selection' }]}
        >
          <Radio.Group
            onChange={handleBadCheckSelection}
            value={badCheckExists}
          >
            <Space direction="vertical">
              <Radio data-testid="no-bad-check-exists-radio" value={false}>
                No, there is not a sticky or note indicating a returned or
                non-cashed CBR.
              </Radio>
              <Radio data-testid="yes-bad-check-exists-radio" value={true}>
                Yes, there is a sticky or note indicating a returned or
                non-cashed CBR.
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {badCheckExists ? (
          <>
            <Alert
              banner
              data-testid="bad-check-exists-message"
              type="warning"
              style={{ marginBottom: 'var(--spacing)' }}
              message="This request cannot be processed in Mission Control."
            />
            <Paragraph style={{ marginBottom: 0 }}>
              In order to process this CBR request, please use the "Transfer to
              CBR - Check" macro in Zendesk.
              <br />
              Please include that you've verified the address on file.
            </Paragraph>
          </>
        ) : undefined}
      </Form>
    </StepContainer>
  );
};

export default BadCheckInquiryStep;
