import AccountOverview from './AccountOverview';

const DebitOverview = () => {
  const getAccountOverviewParams = () => {
    return {
      accountLabel: 'Money',
      tags: [],
      enabledNavigation: false,
    };
  };

  return <AccountOverview {...getAccountOverviewParams()}></AccountOverview>;
};

export default DebitOverview;
