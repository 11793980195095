import { Divider, Modal as AntModal, Typography } from 'antd';
import styled from '@emotion/styled';

import Pill from 'components/Pill/Pill';
import EditCustomerInfoForm from 'components/EditCustomerInfoForm/EditCustomerInfoForm';
import EditCustomerAddressForm from 'components/EditCustomerAddressForm/EditCustomerAddressForm';

const Modal = styled(AntModal)`
  min-width: 60%;
  top: var(--spacing-lg);
`;

const EditCustomerInfoModal = ({ ...modalProps }) => {
  return (
    <Modal
      {...modalProps}
      title="Edit Customer Information"
      destroyOnClose={true}
      forceRender={true}
      footer={null}
    >
      <>
        <Typography.Text
          level={5}
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'inline-block',
          }}
        >
          <Pill color="yellow">Yellow</Pill>
          indicates a value that will be updated
        </Typography.Text>

        <Divider />

        <Typography.Title level={5}>General Information</Typography.Title>
        <EditCustomerInfoForm />

        <Divider />

        <Typography.Title level={5}>Address</Typography.Title>
        <EditCustomerAddressForm />
      </>
    </Modal>
  );
};

export default EditCustomerInfoModal;
