import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Form, Input, Space, Tooltip } from 'antd';
import { LoadingOutlined, UnlockOutlined } from '@ant-design/icons';
import { useMutation, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import Access from 'components/Access/Access';
import DatePicker from 'components/DatePicker/DatePicker';
import ErrorTooltip from 'components/ErrorTooltip/ErrorTooltip';
import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAnalytics from 'hooks/useAnalytics';
import useEditFormStatuses from 'hooks/useEditFormStatuses';
import useCustomerFullSsn from 'hooks/useCustomerFullSsn';
import useTimeoutValue from 'hooks/useTimeoutValue';

import { UPDATE_CUSTOMER_INFORMATION } from 'mutations/customerMeta';
import { CUSTOMER_META } from 'queries/customer';

import { formatDateToSubmit } from 'utilities/datesAndTimes';
import {
  AGENT_ROLES,
  ANALYTICS_EVENTS,
  BREAKPOINT,
  SIZE,
  STATUS,
} from 'utilities/constants';

const GeneralInfoGrid = styled.div`
  position: relative;

  @media (min-width: ${BREAKPOINT.SM_PX}) {
    display: grid;
    grid-gap: 0 var(--spacing);
    grid-template-areas:
      'firstName middleName'
      'lastName birthDate'
      'email rawPhoneNumber'
      'ssn .';
  }

  @media (min-width: ${BREAKPOINT.XL_PX}) {
    display: grid;
    grid-gap: 0 var(--spacing);
    grid-template-areas:
      'firstName middleName lastName'
      'birthDate email rawPhoneNumber'
      'ssn . .';
  }
`;

// Enables grid placement based on form item name
const FormItem = styled(Form.Item)`
  grid-area: ${(props) => props.name};
`;

const GENERAL_INFO = Object.freeze({
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  birthDate: 'birthDate',
  email: 'email',
  phone: 'rawPhoneNumber',
  ssn: 'ssn',
});

// These are internal ML numbers that agents are mistakenly
// setting as customers phone numbers.
const BLOCKED_NUMBERS = [
  '+17209618453',
  '+18557908860',
  '+18886958536',
  '+16199373483',
];

const EditCustomerInfoForm = () => {
  const formName = 'customerInfo';

  const { customerId } = useParams();
  const { trackEvent } = useAnalytics();

  const [form] = Form.useForm();
  const [customer, setCustomer] = useState();
  const [fullSsn, getFullSsn, ssnFailure, { loading: loadingFullSsn }] =
    useCustomerFullSsn();

  const [submissionError, setSubmissionError] = useState('');
  const [submissionWarning, setSubmissionWarning] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useTimeoutValue(
    false,
    3000,
  );

  const {
    containsChanges,
    getFieldStatus,
    initialValues,
    setInitialValues,
    resetInitialValue,
    updateFieldStatus,
  } = useEditFormStatuses(form, () => {
    setSubmissionWarning('');
  });

  const [getCustomerMeta, { loading: loadingCustomer }] = useLazyQuery(
    CUSTOMER_META,
    {
      fetchPolicy: 'network-only',
      variables: { customerId },
      onCompleted: (data) => {
        setCustomer(data.customer);
      },
    },
  );

  const [updateGeneralInfo, { loading: updatingGeneralInfo }] = useMutation(
    UPDATE_CUSTOMER_INFORMATION,
    {
      refetchQueries: ['GetCustomerOverview', 'GetCustomerMeta'],
      onCompleted: () => {
        setSubmissionSuccess(true);
        getCustomerMeta();
      },
      onError: ({ message }) => {
        setSubmissionError(message);
      },
    },
  );

  const getInitialSsn = () => {
    return fullSsn || `_____ ${customer?.lastFourSsn}`;
  };

  const resetSsnOnly = () => {
    const ssn = getInitialSsn();
    resetInitialValue(GENERAL_INFO.ssn, ssn);
  };

  const resetFormValues = () => {
    const contactInfo = Object.assign({}, customer?.contactInfo || {});

    let phone = '';
    if (contactInfo.rawPhoneNumber) {
      phone = contactInfo.rawPhoneNumber.replace('+1', '');
    }

    setInitialValues({
      [GENERAL_INFO.firstName]: contactInfo.firstName,
      [GENERAL_INFO.middleName]: contactInfo.middleName,
      [GENERAL_INFO.lastName]: contactInfo.lastName,
      [GENERAL_INFO.birthDate]: dayjs(customer?.birthDate),
      [GENERAL_INFO.email]: contactInfo.email,
      [GENERAL_INFO.phone]: phone,
      [GENERAL_INFO.ssn]: getInitialSsn(),
    });
  };

  const handleSubmit = async () => {
    setSubmissionError('');

    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    if (!containsChanges) {
      setSubmissionWarning(`You haven't made any updates to customer info.`);
      return;
    }

    trackEvent(ANALYTICS_EVENTS.GENERAL_INFO_EDIT_SAVED);
    return await updateGeneralInfo({
      variables: {
        customerId: customer.id,
        firstName: form.getFieldValue(GENERAL_INFO.firstName),
        middleName: form.getFieldValue(GENERAL_INFO.middleName),
        lastName: form.getFieldValue(GENERAL_INFO.lastName),
        birthDate: formatDateToSubmit(
          form.getFieldValue(GENERAL_INFO.birthDate),
        ),
        email: form.getFieldValue(GENERAL_INFO.email),
        phone: `+1${form.getFieldValue(GENERAL_INFO.phone)}`,
        ssn: fullSsn ? form.getFieldValue(GENERAL_INFO.ssn) : undefined,
      },
    });
  };

  const renderSSNButton = () => {
    if (fullSsn) {
      return null;
    }

    let TooltipComp = Tooltip;
    let tooltipText = 'Enable SSN editing';
    let showTooltip;

    if (ssnFailure) {
      TooltipComp = ErrorTooltip;
      tooltipText = ssnFailure;
      showTooltip = true;
    }

    return (
      <TooltipComp title={tooltipText} open={showTooltip}>
        <Button
          icon={loadingFullSsn ? <LoadingOutlined /> : <UnlockOutlined />}
          onClick={getFullSsn}
        />
      </TooltipComp>
    );
  };

  useEffect(() => {
    resetFormValues();
  }, [customer]);

  useEffect(() => {
    if (fullSsn) {
      resetSsnOnly();
    }
  }, [fullSsn]);

  useEffect(() => {
    getCustomerMeta();
  }, []);

  const formDisabled = updatingGeneralInfo || loadingCustomer;

  return (
    <Form
      form={form}
      name={formName}
      layout="vertical"
      initialValues={initialValues}
      onFieldsChange={updateFieldStatus}
      onFinish={handleSubmit}
      style={{ position: 'relative' }}
    >
      <GeneralInfoGrid>
        <SectionMessage
          data-testid="general-info-success-message"
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text="General Information Updated"
          cover={true}
          visible={submissionSuccess}
        ></SectionMessage>
        <FormItem
          label="First Name"
          name={GENERAL_INFO.firstName}
          rules={[
            { required: true, message: 'First name is required' },
            {
              pattern: /^[A-Za-z '-]*$/, // Letters, space, apostrophe, hyphen
              message:
                'First name cannot include special characters or numbers',
            },
          ]}
        >
          <Input
            data-testid="firstName-input"
            disabled={formDisabled}
            status={getFieldStatus(GENERAL_INFO.firstName)}
          />
        </FormItem>
        <FormItem
          label="Middle Name"
          name={GENERAL_INFO.middleName}
          rules={[
            {
              pattern: /^[A-Za-z ']*$/, // Letters, space, apostrophe
              message:
                'Middle name cannot include special characters or numbers',
            },
          ]}
        >
          <Input
            data-testid="middleName-input"
            disabled={formDisabled}
            status={getFieldStatus(GENERAL_INFO.middleName)}
          />
        </FormItem>
        <FormItem
          label="Last Name"
          name={GENERAL_INFO.lastName}
          rules={[
            { required: true, message: 'Last name is required' },
            {
              pattern: /^[A-Za-z '-]*$/, // Letters, space, apostrophe, hyphen
              message: 'Last name cannot include special characters or numbers',
            },
          ]}
        >
          <Input
            data-testid="lastName-input"
            disabled={formDisabled}
            status={getFieldStatus(GENERAL_INFO.lastName)}
          />
        </FormItem>
        <FormItem
          label="Date of Birth"
          name={GENERAL_INFO.birthDate}
          rules={[{ required: true, message: 'Date of Birth is required' }]}
        >
          {/* Need a better way of setting format than hardcoding */}
          <DatePicker
            data-testid="birthDate-input"
            disabled={formDisabled}
            disabledDate={(current) => {
              return dayjs(current).isAfter(dayjs().endOf('day'));
            }}
            status={getFieldStatus(GENERAL_INFO.birthDate)}
            style={{ width: '100%' }}
          />
        </FormItem>
        <FormItem
          label="Email"
          name={GENERAL_INFO.email}
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Invalid email address' },
          ]}
        >
          <Input
            data-testid="email-input"
            disabled={formDisabled}
            status={getFieldStatus(GENERAL_INFO.email)}
          />
        </FormItem>
        <FormItem
          label="Primary Phone"
          name={GENERAL_INFO.phone}
          rules={[
            { required: true, message: 'Primary Phone is required' },
            {
              validator: (settings, value) => {
                let validation = new Promise((resolve, reject) => {
                  let isBlockedNumber = BLOCKED_NUMBERS.includes(value);
                  if (isBlockedNumber) {
                    reject(new Error(settings.message));
                  } else {
                    resolve();
                  }
                });
                return validation;
              },
              message: 'This is a Mission Lane service number',
            },
          ]}
        >
          <Input
            data-testid="phone-input"
            disabled={formDisabled}
            prefix="+1"
            maxLength={10}
            status={getFieldStatus(GENERAL_INFO.phone)}
          />
        </FormItem>

        <Access roles={[AGENT_ROLES.SUPER_USER]}>
          {/* Not a fan of Ant making us wrap a FormItem in a FormItem for Input.Group to work :shrug:*/}

          <FormItem label="SSN" name={GENERAL_INFO.ssn} required>
            <Space.Compact>
              <Form.Item
                name={GENERAL_INFO.ssn}
                noStyle
                rules={
                  fullSsn
                    ? [
                        {
                          message: 'SSN must be 9 digits',
                          min: 9,
                          max: 9,
                          required: true,
                        },
                      ]
                    : []
                }
              >
                <Input
                  data-testid="ssn-input"
                  disabled={formDisabled || !fullSsn}
                  style={{ width: !fullSsn ? '85%' : '100%' }}
                  status={getFieldStatus(GENERAL_INFO.ssn)}
                />
              </Form.Item>
              {!fullSsn ? renderSSNButton() : null}
            </Space.Compact>
          </FormItem>
        </Access>
      </GeneralInfoGrid>

      {submissionWarning ? (
        <Alert
          banner
          data-testid="general-info-submission-warning"
          type="warning"
          message={submissionWarning}
          style={{ marginBottom: 'var(--spacing)' }}
        ></Alert>
      ) : null}

      {submissionError ? (
        <Alert
          banner
          data-testid="general-info-failure-message"
          type="error"
          message={submissionError}
          style={{ marginBottom: 'var(--spacing)' }}
        ></Alert>
      ) : null}

      <Button type="primary" htmlType="submit">
        Update General Info
      </Button>
    </Form>
  );
};

export default EditCustomerInfoForm;
