import SanitizeHTML from 'components/SanitizeHTML/SanitizeHTML';

const HighlightHTML = ({ html, textToHighlight, ...props }) => {
  if (textToHighlight) {
    html = html.replace(
      new RegExp(textToHighlight, 'ig'),
      (hit) =>
        `<span style="color: var(--yellow-2); background-color: var(--yellow-9);">${hit}</span>`,
    );
  }

  return <SanitizeHTML html={html} {...props} />;
};

export default HighlightHTML;
