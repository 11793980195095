import { useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import { useLazyQuery } from '@apollo/client';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Typography } from 'antd';

import ApplicationSearchResults from './ApplicationSearchResults';
import {
  SidebarPortal,
  SidebarDivider,
  SidebarContainer,
  SidebarScrollContainer,
} from 'layouts/DashboardLayout/Sidebar';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import SearchCriteria from 'components/SearchCriteria/SearchCriteria';
import useAnalytics from 'hooks/useAnalytics';
import useAgent from 'hooks/useAgent';
import { APPLICATION_SEARCH } from 'queries/applications';

import {
  ANALYTICS_EVENTS,
  CUSTOMER_SEARCH_FIELD,
  SIZE,
} from 'utilities/constants';

import {
  clearApplicationSearchResults,
  setSearchExecuted,
  setApplicationSearchCriteria,
  setApplicationSearchResults,
  useApplicationSearchState,
} from './applicationSearchState';

const ApplicationSearchPage = () => {
  const agent = useAgent();
  const { trackEvent } = useAnalytics();
  const [searchForm] = Form.useForm();
  const [showEmptyFieldsMessage, setShowEmptyFieldsMessage] = useState(false);
  const { searchExecuted, searchCriteria, searchResults } =
    useApplicationSearchState();

  const [searchApplications, applicationsQuery] = useLazyQuery(
    APPLICATION_SEARCH,
    {
      onCompleted: ({ applicationsV2 }) => {
        setApplicationSearchResults(applicationsV2);
      },
    },
  );

  const parseSearchCriteria = () => {
    const formValues = searchForm.getFieldsValue();
    const searchFields = {};
    Object.keys(formValues).forEach((key) => {
      let fieldValue = formValues[key];
      if (
        fieldValue !== '' &&
        fieldValue !== undefined &&
        fieldValue !== null
      ) {
        if (key === CUSTOMER_SEARCH_FIELD.FULL_SSN.name) {
          fieldValue = fieldValue.replace(/\D/g, ''); // strip non-digit characters
        }
        searchFields[key] = fieldValue;
      }
    });
    if (!Object.values(searchFields).length) {
      throw new Error();
    }
    return searchFields;
  };

  const handleSubmit = async () => {
    let searchFields;
    setShowEmptyFieldsMessage(false);
    try {
      searchFields = parseSearchCriteria();
    } catch (error) {
      setShowEmptyFieldsMessage(true);
      return;
    }

    trackEvent(ANALYTICS_EVENTS.APPLICATION_SEARCH_CLICKED, { searchFields });

    try {
      setSearchExecuted(true);
      setApplicationSearchCriteria(searchFields);
      clearApplicationSearchResults();
      const response = await searchApplications({
        variables: {
          applicationSearchRequest: {
            fullSsn: searchFields.fullSsn,
            emailAddress: searchFields.email,
          },
        },
      });
      const results = response?.data?.applicationsV2;
      setApplicationSearchResults(results);
    } catch (error) {
      console.log(error);
    }
  };
  useKeyPressEvent('Enter', handleSubmit);

  const onReset = () => {
    const fields = searchForm.getFieldsValue();
    searchForm.setFieldsValue(
      Object.keys(fields).forEach((name) => {
        searchForm.setFieldValue(name, '');
      }),
    );
  };

  const onRemoveCriteria = (name) => {
    searchForm.setFieldValue(name, '');
    handleSubmit();
  };

  const getFieldProps = (field) => {
    return {
      ...field,
    };
  };

  const loading = applicationsQuery.loading;

  return (
    <>
      <SidebarPortal>
        <SidebarContainer>
          <Flex vertical={true}>
            <Typography.Title
              level={5}
              style={{
                margin: 0,
              }}
            >
              Applicant Search
            </Typography.Title>
          </Flex>

          <SidebarDivider />
        </SidebarContainer>

        <SidebarScrollContainer>
          <Form
            form={searchForm}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{}}
          >
            {agent?.isSuperUser && (
              <Form.Item {...getFieldProps(CUSTOMER_SEARCH_FIELD.FULL_SSN)}>
                <Input />
              </Form.Item>
            )}

            <Form.Item {...getFieldProps(CUSTOMER_SEARCH_FIELD.EMAIL)}>
              <Input />
            </Form.Item>
          </Form>
        </SidebarScrollContainer>

        <SidebarContainer>
          <SidebarDivider />
          {showEmptyFieldsMessage && (
            <Typography.Paragraph type="danger">
              No search criteria provided.
            </Typography.Paragraph>
          )}
          <Flex gap="var(--spacing-sm)">
            <Button
              danger
              data-testid="resetButton"
              onClick={onReset}
              disabled={loading}
            >
              Clear Form
            </Button>
            <Button
              data-testid="searchButton"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Search
            </Button>
          </Flex>
        </SidebarContainer>
      </SidebarPortal>

      {searchExecuted && searchResults ? (
        <>
          <SearchCriteria
            criteria={searchCriteria}
            onRemoveCriteria={onRemoveCriteria}
          />
          <ApplicationSearchResults
            searchResults={searchResults}
            loading={loading}
          />
        </>
      ) : (
        <SectionMessage
          height={'500px'}
          icon={SearchOutlined}
          text="Search Applicants"
          size={SIZE.MD}
          body={
            <Typography.Paragraph type="secondary" align="center">
              Use the fields to search for applicants. <br />
              It is highly encouraged you use multiple fields to obtian accurate
              results.
            </Typography.Paragraph>
          }
        />
      )}
    </>
  );
};

export default ApplicationSearchPage;
