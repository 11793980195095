import { Button, Flex, Typography } from 'antd';
import Icon, {
  CheckCircleOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MehOutlined,
  StopOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';

import { SIZE, STATUS } from 'utilities/constants';

let MessageContainer = styled.div(
  ({ cover, coverBackgroundColor, minHeight, visible }) => {
    minHeight = cover ? 0 : minHeight;

    const pointerEvents = visible ? 'auto' : 'none';
    const opacity = visible ? 1 : 0;

    let coverStyles = '';
    if (cover) {
      coverStyles = `
      z-index: 100;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${coverBackgroundColor};
    `;
    }

    return `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: ${minHeight};
    opacity: ${opacity};
    pointer-events: ${pointerEvents};
    transition: opacity .3s;
    ${coverStyles}
  `;
  },
);

const SectionMessage = ({
  buttons = [],
  cover = false,
  coverBackgroundColor = 'var(--color-background)',
  height,
  icon,
  body,
  text = '',
  verticalButtons = false,
  canClose = false,
  handleClose,
  size = SIZE.LG,
  status = STATUS.INVALID,
  visible = true,
  ...rest
}) => {
  let iconSize = 0;
  let textSize = 0;
  let spacing = '';
  let iconColor;

  switch (size) {
    case SIZE.XS:
      iconSize = '1.5rem';
      textSize = 5;
      height = height || '150px';
      spacing = 'var(--spacing-xs)';
      break;
    case SIZE.SM:
      iconSize = '2rem';
      textSize = 5;
      height = height || '200px';
      spacing = 'var(--spacing-sm)';
      break;
    case SIZE.MD:
      iconSize = '3.25rem';
      textSize = 3;
      height = height || '250px';
      spacing = 'var(--spacing)';
      break;
    case SIZE.LG:
    default:
      iconSize = '4.5rem';
      textSize = 3;
      height = height || '300px';
      spacing = 'var(--spacing-lg)';
  }

  switch (status) {
    case STATUS.ERROR:
      icon = icon ?? StopOutlined;
      iconColor = 'var(--color-error)';
      break;
    case STATUS.LOADING:
      icon = LoadingOutlined;
      iconColor = 'var(--purple-3)';
      break;
    case STATUS.SUCCESS:
      icon = icon ?? CheckCircleOutlined;
      iconColor = 'var(--color-success)';
      break;
    case STATUS.INFORMATION:
      icon = icon ?? InfoCircleOutlined;
      iconColor = 'var(--color-info)';
      break;
    case STATUS.WARNING:
      icon = WarningOutlined;
      iconColor = 'var(--color-warning)';
      break;
    case STATUS.PENDING:
      icon = ClockCircleOutlined;
      iconColor = 'var(--color-warning)';
      break;
    case STATUS.INVALID:
    default:
      icon = icon ?? MehOutlined;
      iconColor = 'var(--color-text-secondary)';
  }

  return (
    <MessageContainer
      minHeight={height}
      cover={cover}
      coverBackgroundColor={coverBackgroundColor}
      visible={visible}
      {...rest}
    >
      <Flex vertical={true} align="center" gap={spacing}>
        <Icon
          type="secondary"
          component={icon}
          style={{
            color: iconColor,
            fontSize: iconSize,
          }}
        />
        <Typography.Title
          type="secondary"
          level={textSize}
          style={{ margin: 0, textAlign: 'center' }}
        >
          {text}
        </Typography.Title>
        {body ?? null}
        {buttons.length ? (
          <Flex
            justify="center"
            vertical={verticalButtons}
            style={{ marginTop: 'var(--spacing-lg)' }}
          >
            {buttons.map(
              (
                { text, ghost = true, type = 'primary', ...buttonProps },
                index,
              ) => (
                <Button
                  key={`message-button-${index}`}
                  type={type}
                  ghost={ghost}
                  {...buttonProps}
                >
                  {text}
                </Button>
              ),
            )}
          </Flex>
        ) : null}
      </Flex>
      {canClose && (
        <Button
          onClick={handleClose}
          type="primary"
          style={{
            position: 'absolute',
            bottom: 'var(--spacing)',
            right: 'var(--spacing)',
          }}
        >
          Close
        </Button>
      )}
    </MessageContainer>
  );
};

export default SectionMessage;
