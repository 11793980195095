import FundingAccountStep from '../FundingAccountStep';
import {
  RECOVERIES_STEPS,
  setFundingAccount,
  setNextStep,
  useRecoveriesState,
} from './recoveriesState';

const FundingAccountStepWrapper = () => {
  const { offer } = useRecoveriesState();
  const isOneTimeAchOffer = offer?.offerType.includes('OneTimeAch');

  const { fundingAccount } = useRecoveriesState();

  const handleContinue = async () => {
    if (isOneTimeAchOffer) {
      return setNextStep(RECOVERIES_STEPS.DISCLOSURE);
    }

    setNextStep(RECOVERIES_STEPS.PAYMENT_PLAN_DETAILS);
  };

  return (
    <FundingAccountStep
      fundingAccount={fundingAccount}
      setFundingAccount={setFundingAccount}
      onContinue={handleContinue}
    />
  );
};

export default FundingAccountStepWrapper;
