import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Collapse,
  Descriptions,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';

import { INCOME_VERIFICATION_DOCUMENTS } from 'queries/incomeVerification';
import { UPDATE_INCOME_VERIFICATION_STATUS } from 'mutations/incomeVerification';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import CondensedTable from 'components/CondensedTable/CondensedTable';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import { STATUS, SIZE } from 'utilities/constants';
import { currencyFormatter } from 'utilities/helpers';
import useApplicationQuery from 'hooks/useApplicationQuery';
import useTimeoutValue from 'hooks/useTimeoutValue';
import {
  DOCUMENT_STATUS_OPTIONS,
  rejectionReasonOptions,
  statusOptions,
} from './documentUtils';

const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const POIVerificationPage = () => {
  const { applicationId } = useParams();
  const [form] = Form.useForm();
  const { applicant, applicationQuery } = useApplicationQuery(applicationId);

  const [currentDocuments, setCurrentDocuments] = useState([]);

  const [verificationStatus, setVerificationStatus] = useState('');
  const [verificationDocuments, setVerificationDocuments] = useState([]);
  const [customRejectionMessage, setCustomRejectionMessage] = useState('');

  const [submissionWarning, setSubmissionWarning] = useState('');
  const [updateFailure, setUpdateFailure] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useTimeoutValue(false, 2500);
  const [verificationStatusAlertShown, setVerificationStatusAlertShown] =
    useState(false);

  const selfReportedIncome = useMemo(() => {
    return applicant?.financialDetails?.income || '';
  }, [applicant]);

  const getUpdatedVerificationStatus = () => {
    if (!currentDocuments.length) {
      return;
    }

    const allAccepted = currentDocuments.every(
      (document) =>
        document.documentStatus === DOCUMENT_STATUS_OPTIONS.ACCEPTED,
    );

    if (allAccepted) {
      return DOCUMENT_STATUS_OPTIONS.ACCEPTED;
    }

    const allRejected = currentDocuments.every(
      (document) =>
        document.documentStatus === DOCUMENT_STATUS_OPTIONS.REJECTED,
    );

    if (allRejected) {
      return DOCUMENT_STATUS_OPTIONS.REJECTED;
    }

    return DOCUMENT_STATUS_OPTIONS.REQUESTED_UPDATES;
  };

  const updatedVerificationStatus = getUpdatedVerificationStatus();

  useEffect(() => {
    if (
      updatedVerificationStatus &&
      updatedVerificationStatus !== DOCUMENT_STATUS_OPTIONS.ACCEPTED
    ) {
      setVerificationStatusAlertShown(true);
    }
  }, [updatedVerificationStatus]);

  const incomeDocumentsQuery = useQuery(INCOME_VERIFICATION_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    variables: { applicationId },
    onCompleted: (data) => {
      const documents =
        data?.incomeVerificationDocuments?.verificationDocuments;
      setVerificationStatus(
        data?.incomeVerificationDocuments?.verificationStatus,
      );
      setVerificationDocuments(documents);
      setCustomRejectionMessage(
        data?.incomeVerificationDocuments?.customRejectionMessage,
      );
    },
  });

  const [updateApplicationStatus, updateApplicationStatusMutation] =
    useMutation(UPDATE_INCOME_VERIFICATION_STATUS, {
      onCompleted: () => {
        setUpdateSuccess(true);
        setUpdateFailure(false);
        setCurrentDocuments([]);
        form.resetFields();
      },
      onError: () => {
        setUpdateFailure(true);
      },
      refetchQueries: ['ApplicationDocumentsV2'],
    });

  const handleSubmit = async (values) => {
    const { rejectionMessage, rejectApplication, acceptApplication } = {
      ...values,
    };

    if (Object.values(values).every((value) => !value)) {
      return setSubmissionWarning('There are no changes to submit.');
    }

    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    setUpdateFailure(false);

    let updatedVerificationStatus = getUpdatedVerificationStatus();

    // this covers the scenario where
    // - all docs have been rejected but intention is not to reject entire application
    // - all documents accepted but they are requesting more
    if (!rejectApplication && !acceptApplication) {
      updatedVerificationStatus = DOCUMENT_STATUS_OPTIONS.REQUESTED_UPDATES;
    }

    const variables = {
      applicationId,
      customRejectionMessage: rejectionMessage,
      documentRequests: currentDocuments,
      verificationStatus: updatedVerificationStatus,
    };

    try {
      updateApplicationStatus({
        variables,
        refetchQueries: ['IncomeVerificationDocuments'],
      });
    } catch (error) {
      console.error('Failed to update income verification status', error);
    }
  };

  const handleReviewStatusChange = (reviewStatus, record) => {
    setSubmissionWarning('');

    const foundDoc = currentDocuments.find(
      (doc) => doc.documentId === record.documentId,
    );
    if (!foundDoc) {
      return setCurrentDocuments([
        ...currentDocuments,
        { documentId: record.documentId, documentStatus: reviewStatus },
      ]);
    }
    setCurrentDocuments(
      currentDocuments.map((doc) => {
        if (doc.documentId === record.documentId) {
          if (doc.documentStatus === DOCUMENT_STATUS_OPTIONS.REJECTED) {
            return {
              ...doc,
              documentStatus: reviewStatus,
              documentStatusReason: undefined,
            };
          }

          return { ...doc, documentStatus: reviewStatus };
        }
        return doc;
      }),
    );
  };

  const handleReviewReasonChange = (reviewReason, record) => {
    const foundDoc = currentDocuments.find(
      (doc) => doc.documentId === record.documentId,
    );
    if (!foundDoc) {
      return setCurrentDocuments([
        ...currentDocuments,
        { documentId: record.documentId, documentStatusReason: reviewReason },
      ]);
    }

    setCurrentDocuments(
      currentDocuments.map((doc) => {
        if (doc.documentId === record.documentId) {
          return { ...doc, documentStatusReason: reviewReason };
        }
        return doc;
      }),
    );
  };

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'updatedAt',
    },
    {
      title: 'Document Type',
      dataIndex: 'documentType',
    },
    {
      title: 'Document Link',
      render: (document) => (
        <a
          href={document.documentLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </a>
      ),
    },
    {
      title: 'Current Status',
      dataIndex: 'documentStatus',
      render: (_, record) => {
        const rejectionReason = rejectionReasonOptions.find((reason) => {
          return reason.value === record.documentStatusReason;
        })?.label;

        return (
          <>
            <Text>{record.documentStatus}</Text>
            {record.documentStatus !== DOCUMENT_STATUS_OPTIONS.ACCEPTED && (
              <>
                <br />
                <Text type="secondary">Reason: {rejectionReason}</Text>
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'Review Status',
      render: (_, record) => {
        return (
          <Form.Item noStyle name={`${record.documentId}-reviewStatus}`}>
            <Select
              data-testid="review-status-dropdown"
              options={statusOptions}
              onChange={(e) => {
                handleReviewStatusChange(e, record);
              }}
              style={{ width: 200 }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Review Reason',
      render: (_, record) => {
        const currentReviewStatus = form.getFieldValue(
          `${record.documentId}-reviewStatus}`,
          form,
        );

        const notAccepted =
          currentReviewStatus &&
          currentReviewStatus !== DOCUMENT_STATUS_OPTIONS.ACCEPTED;

        const disableReasonDropdown = notAccepted ? false : true;

        const hasError =
          notAccepted &&
          !form.getFieldValue(`${record.documentId}-reviewReason}`);

        return (
          <Form.Item
            noStyle={hasError ? false : true}
            name={`${record.documentId}-reviewReason}`}
            validateStatus={hasError ? 'error' : 'success'}
            style={{ width: 300 }}
            {...(hasError && {
              help: 'Reason is required when document is rejected or requesting changes.',
            })}
            rules={[
              {
                validator: (_, value) => {
                  if (notAccepted && !value) {
                    return Promise.reject(
                      new Error(
                        'Reason is required when document is rejected or requesting changes.',
                      ),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Select
              data-testid="review-reason-dropdown"
              disabled={disableReasonDropdown}
              style={{ width: 300 }}
              options={rejectionReasonOptions}
              onChange={(e) => handleReviewReasonChange(e, record)}
            />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Card title="POI Verification">
      <Form
        form={form}
        onFinish={handleSubmit}
        disabled={updateApplicationStatusMutation.loading}
      >
        <QueryBoundary
          mode={QueryBoundary.MODE.MESSAGE}
          loadingMessage="Loading Application Documents"
          loadingOverride={
            incomeDocumentsQuery.loading || applicationQuery.loading
          }
          query={incomeDocumentsQuery}
        >
          {!verificationDocuments ? (
            <SectionMessage
              status={STATUS.WARNING}
              size={SIZE.MD}
              text="No Income Verification Documents to Review"
            />
          ) : (
            <>
              <Flex vertical>
                <Descriptions
                  bordered
                  column={1}
                  size="small"
                  style={{ width: '100%', marginBottom: 'var(--spacing)' }}
                  labelStyle={{ width: '20%' }}
                >
                  <Descriptions.Item label="Income Verification Status">
                    {verificationStatus}
                  </Descriptions.Item>
                  {customRejectionMessage && (
                    <Descriptions.Item label="Additional Information">
                      <Collapse>
                        <Panel header="View" key="1">
                          {customRejectionMessage}
                        </Panel>
                      </Collapse>
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Self Reported Income">
                    {currencyFormatter(selfReportedIncome)}
                  </Descriptions.Item>
                </Descriptions>
              </Flex>
              <CondensedTable
                data-testid="verification-documents-table"
                columns={columns}
                data={verificationDocuments ?? []}
                rowKey={(document) => document.documentId}
              />
              <Form.Item
                name="rejectionMessage"
                label={
                  <Text strong>
                    Would you like to provide the customer with additional
                    reasons for rejection?
                  </Text>
                }
                labelCol={{ span: 24 }}
                style={{ marginTop: 'var(--spacing)' }}
              >
                <TextArea rows={5} />
              </Form.Item>
            </>
          )}
          {submissionWarning && (
            <Alert
              type="warning"
              message={submissionWarning}
              style={{ marginBottom: 'var(--spacing)' }}
            ></Alert>
          )}
          {updateSuccess && (
            <SectionMessage
              data-testid="update-success-message"
              status={STATUS.SUCCESS}
              text={'Income verification documents updated successfully!'}
              cover={true}
              visible={updateSuccess}
            />
          )}
          {updateFailure && (
            <Alert
              type="error"
              message="Failed to update document status. Please try again."
              style={{ marginTop: 'var(--spacing-sm)' }}
            />
          )}
          {!verificationStatusAlertShown &&
            updatedVerificationStatus &&
            updatedVerificationStatus !== DOCUMENT_STATUS_OPTIONS.ACCEPTED && (
              <Alert
                banner
                type="warning"
                message={
                  <>
                    <strong>Note: </strong> Upon clicking Submit, applicant will
                    receive an email containing the reason(s) provided for
                    Rejection or Requested Changes
                  </>
                }
                style={{ marginBottom: 'var(--spacing-sm)' }}
              />
            )}

          {updatedVerificationStatus &&
            updatedVerificationStatus !== DOCUMENT_STATUS_OPTIONS.ACCEPTED && (
              <Alert
                banner
                type="warning"
                message={
                  <>
                    <strong>Note: </strong> Upon clicking Submit, applicant will
                    receive an email containing the reason(s) provided for
                    Rejection or Requested Changes
                  </>
                }
                style={{ marginBottom: 'var(--spacing-sm)' }}
              />
            )}

          {updatedVerificationStatus === DOCUMENT_STATUS_OPTIONS.ACCEPTED && (
            <Form.Item name="acceptApplication" valuePropName="checked">
              <Checkbox data-testid="accept-application-checkbox">
                <strong>ACCEPT</strong> this application.
              </Checkbox>
            </Form.Item>
          )}

          {updatedVerificationStatus === DOCUMENT_STATUS_OPTIONS.REJECTED && (
            <Form.Item name="rejectApplication" valuePropName="checked">
              <Checkbox data-testid="reject-application-checkbox">
                <strong>REJECT</strong> this application.
              </Checkbox>
            </Form.Item>
          )}

          {verificationDocuments && (
            <Button
              data-testid="submit-button"
              type="primary"
              htmlType="submit"
              loading={updateApplicationStatusMutation.loading}
            >
              Submit
            </Button>
          )}
        </QueryBoundary>
      </Form>
    </Card>
  );
};

export default POIVerificationPage;
