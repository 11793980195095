import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Card, Form, Select, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';

import ReasonsEvidenceFormList from './ReasonsEvidenceFormList';
import QueryResult from 'components/QueryResult/QueryResult';

import useAgent from 'hooks/useAgent';
import {
  NEW_FRAUD_CASE_FOR_APPLICATION,
  NEW_FRAUD_CASE_FOR_CUSTOMER,
} from 'mutations/newFraudCase';
import { CASE_OPTIONS } from 'queries/fraudCases';
import { formatDateToSubmitIsoUtcOffset } from 'utilities/datesAndTimes';

const { Option } = Select;

const FraudNewCaseForm = ({ setAddingNewCase }) => {
  const agent = useAgent();
  const { applicationId, customerId } = useParams();
  const [showSubmissionFailure, setShowSubmissionFailure] = useState(false);

  const [newCaseForm] = Form.useForm();
  const {
    loading: loadingOpt,
    errors: errorOpt,
    data: dataOpt,
  } = useQuery(CASE_OPTIONS);

  const [submitNewCase, { loading }] = useMutation(
    applicationId
      ? NEW_FRAUD_CASE_FOR_APPLICATION
      : NEW_FRAUD_CASE_FOR_CUSTOMER,
    {
      refetchQueries: ['FraudCases'],
    },
  );

  const handleNewCaseCancel = () => {
    setAddingNewCase(false);
    newCaseForm.resetFields();
  };

  const handleNewCaseSubmit = async () => {
    let values;
    try {
      values = await newCaseForm.validateFields();
    } catch (error) {
      return;
    }

    const variables = {
      ...values,
      ...{
        applicationId,
        customerId,
        reasons: JSON.stringify({ reasons: values.reasons }),
        evidence: JSON.stringify({ evidence: values.evidence }),
        actor: agent?.user?.name || 'anonymous',
        initiatedAt: formatDateToSubmitIsoUtcOffset(Date.now()),
      },
    };

    try {
      setShowSubmissionFailure(false);
      await submitNewCase({
        variables,
      });
      setAddingNewCase(false);
      newCaseForm.resetFields();
    } catch (error) {
      setShowSubmissionFailure(true);
    }
  };

  return (
    <QueryResult
      loading={loadingOpt}
      error={errorOpt}
      data={dataOpt}
      called={true}
    >
      <Card title="Add New Case">
        <Form
          title="New Case"
          form={newCaseForm}
          style={{ width: '50%', minWidth: '700px' }}
        >
          <Form.Item
            label={<Typography.Text strong>Fraud Type</Typography.Text>}
            name="fraudType"
            data-testid="fraud-type"
            rules={[
              {
                required: true,
                message: 'Please select a fraud type!',
              },
            ]}
          >
            <Select placeholder="Select a fraud type">
              {dataOpt?.caseOptionsV2?.fraudType.map((option) => {
                return (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={<Typography.Text strong>Product</Typography.Text>}
            name="product"
            data-testid="product"
            rules={[
              {
                required: true,
                message: 'Please select a product!',
              },
            ]}
          >
            <Select placeholder="Select a product type">
              {dataOpt?.caseOptionsV2?.product.map((option) => {
                return (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={<Typography.Text strong>Action Type</Typography.Text>}
            name="actionType"
            data-testid="action-type"
            rules={[
              {
                required: true,
                message: 'Please select an action type!',
              },
            ]}
          >
            <Select placeholder="Select a state">
              {dataOpt?.caseOptionsV2?.state.map((option) => {
                return (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <ReasonsEvidenceFormList />
          {showSubmissionFailure ? (
            <Alert
              type="error"
              message="Failed to create new fraud case. Please try again."
              style={{ marginBottom: 'var(--spacing)' }}
            />
          ) : null}
        </Form>

        <Button onClick={handleNewCaseCancel}>Cancel</Button>
        <Button
          loading={loading}
          style={{ marginLeft: 5 }}
          type="primary"
          onClick={handleNewCaseSubmit}
        >
          Create New Case
        </Button>
      </Card>
    </QueryResult>
  );
};

export default FraudNewCaseForm;
