import { useLayoutEffect, useRef, useState } from 'react';
import { Tooltip, Typography } from 'antd';
import Icon, { CopyOutlined, LikeOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import copy from 'copy-to-clipboard';

import useTimeoutValue from 'hooks/useTimeoutValue';
import { SIZE, STATUS } from 'utilities/constants';
import { styledWithTransientProps } from 'utilities/styles';

const getFontSize = (size) => {
  let fontSize;
  switch (size) {
    case SIZE.SM:
      fontSize = 'var(--font-size-sm)';
      break;
    case SIZE.LG:
      fontSize = 'var(--font-size-lg)';
      break;
    default:
      fontSize = 'var(--font-size)';
  }
  return fontSize;
};

const DisplayLabel = styled(Typography.Text)(({ size, status }) => {
  const color =
    status === STATUS.ERROR
      ? 'var(--color-error)'
      : 'var(--color-text-secondary)';

  return `
    display: block;
    color: ${color};
    font-size: ${getFontSize(size)};
    font-weight: 300;
    margin-bottom: var(--spacing-xxs);
  `;
});

const DisplayText = styled('span')(({ size, status }) => {
  let color = status === STATUS.ERROR ? 'var(--color-error)' : 'inherit';

  return `
    color: ${color};
    font-size: ${getFontSize(size)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  `;
});

const LabelIcon = styled(Icon)`
  margin-left: var(--spacing-xxs);
`;

const InteractiveWrap = ({ children, onClick }) => {
  return onClick ? (
    <Typography.Link
      role="button"
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      {children}
    </Typography.Link>
  ) : (
    children
  );
};

/**
 * @param {Object} props
 * @param {string} props.label - Label text
 * @param {string} props.labelIcon - Icon to be displayed right of the label
 * @param {Function} props.onLabelIconClick - Function to be executed on clicking of the label icon (makes icon interactive)
 * @param {Function} props.onValueClick - Function to be executed on clicking of the value text (makes text interactive)
 */
const DisplayValue = ({
  children,
  canCopy,
  copiedValue,
  label = '',
  labelIcon,
  onLabelIconClick,
  onValueClick,
  size = SIZE.MD,
  status,
}) => {
  let textRef = useRef();
  let [valueCopied, setValueCopied] = useTimeoutValue(false, 2000);
  let [renderTooltip, setRenderTooltip] = useState(false);

  // If text is overflowing, render a tooltip containing full text.
  let renderText = () => {
    let text = (
      <DisplayText ref={textRef} size={size} status={status}>
        {children}
      </DisplayText>
    );
    if (renderTooltip) {
      text = <Tooltip title={children}>{text}</Tooltip>;
    }
    return <InteractiveWrap onClick={onValueClick}>{text}</InteractiveWrap>;
  };

  let renderCopyTooltipText = () => {
    return valueCopied ? (
      <span>
        {label} copied <LikeOutlined />
      </span>
    ) : (
      `Copy ${label}`
    );
  };

  let copyValue = () => {
    setValueCopied(true);
    copy(copiedValue || `${children}`);
  };

  useLayoutEffect(() => {
    let displayText = textRef.current;
    if (displayText) {
      setRenderTooltip(displayText.scrollWidth > displayText.clientWidth);
    }
  });

  return (
    <>
      <DisplayLabel status={status} size={size}>
        {label}
        {labelIcon ? (
          <InteractiveWrap onClick={onLabelIconClick}>
            <LabelIcon component={labelIcon} />
          </InteractiveWrap>
        ) : null}
        {canCopy ? (
          <InteractiveWrap onClick={copyValue}>
            <Tooltip title={renderCopyTooltipText()}>
              <LabelIcon component={CopyOutlined} />
            </Tooltip>
          </InteractiveWrap>
        ) : null}
      </DisplayLabel>
      {renderText()}
    </>
  );
};

export default DisplayValue;
