import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, Skeleton, Space, Tooltip } from 'antd';
import { LikeOutlined, RedoOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import ErrorTooltip from 'components/ErrorTooltip/ErrorTooltip';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { RESET_AUTHORIZATION_COUNTERS } from 'mutations/transactions';
import { AUTHORIZATION_COUNTERS } from 'queries/transactions';

const CountSkeleton = styled(Skeleton)`
  min-width: 1ch;
  ul {
    margin-bottom: 0;
  }
`;

const CountContainer = styled(Flex)`
  padding: 0 var(--spacing-sm);
  border: var(--border);
  border-right: 0;
  border-radius: 6px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
`;

const DeclinedAuthorizationControl = () => {
  const { accountId } = useParams();
  const [declinedCount, setDeclineCount] = useState('-');
  const [showLoadFailure, setShowLoadFailure] = useTimeoutValue(false);
  const [showResetFailure, setShowResetFailure] = useTimeoutValue(false);
  const [showSuccess, setShowSuccess] = useTimeoutValue(false);

  const { loading } = useQuery(AUTHORIZATION_COUNTERS, {
    variables: { accountId },
    onCompleted: (data) => {
      setDeclineCount(data.accountDailyAuthorizationCounters.declined.count);
    },
    onError: () => {
      setShowLoadFailure(true);
    },
  });

  const [resetAuthorizationCounters, resetMutation] = useMutation(
    RESET_AUTHORIZATION_COUNTERS,
    {
      variables: { accountId },
      onCompleted: () => {
        setDeclineCount(0);
        setShowSuccess(true);
      },
      onError: () => {
        setShowResetFailure(true);
      },
    },
  );

  const renderButton = () => {
    let button = (
      <Button
        data-testid="reset-authorization-counters-button"
        icon={<RedoOutlined />}
        disabled={loading}
        loading={resetMutation.loading}
        onClick={resetAuthorizationCounters}
      ></Button>
    );

    if (showLoadFailure || showResetFailure) {
      const action = showLoadFailure ? 'load' : 'reset';
      button = (
        <ErrorTooltip
          open={true}
          placement="topRight"
          title={`Failed to ${action} authorization counters`}
        >
          {button}
        </ErrorTooltip>
      );
    } else {
      button = (
        <Tooltip
          open={showSuccess || undefined}
          placement="topRight"
          title={() => {
            return showSuccess ? (
              <span>
                Decline count reset <LikeOutlined />
              </span>
            ) : (
              'Reset declined authorization count'
            );
          }}
        >
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  return (
    <Space.Compact>
      <CountContainer gap="var(--spacing-xxs)" align="center">
        <span>Declines:</span>
        {loading ? (
          <CountSkeleton
            active={true}
            title={false}
            paragraph={{ rows: [{ width: '1rem' }] }}
          />
        ) : (
          declinedCount
        )}
      </CountContainer>
      {renderButton()}
    </Space.Compact>
  );
};

export default DeclinedAuthorizationControl;
