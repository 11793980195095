import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Dropdown,
  Flex,
  Menu,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

import { SEND_PRE_OFFER_EMAIL } from 'mutations/settlements';

import useTimeoutValue from 'hooks/useTimeoutValue';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { formatDollarsFromCents, getDefaultPayments } from 'utilities/helpers';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';

import {
  RECOVERIES_STEPS,
  setNextStep,
  setOffer,
  setFundingAccount,
  setPaymentDates,
  setPaymentPlan,
} from './recoveriesState';

const { Text, Title } = Typography;

const OfferCard = ({
  offer,
  hasPendingPayment,
  hasScheduledPayment,
  thirdParty,
}) => {
  const { accountId, customerId } = useParams();

  const amount = offer?.amount;
  const outstandingBalance = offer?.outstandingBalance;
  const durationMonths = offer?.durationMonths;
  const offerDurationFromToday = dayjs().add(offer?.durationMonths, 'month');
  const isPifOffer = offer.offerName.includes(SETTLEMENT_OFFER_TYPES.PIF);
  const settlementPercentage = Math.round((amount / outstandingBalance) * 100);

  const [sendPreOfferEmailSuccess, setSendPreOfferEmailSuccess] =
    useTimeoutValue(false, 3000);
  const [sendPreOfferEmailErrorMsg, setSendPreOfferEmailErrorMsg] =
    useState('');

  const payments = getDefaultPayments(
    amount,
    dayjs(),
    offerDurationFromToday,
    durationMonths,
  );

  const estimatedPaymentsText = (
    <Text>
      <CalendarOutlined /> <strong>With a start date of today,</strong> a
      customer would owe around:
      <br />
      <strong>{formatDollarsFromCents(payments.monthly)} | monthly</strong>,
      over {durationMonths} months <br />
      <strong>
        {formatDollarsFromCents(payments.bimonthly)} | every 2 weeks
      </strong>
      , over {durationMonths} months
      <br />
      <strong>{formatDollarsFromCents(payments.weekly)} | weekly</strong>, over{' '}
      {durationMonths} months
    </Text>
  );

  const OfferTitle = () => {
    const text = !isPifOffer
      ? `${settlementPercentage}% of balance, up to ${durationMonths} months`
      : `Up to ${durationMonths} months`;

    return (
      <>
        {offer.key}. {isPifOffer ? 'Pay in Full' : 'Settle in Full'}
        <br />
        <Text type="secondary">{text}</Text>{' '}
      </>
    );
  };

  const [sendPreOfferEmail, { loading: sendingPreOfferEmail }] = useMutation(
    SEND_PRE_OFFER_EMAIL,
    {
      onCompleted: () => {
        setSendPreOfferEmailSuccess(true);
        setSendPreOfferEmailErrorMsg('');
      },
      onError: () => {
        setSendPreOfferEmailErrorMsg(
          'Failed to send pre-offer email. Please try again.',
        );
      },
    },
  );

  const onSendEmail = () => {
    sendPreOfferEmail({
      variables: {
        availableOfferId: offer.offerId,
        accountId,
        customerId,
      },
    });
  };

  const onOfferSelection = () => {
    setOffer({
      balance: { settlementBalance: amount, outstandingBalance },
      duration: offer?.durationMonths,
      expiration: offerDurationFromToday,
      offerId: offer?.offerId,
      offerName: offer?.offerName,
      offerType: isPifOffer
        ? SETTLEMENT_OFFER_TYPES.PIF
        : SETTLEMENT_OFFER_TYPES.SIF,
      settlementPercentage: `${settlementPercentage}%`,
    });
    setNextStep(RECOVERIES_STEPS.ADD_FUNDING_ACCOUNT);
  };

  const onDebitPaymentSelection = () => {
    setOffer({
      balance: { settlementBalance: amount, outstandingBalance },
      duration: 1,
      expiration: dayjs().add(2, 'day'),
      offerId: offer?.offerId,
      offerName: offer?.offerName,
      offerType: isPifOffer ? 'PIFOneTimeDebit' : 'SIFOneTimeDebit',
      settlementPercentage: `${settlementPercentage}%`,
    });
    setPaymentPlan({
      cadence: SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT,
      current: [{ amount: offer?.amount, date: formatDateToSubmit(dayjs()) }],
    });
    setFundingAccount('--', 'Debit Card Payment');
    setPaymentDates(dayjs().format('L'), dayjs().add(1, 'day').format('L'));
    setNextStep(RECOVERIES_STEPS.DISCLOSURE);
  };

  const onOptionalPaymentPlanSelection = () => {
    setOffer({
      balance: { settlementBalance: amount, outstandingBalance },
      duration: offer?.durationMonths,
      expiration: offerDurationFromToday,
      offerId: offer?.offerId,
      offerName: offer?.offerName,
      offerType: isPifOffer
        ? 'PIFOptionalPaymentPlan'
        : 'SIFOptionalPaymentPlan',
      settlementPercentage: `${settlementPercentage}%`,
    });
    setNextStep(RECOVERIES_STEPS.DISCLOSURE);
  };

  const buttons = (
    <Flex
      vertical={true}
      justify="center"
      style={{ paddingLeft: 24, paddingRight: 24 }}
    >
      <Flex gap="var(--spacing-sm)" justify="space-between" width="100%">
        <Tooltip
          title={
            hasPendingPayment || hasScheduledPayment
              ? 'Cannot activate with a pending or scheduled payment'
              : ''
          }
        >
          <Dropdown
            trigger={['click']}
            disabled={hasPendingPayment || hasScheduledPayment}
            dropdownRender={() => (
              <Menu>
                <Menu.Item key="paymentPlan">
                  <Button onClick={onOfferSelection} style={{ width: '100%' }}>
                    Payment Plan
                  </Button>
                </Menu.Item>
                <Menu.Item key="debit">
                  <Button
                    onClick={onDebitPaymentSelection}
                    style={{ width: '100%' }}
                  >
                    One Time Debit
                  </Button>
                </Menu.Item>
                <Menu.Item key="optional">
                  <Button
                    onClick={onOptionalPaymentPlanSelection}
                    style={{ width: '100%' }}
                  >
                    Flexible Payment Plan
                  </Button>
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              key="selectOffer"
              data-testid={`selectOffer-${offer.key}`}
              type="primary"
              style={{ width: '100%' }}
            >
              Select Offer
            </Button>
          </Dropdown>
        </Tooltip>
        {!isPifOffer && (
          <>
            <Popconfirm
              title={<Title level={5}>Send offer letter via email?</Title>}
              description={
                thirdParty?.email ? (
                  <Text type="warning">
                    An email will also be sent to the <br />
                    3rd party managing this account
                  </Text>
                ) : (
                  ''
                )
              }
              onConfirm={onSendEmail}
              okText="Send"
              cancelText="Cancel"
              okButtonProps={
                thirdParty?.email
                  ? { style: { marginTop: 'var(--spacing-sm)' } }
                  : {}
              }
              disabled={hasPendingPayment || hasScheduledPayment}
            >
              <Button
                key="sendOfferEmail"
                data-testid="sendOfferEmail"
                type="primary"
                loading={sendingPreOfferEmail}
                disabled={hasPendingPayment || hasScheduledPayment}
              >
                {sendPreOfferEmailSuccess ? (
                  <>
                    Email Sent <CheckCircleOutlined />
                  </>
                ) : (
                  <MailOutlined />
                )}
              </Button>
            </Popconfirm>
          </>
        )}
      </Flex>
      {sendPreOfferEmailErrorMsg && (
        <Alert
          banner
          type="error"
          message={sendPreOfferEmailErrorMsg}
          style={{
            marginTop: 'var(--spacing-sm)',
            width: '100%',
          }}
        />
      )}
    </Flex>
  );

  return (
    <Card
      type="inner"
      style={{ minWidth: 350 }}
      title={<OfferTitle />}
      actions={[buttons]}
    >
      Program Amount: {formatDollarsFromCents(amount)}
      <br />
      <strong>
        Plan Due Date:{' '}
        {dayjs().add(offer?.durationMonths, 'month').format('LL')}
      </strong>
      <br />
      --
      <br /> <br />
      <strong>Estimated Payments</strong>
      <br />
      <br />
      {estimatedPaymentsText}
    </Card>
  );
};

export default OfferCard;
