import { SettingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Flex,
  Form,
  Input,
  Slider,
  Tooltip,
  Typography,
} from 'antd';

import {
  SidebarPortal,
  SidebarDivider,
  SidebarScrollContainer,
} from 'layouts/DashboardLayout/Sidebar';

import Pill from 'components/Pill/Pill';

import { CUSTOMER_SEARCH_FIELD, SIZE } from 'utilities/constants';

const { Text, Title } = Typography;

const ThemeTestPage = () => {
  return (
    <>
      {/* <SidebarPortal>
        <Flex vertical={true}>
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Customer Search
          </Typography.Title>
        </Flex>

        <SidebarDivider />

        <Flex align="center" justify="space-between">
          <Tooltip title="Configure search fields">
            <Button
              data-testid="configure-search-fields"
              type="link"
              icon={<SettingOutlined style={{ fontSize: '1.2rem' }} />}
              style={{
                display: 'flex',
              }}
            ></Button>
          </Tooltip>
        </Flex>

        <SidebarDivider />

        <SidebarScrollContainer>
          <Form layout="vertical">
            <Form.Item {...CUSTOMER_SEARCH_FIELD.FULL_SSN}>
              <Input placeholder="Pizza" />
            </Form.Item>

            <Form.Item {...CUSTOMER_SEARCH_FIELD.EMAIL}>
              <Input placeholder="beans@can.com" />
            </Form.Item>
          </Form>
        </SidebarScrollContainer>

        <div>
          <SidebarDivider />
          <Flex gap="var(--spacing-sm)">
            <Button
              danger
              data-testid="resetButton"
              // onClick={onReset}
              // disabled={loading}
            >
              Clear Form
            </Button>
            <Button
              data-testid="searchButton"
              type="primary"
              // onClick={handleSubmit}
              // loading={loading}
            >
              Search
            </Button>
          </Flex>
        </div>
      </SidebarPortal> */}

      <Flex vertical={true} gap="24px">
        <Flex gap="24px">
          <Flex vertical={true}>
            <Title level={1}>Level 1 Heading</Title>
            <Title level={2}>Level 2 Heading</Title>
            {/* <Typography.Title level={3}>Level 3 Heading</Typography.Title>
            <Typography.Title level={4}>Level 4 Heading</Typography.Title>
            <Typography.Title level={5}>Level 5 Heading</Typography.Title> */}
          </Flex>
          <Flex vertical={true}>
            <Text>Ant Design (default)</Text>
            <Text type="secondary">Ant Design (secondary)</Text>
            <Text disabled>Ant Design (disabled)</Text>
            <Text type="success">Ant Design (success)</Text>
            <Text type="warning">Ant Design (warning)</Text>
            <Text type="danger">Ant Design (danger)</Text>
            <Text mark>Ant Design (mark)</Text>
            <Text code>Ant Design (code)</Text>
            <Text keyboard>Ant Design (keyboard)</Text>
            <Text underline>Ant Design (underline)</Text>
            <Text delete>Ant Design (delete)</Text>
            <Text strong>Ant Design (strong)</Text>
            <Text italic>Ant Design (italic)</Text>
          </Flex>
          <Flex vertical={true}>
            <a>Hey</a>
          </Flex>
        </Flex>

        <SidebarDivider />
        <Flex gap="24px">
          <Button color="default" variant="solid">
            Primary
          </Button>
          <Button color="default" variant="outlined">
            Primary
          </Button>
          <Button color="default" variant="dashed">
            Primary
          </Button>
          <Button color="default" variant="filled">
            Primary
          </Button>
          <Button color="default" variant="text">
            Primary
          </Button>
          <Button color="default" variant="link">
            Primary
          </Button>
        </Flex>

        <Flex gap="24px">
          <Button color="primary" variant="solid">
            Primary
          </Button>
          <Button color="primary" variant="outlined">
            Primary
          </Button>
          <Button color="primary" variant="dashed">
            Primary
          </Button>
          <Button color="primary" variant="filled">
            Primary
          </Button>
          <Button color="primary" variant="text">
            Primary
          </Button>
          <Button color="primary" variant="link">
            Primary
          </Button>
        </Flex>

        <Flex gap="24px">
          <Button color="danger" variant="solid">
            Danger
          </Button>
          <Button color="danger" variant="outlined">
            Danger
          </Button>
          <Button color="danger" variant="dashed">
            Danger
          </Button>
          <Button color="danger" variant="filled">
            Danger
          </Button>
          <Button color="danger" variant="text">
            Danger
          </Button>
          <Button color="danger" variant="link">
            Danger
          </Button>
        </Flex>

        <SidebarDivider />

        <Flex>
          <Pill>Hawaii</Pill>
          <Pill color="green">Activated</Pill>
          <Pill color="yellow">Warning</Pill>
          <Pill color="red">Failed</Pill>
          <Pill color="blue">Information</Pill>
          <Pill color="orange">Grapefruit</Pill>
          <Pill color="purple">Very Cool</Pill>
          <Pill color="magenta">So Chic</Pill>
          <Pill color="lime">So Sour</Pill>
          <Pill color="teal">So Chill</Pill>
        </Flex>

        <SidebarDivider />

        <Flex gap="24px">
          <Alert type="info" message="This is an info"></Alert>
          <Alert type="success" message="This is a success"></Alert>
          <Alert type="warning" message="This is a warning"></Alert>
          <Alert type="error" message="This is a error"></Alert>
        </Flex>

        <Flex gap="24px">
          <Alert type="info" banner message="This is an info"></Alert>
          <Alert type="success" banner message="This is a success"></Alert>
          <Alert type="warning" banner message="This is a warning"></Alert>
          <Alert type="error" banner message="This is a error"></Alert>
        </Flex>

        <SidebarDivider />

        <Flex gap="24px" vertical={true}>
          <Slider defaultValue={30} />
          <Slider defaultValue={30} disabled={true} />
          <Slider range defaultValue={[20, 50]} />
        </Flex>

        <SidebarDivider />

        <Flex gap="24px" vertical={true}>
          <Card title="This is a card"> </Card>
        </Flex>

        <SidebarDivider />

        <Descriptions
          title="User Info"
          bordered={true}
          items={[
            {
              key: '1',
              label: 'UserName',
              children: 'Zhou Maomao',
            },
            {
              key: '2',
              label: 'Telephone',
              children: '1810000000',
            },
            {
              key: '3',
              label: 'Live',
              children: 'Hangzhou, Zhejiang',
            },
            {
              key: '4',
              label: 'Remark',
              children: 'empty',
            },
            {
              key: '5',
              label: 'Address',
              children:
                'No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China',
            },
          ]}
        />
      </Flex>
    </>
  );
};

export default ThemeTestPage;
