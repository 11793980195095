import { ACCOUNT_STATUS_CODE } from 'utilities/constants';

export const FORM_FIELD = Object.freeze({
  REASON_CODE: 'reasonCode',
  STATUS_CODE: 'statusCode',
  APPLY_TO_ALL_ACCOUNTS: 'applyToAllAccounts',
  CLOSURE_AUTHORIZED: 'closureAuthorized',
  CLOSURE_FRAUD_TYPE: 'closureFraudType',
  CLOSURE_FRAUD_TYPE_STATUS_TIMEFRAME: 'closureFraudTypeStatusTimeFrame',
  FRAUD_TIMEFRAME: 'fraudTimeframe',
  OVERWRITE_STATUS: 'overwriteStatus',
});

export const DEFAULT_STATUS_FORM_VALUES = {
  [FORM_FIELD.APPLY_TO_ALL_ACCOUNTS]: false,
  [FORM_FIELD.CLOSURE_AUTHORIZED]: false,
  [FORM_FIELD.CLOSURE_FRAUD_TYPE]: undefined,
  [FORM_FIELD.CLOSURE_FRAUD_TYPE_STATUS_TIMEFRAME]: [],
  [FORM_FIELD.STATUS_CODE]: undefined,
  [FORM_FIELD.REASON_CODE]: undefined,
  [FORM_FIELD.FRAUD_TIMEFRAME]: [],
  [FORM_FIELD.OVERWRITE_STATUS]: false,
};

/**
 * CARD WATCH
 */
export const CARD_WATCH_REASON = Object.freeze({
  TEMPORARILY_LOST_CARD: '06',
  SUSPICIOUS_TRANSACTION: '10',
  SUSPECTED_TEST_MERCHANT: '11',
  SUSPECTED_COUNTERFEIT: '12',
  SUSPECTED_INTERNET_FRAUD: '13',
  SUSPECTED_TOKEN_FRAUD: '14',
  FRAUD_ALERT_BYPASS: '20',
});

/**
 * TIERED WATCH
 */
export const TIERED_WATCH_REASON = Object.freeze({
  TSYS_IDENTIFIED_ATO: 'L4',
  TSYS_IDENTIFIED_FRAUD_APP: 'L5',
  SUSPECTED_PAYMENT_FRAUD: 'L7',
  SUSPECTED_ATO: 'L8',
  SUSPECTED_FRAUD_APP: 'L9',
  ADS_II_BYPASS: '15',
  SECONDARY_BYPASS: '19',
});

/**
 * RETURN MAIL
 */
export const RETURNED_MAIL_REASON = Object.freeze({
  CORRESPONDENCE_RETURNED: 'CR',
  PLASTICS_RETURNED: 'PR',
  STATEMENT_RETURNED: 'SR',
});

/**
 * CLOSED
 */
export const CLOSED_REASON = Object.freeze({
  ANNUAL_FEE: 'AF',
  BANK_POLICY: 'BP',
  CHANGE_IN_TERMS: 'CT',
  DID_NOT_USE: 'DU',
  INTEREST_RATE: 'IR',
  VOLUNTARY_1: 'V1',
  PAYMENT_FRAUD: 'V7',
  ACCOUNT_TAKEOVER: 'V8',
  APPLICATION_FRAUD: 'V9',
});

/**
 * FIXED PAY
 */
export const FIXED_PAY_REASON = Object.freeze({
  CREDIT_COUNSELING: 'CC',
  PAY_OFF: 'PO',
  SYSTEM_SET: 'SY',
});

export const FRAUDLENT_CLOSED_REASONS = Object.freeze([
  CLOSED_REASON.BANK_POLICY,
  CLOSED_REASON.PAYMENT_FRAUD,
  CLOSED_REASON.ACCOUNT_TAKEOVER,
  CLOSED_REASON.APPLICATION_FRAUD,
]);

export const STATUS_OPTIONS = Object.freeze([
  {
    value: ACCOUNT_STATUS_CODE.CARD_WATCH,
    label: 'Card Watch',
    reasonCodes: [
      {
        value: CARD_WATCH_REASON.TEMPORARILY_LOST_CARD,
        label: 'CW-06: Temporarily Lost Card',
      },
      {
        value: CARD_WATCH_REASON.SUSPICIOUS_TRANSACTION,
        label: 'CW-10: Suspicious Transaction',
      },
      {
        value: CARD_WATCH_REASON.SUSPECTED_TEST_MERCHANT,
        label: 'CW-11: Suspected Test Merchant',
      },
      {
        value: CARD_WATCH_REASON.SUSPECTED_COUNTERFEIT,
        label: 'CW-12: Suspected Counterfeit',
      },
      {
        value: CARD_WATCH_REASON.SUSPECTED_INTERNET_FRAUD,
        label: 'CW-13: Suspected Internet Fraud',
      },
      {
        value: CARD_WATCH_REASON.SUSPECTED_TOKEN_FRAUD,
        label: 'CW-14: Suspected Token Fraud',
      },
      {
        value: CARD_WATCH_REASON.FRAUD_ALERT_BYPASS,
        label: 'CW-20: Fraud Alert Bypass',
      },
    ],
  },
  {
    value: ACCOUNT_STATUS_CODE.TIERED_WATCH,
    label: 'Tiered Watch',
    reasonCodes: [
      {
        value: TIERED_WATCH_REASON.TSYS_IDENTIFIED_ATO,
        label: 'TW-L4: TSYS Identified ATO',
      },
      {
        value: TIERED_WATCH_REASON.TSYS_IDENTIFIED_FRAUD_APP,
        label: 'TW-L5: TSYS Identified Fraud App',
      },
      {
        value: TIERED_WATCH_REASON.SUSPECTED_PAYMENT_FRAUD,
        label: 'TW-L7: Suspected Payment Fraud',
      },
      {
        value: TIERED_WATCH_REASON.SUSPECTED_ATO,
        label: 'TW-L8: Suspected ATO',
      },
      {
        value: TIERED_WATCH_REASON.SUSPECTED_FRAUD_APP,
        label: 'TW-L9: Suspected Fraud App',
      },
      {
        value: TIERED_WATCH_REASON.ADS_II_BYPASS,
        label: 'TW-15: ADS II Bypass',
      },
      {
        value: TIERED_WATCH_REASON.SECONDARY_BYPASS,
        label: 'TW-19: Secondary Bypass',
      },
    ],
  },
  {
    value: ACCOUNT_STATUS_CODE.RETURNED_MAIL,
    label: 'Returned Mail',
    reasonCodes: [
      {
        value: RETURNED_MAIL_REASON.CORRESPONDENCE_RETURNED,
        label: 'RM-CR: Correspondence Returned',
      },
      {
        value: RETURNED_MAIL_REASON.PLASTICS_RETURNED,
        label: 'RM-PR: Plastics Returned',
      },
      {
        value: RETURNED_MAIL_REASON.STATEMENT_RETURNED,
        label: 'RM-SR: Statement Returned',
      },
    ],
  },
  {
    value: ACCOUNT_STATUS_CODE.CLOSED,
    label: 'Closed',
    reasonCodes: [
      {
        value: CLOSED_REASON.ANNUAL_FEE,
        label: 'CL-AF: Closed Annual Fee',
      },
      {
        value: CLOSED_REASON.BANK_POLICY,
        label: 'CL-BP: Closed Bank Policy',
      },
      {
        value: CLOSED_REASON.CHANGE_IN_TERMS,
        label: 'CL-CT: Change in Terms',
      },
      {
        value: CLOSED_REASON.DID_NOT_USE,
        label: 'CL-DU: Closed Did Not Use',
      },
      {
        value: CLOSED_REASON.INTEREST_RATE,
        label: 'CL-IR: Closed Interest Rate',
      },
      {
        value: CLOSED_REASON.VOLUNTARY_1,
        label: 'CL-V1: Voluntary Closed 1',
      },
      {
        value: CLOSED_REASON.PAYMENT_FRAUD,
        label: 'CL-V7: Payment Fraud',
      },
      {
        value: CLOSED_REASON.ACCOUNT_TAKEOVER,
        label: 'CL-V8: Account Takeover',
      },
      {
        value: CLOSED_REASON.APPLICATION_FRAUD,
        label: 'CL-V9: Application Fraud',
      },
    ],
  },
]);

export const CDF10_REASONS = Object.freeze([
  TIERED_WATCH_REASON.TSYS_IDENTIFIED_ATO,
  TIERED_WATCH_REASON.TSYS_IDENTIFIED_FRAUD_APP,
  TIERED_WATCH_REASON.SUSPECTED_PAYMENT_FRAUD,
  TIERED_WATCH_REASON.SUSPECTED_ATO,
  TIERED_WATCH_REASON.SUSPECTED_FRAUD_APP,
]);

export const MULTI_ACCOUNT_REASONS = Object.freeze([
  TIERED_WATCH_REASON.TSYS_IDENTIFIED_ATO,
  TIERED_WATCH_REASON.TSYS_IDENTIFIED_FRAUD_APP,
  TIERED_WATCH_REASON.SUSPECTED_PAYMENT_FRAUD,
  TIERED_WATCH_REASON.SUSPECTED_ATO,
  TIERED_WATCH_REASON.SUSPECTED_FRAUD_APP,
]);
