import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Typography,
} from 'antd';
import styled from '@emotion/styled';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAgent from 'hooks/useAgent';
import useApplicationQuery from 'hooks/useApplicationQuery';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useModalControls from 'hooks/useModalControls';
import { EFA_CONTACT_PULL } from 'queries/efa';
import {
  BREAKPOINT,
  EFA_PHONE_VERIFICATION,
  SIZE,
  STATUS,
} from 'utilities/constants';

const FormFieldsGrid = styled.div(() => {
  return `
    display: grid;
    grid-gap: 0 var(--spacing);
    grid-template-columns: 1fr 1fr;

    @media (min-width: ${BREAKPOINT.LG_PX}) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `;
});

const EFAContactPullPage = () => {
  const agent = useAgent();
  const { applicationId } = useParams();
  const { customerInfo, customerInfoQuery } = useCustomerInfo();
  const confirmationModal = useModalControls();
  const [form] = Form.useForm();

  const [statements, setStatements] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [pullExecuted, setPullExecuted] = useState(false);

  const { application, applicationQuery, sponsorBank } =
    useApplicationQuery(applicationId);

  const requiresVerification = useMemo(() => {
    return application && application.statusReason === EFA_PHONE_VERIFICATION;
  }, [application]);

  const [executeEFACOntactPull, efaPullQuery] = useLazyQuery(EFA_CONTACT_PULL, {
    onCompleted: (data) => {
      setStatements(data.pullEFAContact.efaContact);
      setPullExecuted(true);
    },
    onError: (error) => {
      setShowError(true);
    },
  });

  useEffect(() => {
    if (!customerInfoQuery.loading && customerInfo) {
      const { firstName, lastName } = customerInfo;
      const { city, state, street1, postalCode } = customerInfo.address || {};

      form.setFieldsValue({
        firstName,
        lastName,
        street1,
        city,
        state,
        zip: postalCode,
      });
    }
  }, [customerInfo, customerInfoQuery]);

  const validateAndShowConfirmation = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    confirmationModal.show();
  };

  const onExecutePullClick = async () => {
    confirmationModal.hide();
    handleSubmit();
  };

  let handleSubmit = async () => {
    setShowError(false);

    let fieldValues = await form.validateFields();
    let variables = Object.assign(
      {
        email: customerInfo?.email || '',
        agentEmail: agent?.user?.email,
        applicationId: application?.applicationId || '',
        sponsorBank: sponsorBank ?? 'TAB',
      },
      fieldValues,
    );

    await executeEFACOntactPull({
      variables,
    });
  };

  return (
    <>
      <Modal
        {...confirmationModal}
        id="efaPullConfirmationModal"
        title="You are about to perform a soft credit pull!"
        okText="Execute Soft Pull"
        onOk={() => onExecutePullClick()}
        okButtonProps={{
          disabled: !confirmSubmit,
          'data-testid': 'submit-button',
        }}
      >
        <Flex
          data-testid="confirmation-modal"
          vertical={true}
          gap="var(--spacing-sm)"
        >
          <Typography.Text>
            You are about to execute a soft Transunion pull for this customer.
            Once you leave this page you will not be able to see this report
            again without re-pulling the data. Use this feature with caution.
            Are you sure you want to continue?
          </Typography.Text>
          <Checkbox
            data-testid="confirm-submission-check"
            onChange={(e) => setConfirmSubmit(e.target.checked)}
          >
            I confirm I understand the above conditions
          </Checkbox>
        </Flex>
      </Modal>
      <Card title="EFA Contact Pull">
        <Form form={form} initialValues={{}} layout="vertical">
          <QueryBoundary
            mode={QueryBoundary.MODE.MESSAGE}
            loadingMessage="Loading Application and Applicant Information"
            loadingOverride={
              customerInfoQuery.loading ||
              applicationQuery.loading ||
              !agent.loaded
            }
            query={applicationQuery}
          >
            {agent.isSuperUser ? (
              <>
                <FormFieldsGrid>
                  <Form.Item label="First Name" name="firstName">
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Last Name" name="lastName">
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item
                    label="Street"
                    name="street1"
                    style={{
                      gridColumn: '1/3',
                    }}
                  >
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    style={{
                      gridColumn: '1',
                    }}
                  >
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="State" name="state">
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Zip Code" name="zip">
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item
                    label="SSN"
                    name="fullSsn"
                    required
                    rules={[
                      {
                        message: 'SSN should be at least 9 digits long',
                        min: 9,
                        max: 9,
                        required: true,
                      },
                    ]}
                    style={{
                      gridColumn: '1',
                    }}
                  >
                    <Input data-testid="full-ssn-input" maxLength={9} />
                  </Form.Item>
                </FormFieldsGrid>

                {!requiresVerification ? (
                  <Alert
                    banner
                    type="warning"
                    style={{
                      maxWidth: 'fit-content',
                      marginBottom: 'var(--spacing)',
                    }}
                    message="This application is not in need of identify verification."
                  />
                ) : null}

                {showError ? (
                  <Alert
                    type="error"
                    style={{
                      maxWidth: 'fit-content',
                      marginBottom: 'var(--spacing)',
                    }}
                    message="Failed to retrieve EFA contact information. Please try again."
                  />
                ) : null}

                <Button
                  data-testid="execute-pull-button"
                  type="primary"
                  disabled={!requiresVerification || !!pullExecuted}
                  loading={efaPullQuery.loading}
                  onClick={validateAndShowConfirmation}
                >
                  Execute Pull
                </Button>
              </>
            ) : (
              <SectionMessage
                data-testid="no-efa-access-message"
                status={STATUS.ERROR}
                size={SIZE.SM}
                text="Sorry, you lack the permissions to execute an EFA Contact Pull"
                visible={true}
              ></SectionMessage>
            )}
          </QueryBoundary>
        </Form>

        {pullExecuted && (
          <>
            <Divider></Divider>
            <Flex vertical={true} gap="var(--spacing)">
              <Alert
                banner
                message="Don't Refresh the Page!"
                description="Once you leave this page you will not be able to see this report again without re-pulling the data. Use this feature with caution."
                type="warning"
              />

              {statements?.length ? (
                <List
                  data-testid="statement-list"
                  size="small"
                  bordered
                  dataSource={statements}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text code={true}>{item}</Typography.Text>
                    </List.Item>
                  )}
                />
              ) : (
                <SectionMessage
                  data-testid="no-statement-message"
                  status={STATUS.INFORMATION}
                  size={SIZE.SM}
                  text="No consumer statement found in the credit report"
                  visible={true}
                />
              )}
            </Flex>
          </>
        )}
      </Card>
    </>
  );
};

export default EFAContactPullPage;
