import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Alert, Typography } from 'antd';
import styled from '@emotion/styled';
import { loadVGSCollect } from '@vgs/collect-js';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import config from 'config';
import { VERIFY_CVV } from 'queries/cvvVerify';
import useTheme from 'hooks/useTheme';
import { SIZE, STATUS } from 'utilities/constants';

const { Text } = Typography;

const AlertItem = styled(Alert)`
  margin-top: var(--spacing);
`;

/**
 * Switch to using the react library
 * https://www.npmjs.com/package/@vgs/collect-js-react
 */

const CvvVerifyModal = ({ hide, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const { tokens } = useTheme();
  const [cvvResultData, setCvvResultData] = useState(null);
  const [showVerifyFailure, setShowVerifyFailure] = useState(false);
  const [collectForm, setCollectForm] = useState({});
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);

  const formName = 'card-cvv';
  const fieldValue = formValue[formName];

  const inputFieldCss = {
    border: `1px solid ${tokens.colorBorderSecondary}`,
    background: tokens.colorBgContainer,
    color: tokens.colorText,
    width: '100%',
    height: '32px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontSize: 'var(--font-size-sm)',
    '&.invalid.touched': {
      color: tokens.colorError,
    },
  };

  useEffect(() => {
    const initializeForm = async () => {
      const collect = await loadVGSCollect({
        vaultId: config.VGS_VAULT_ID,
        environment: config.VGS_ENV,
        version: '2.17.0',
      });

      const form = collect.init(setFormValue);
      form.useCname(config.VGS_CNAME);

      const field = form.field('#card-cvv', {
        type: 'card-security-code',
        name: formName,
        maxLength: 3,
        validations: ['required', 'validCardSecurityCode'],
        hideValue: 'true',
        css: inputFieldCss,
      });
      setCollectForm(form);
      field.on('update', () => setCvvResultData(null));
    };
    initializeForm();
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    setShowVerifyFailure(false);
    collectForm.submit(
      '/graphql',
      {
        withCredentials: true,
        data: (values) => {
          return {
            query: VERIFY_CVV.loc?.source.body,
            variables: {
              accountId: accountId,
              customerId: customerId,
              cvv: values[formName],
              verifyPreviousValue: false,
            },
          };
        },
      },
      (status, response) => {
        if (response?.errors && response?.errors.length) {
          setShowVerifyFailure(true);
        } else {
          setCvvResultData(response?.data.verifyCvv || null);
        }

        setLoading(false);
      },
    );
  };

  const customizeValidationError = (errorMessage) => {
    if (errorMessage === 'is not a valid security code') {
      return 'must be 3 numbers';
    }
    return errorMessage;
  };

  const closeModal = () => {
    collectForm.reset();
    setCvvResultData(null);
    hide();
  };

  const CVVValidated = () => {
    setTimeout(closeModal, 2500);

    return (
      <SectionMessage
        status={STATUS.SUCCESS}
        size={SIZE.SM}
        text="CVV has been verified!"
        cover={true}
        visible={cvvResultData?.isCvvVerified}
      />
    );
  };

  const CVVBlocked = () => (
    <AlertItem message="CVV verification is blocked!" type="error" />
  );

  const CVVUnverified = () => (
    <AlertItem
      message={`Unable to verify CVV. You may give the customer up to three tries to provide the correct CVV number.`}
      type="warning"
      showIcon
    />
  );

  return (
    <>
      <Modal
        {...modalProps}
        data-testid="verify-cvv-modal"
        title="Verify CVV"
        destroyOnClose={true}
        forceRender={true}
        okText="Submit"
        okButtonProps={{ disabled: !fieldValue?.isValid }}
        onOk={handleSubmit}
        onCancel={closeModal}
        width={450}
        confirmLoading={loading}
      >
        <label>CVV Number</label>
        <div
          style={{
            margin: 'var(--spacing-xs) 0 var(--spacing)',
          }}
        >
          <div id={formName} style={{ height: '32px', overflow: 'hidden' }} />
          {fieldValue?.isTouched &&
            !fieldValue?.isValid &&
            fieldValue?.errorMessages.length > 0 && (
              <Text type="danger">
                CVV {customizeValidationError(fieldValue.errorMessages[0])}.
              </Text>
            )}
        </div>
        {cvvResultData != null &&
          (cvvResultData?.isCvvVerified ? (
            <CVVValidated />
          ) : cvvResultData?.isLockedOut ? (
            <CVVBlocked />
          ) : (
            <CVVUnverified numAttempts={cvvResultData?.remainingSubmissions} />
          ))}
        {showVerifyFailure ? (
          <AlertItem
            message="We've experienced an error while trying to verify the customer's CVV. Please try again."
            type="error"
          />
        ) : null}
      </Modal>
    </>
  );
};

export default CvvVerifyModal;
