import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Alert, Typography } from 'antd';

import FundingAccountTable from 'components/FundingAccountTable/FundingAccountTable';
import useAnalytics from 'hooks/useAnalytics';
import { CUSTOMER_FUNDING_ACCOUNTS } from 'queries/fundingAccounts';
import { ANALYTICS_EVENTS } from 'utilities/constants';

import StepContainer from '../StepContainer';
import {
  setNextStep,
  resetCreditBalanceRefundState,
  CREDIT_BALANCE_REFUND_STEPS,
} from './creditBalanceRefundState';

const FundingAccountManagementStep = ({ containerStyles }) => {
  const { accountId, customerId } = useParams();
  const { trackEvent } = useAnalytics();
  const [fundingAccounts, setFundingAccounts] = useState([]);

  const accountsQuery = useQuery(CUSTOMER_FUNDING_ACCOUNTS, {
    variables: { customerId },
    onCompleted: (data) => {
      setFundingAccounts(data?.fundingAccounts || []);
    },
  });

  const handleContinue = () => {
    trackEvent(ANALYTICS_EVENTS.CBR_BAD_CHECKS_INQUIRY, {
      customerId,
      accountId,
    });

    setNextStep(CREDIT_BALANCE_REFUND_STEPS.ADDRESS_REVIEW_STEP);
  };

  return (
    <StepContainer
      title="Remove Non-valid Funding Accounts"
      onContinue={handleContinue}
      onExit={resetCreditBalanceRefundState}
      style={containerStyles}
    >
      <Alert
        banner
        type="warning"
        message="Note: You cannot add a new funding account for credit balance refunds"
        style={{ marginBottom: 'var(--spacing)' }}
      ></Alert>
      <Typography.Paragraph style={{ marginBottom: 'var(--spacing-lg)' }}>
        If approved, this customer may recieve a credit balance refund via ACH.
        <br />
        Please remove any non-valid funding accounts as confirmed by the
        customer.
      </Typography.Paragraph>
      <div style={{ marginBottom: 'var(--spacing-lg)' }}>
        <FundingAccountTable
          fundingAccounts={fundingAccounts}
          loading={accountsQuery.loading}
        />
      </div>
    </StepContainer>
  );
};

export default FundingAccountManagementStep;
