import { Alert, Breadcrumb, Button, Divider, Flex, Typography } from 'antd';

import useModalControls from 'hooks/useModalControls';
import { SETTLEMENT_OFFER_STATES } from 'utilities/constants';

import ActiveOfferCard from './ActiveOfferCard';
import CancelPlanModal from './CancelPlanModal';
import ManagedByThirdPartyAlert from './ManagedByThirdPartyAlert';
import ThirdPartyEmailModal from './ThirdPartyEmailModal';
import ThirdPartyInfoPromptAlert from './ThirdPartyInfoPromptAlert';
import ScheduledPaymentsTable from './ScheduledPaymentsTable';

import { RecoveriesGrid } from './RecoveriesSection';
import { useRecoveriesState } from './recoveriesState';

const { Title } = Typography;

const ActiveOffer = ({ thirdParty }) => {
  const cancelPlanModal = useModalControls();
  const thirdPartyEmailModal = useModalControls();
  const { offer } = useRecoveriesState();

  const isPendingCloseOut =
    offer?.state === SETTLEMENT_OFFER_STATES.PENDING_CLOSE_OUT;

  const title = isPendingCloseOut ? 'Pending Close Out' : 'Active Offer';

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 'var(--spacing-lg)' }}
        items={[{ title: 'Recoveries Home' }, { title }]}
      />

      {!thirdParty.email && !isPendingCloseOut && (
        <ThirdPartyInfoPromptAlert
          showThirdPartyEmailModal={thirdPartyEmailModal.show}
        />
      )}

      <RecoveriesGrid>
        <Flex>
          <Flex vertical={true}>
            <Title level={5}>{title}</Title>

            {thirdParty.error && (
              <Alert
                banner
                type="error"
                message="Failed to retrieve 3rd party info."
                style={{ marginBottom: 'var(--spacing-sm)', width: 270 }}
              />
            )}
            {thirdParty.email && (
              <ManagedByThirdPartyAlert
                showThirdPartyEmailModal={thirdPartyEmailModal.show}
                width={270}
              />
            )}
            <ThirdPartyEmailModal
              {...thirdPartyEmailModal}
              thirdParty={thirdParty}
            />

            <ActiveOfferCard thirdParty={thirdParty} />

            {!isPendingCloseOut && (
              <>
                <Button
                  danger
                  type="text"
                  style={{ marginTop: 'var(--spacing-sm)', float: 'right' }}
                  onClick={cancelPlanModal.show}
                >
                  Cancel Plan
                </Button>
                <CancelPlanModal {...cancelPlanModal} />
              </>
            )}
          </Flex>
          <Divider
            type="vertical"
            style={{ height: '100%', margin: '0 var(--spacing)' }}
          />
        </Flex>
        <div>
          <ScheduledPaymentsTable thirdParty={thirdParty} />
        </div>
      </RecoveriesGrid>
    </>
  );
};

export default ActiveOffer;
