import { useEffect, useState, useMemo } from 'react';
import { useLocalStorage, useToggle } from 'react-use';
import { useLocation } from 'react-router-dom';
import { useReactiveVar, useMutation, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';
import {
  Alert,
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import {
  ArrowRightOutlined,
  CopyOutlined,
  LikeOutlined,
} from '@ant-design/icons';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import DatePicker from 'components/DatePicker/DatePicker';

import { noteVar } from 'apollo/reactiveVars';

import useAccountQuery from 'hooks/useAccountQuery';
import useAnalytics from 'hooks/useAnalytics';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useAddNote from './useAddNote';

import { CREATE_ZENDESK_CASE } from 'mutations/zendeskCaseCreation';
import { CUSTOMER_WITH_FULL_ACCOUNTS } from 'queries/customer';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { GET_CASE_TYPE_OPTIONS } from 'queries/caseManagement';
import { ANALYTICS_EVENTS, STATUS, SIZE } from 'utilities/constants';
import { getCardDisplayName } from 'utilities/helpers';

const { TextArea } = Input;
const { Item } = Form;
const { Option } = Select;

const NoteForm = () => {
  const location = useLocation();

  const isAccountsPage = location.pathname.includes('accounts');

  const { customerInfo, applicationId, customerId, accountId } =
    useCustomerInfo();

  const { account, accounts } = useAccountQuery(customerId, accountId);

  const [addNote, addNoteMutation] = useAddNote();

  const note = useReactiveVar(noteVar);

  const { trackEvent } = useAnalytics();

  const [createCaseChecked, setCreateCaseChecked] = useToggle(false);

  const [customerNoteContext, setCustomerNoteContext] = useLocalStorage(
    'customer-note-context',
  );

  const [caseCreationFormContext, setCaseCreationFormContext] = useLocalStorage(
    'case-creation-context',
  );

  const [caseTypeOptions, setCaseTypeOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [zendeskUrl, setZendeskUrl] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [caseTypeSelected, setCaseTypeSelected] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [statementCloseDate, setStatementCloseDate] = useState('');
  const [zendeskLinkCopied, setZendeskLinkCopied] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [noteSaved, setNoteSaved] = useState(false);
  const [caseSaved, setCaseSaved] = useState(false);

  const [form] = Form.useForm();

  const currentDueDate = accounts?.find(
    (account) =>
      account.id === (accountId || selectedAccountId || accounts[0].id),
  )?.paymentInfo?.printDueDate;

  useEffect(() => {
    if (isAccountsPage) {
      form.setFieldsValue({
        selectedAccount: getCardDisplayName(account),
        requestedDueDate: null,
      });
    } else {
      form.setFieldsValue({ selectedAccount: null, requestedDueDate: null });
    }
  }, [account?.cardDetails?.name, isAccountsPage, accountId]);

  const isDate26thOr29thTo31st = (selectedDate) => {
    const date = selectedDate.date();
    return date === 26 || (date >= 29 && date <= 31);
  };

  const is4DaysLeadingToDueDate = (selectedDate) => {
    const date = selectedDate.date();
    const fourDaysBeforeDueDate = dayjs(currentDueDate).subtract(4, 'day');
    const firstBlockedDay = fourDaysBeforeDueDate.date();
    const currentDueDateDay = dayjs(currentDueDate).date();

    if (currentDueDateDay >= 4) {
      return date >= firstBlockedDay && date <= currentDueDateDay;
    } else {
      return date >= firstBlockedDay || date <= currentDueDateDay;
    }
  };

  const getCycleDateRequiredCases = () => {
    return Object.fromEntries(
      caseTypeOptions
        .filter((option) => option.cycleDateRequired)
        .map((option) => [option.value, true]),
    );
  };

  const getRequestedDueDateRequiredCases = () => {
    return Object.fromEntries(
      caseTypeOptions
        .filter((option) => option.requestedDueDateRequired)
        .map((option) => [option.value, true]),
    );
  };

  const cycleDateRequiredCases = useMemo(() => {
    return getCycleDateRequiredCases();
  }, [caseTypeOptions]);

  const requestedDueDateRequiredCases = useMemo(() => {
    return getRequestedDueDateRequiredCases();
  }, [caseTypeOptions]);

  useEffect(() => {
    if (customerId) {
      getCustomerStatementDates({
        variables: {
          customerId,
        },
      });
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId && customerNoteContext?.customerId === customerId) {
      const noteText = customerNoteContext?.note || '';
      form.setFieldsValue({ note: noteText });
    } else {
      form.setFieldsValue({ note });
    }
  }, [customerId]);

  useEffect(() => {
    if (
      caseCreationFormContext?.createCase === true &&
      caseCreationFormContext?.userId === (customerId || applicationId)
    ) {
      const {
        createCase,
        selectedCaseType,
        selectedDueDate,
        selectedAccountId,
        caseTypes,
        teams,
      } = caseCreationFormContext;

      setCreateCaseChecked(createCase);
      setCaseTypeOptions(caseTypes);
      setTeamOptions(teams);
      setCaseTypeSelected(selectedCaseType);
      setSelectedAccountId(selectedAccountId);

      const currentAccount = accounts?.find((account) => {
        return account.id === selectedAccountId || accountId;
      });

      form.setFieldsValue({
        caseTypes,
        nextStatementCloseDate: statementCloseDate,
        caseType: selectedCaseType,
        team: teams,
        ...(!isAccountsPage && {
          requestedDueDate: selectedDueDate ? dayjs(selectedDueDate) : null,
          selectedAccount: currentAccount
            ? getCardDisplayName(currentAccount)
            : null,
        }),
      });
    }
  }, [account, statementCloseDate, caseCreationFormContext?.createCase]);

  const handleNoteChange = (changedNote) => {
    noteVar(changedNote);
    setCustomerNoteContext({
      ...customerNoteContext,
      customerId: customerId || applicationId,
      note: changedNote,
    });
  };

  const handleCreateCaseChange = (createCaseCheck) => {
    const checked = createCaseCheck.target.checked;
    setCreateCaseChecked(checked);

    if (!checked) {
      return setCaseCreationFormContext(null);
    }

    try {
      getCaseTypeOptions();
    } catch (e) {
      setFailureMessage('Failed to load form. Refresh and try again');
    }
  };

  const [getCustomerStatementDates] = useLazyQuery(
    CUSTOMER_WITH_FULL_ACCOUNTS,
    {
      onCompleted: ({ customer }) => {
        const statementCloseDates = customer.creditAccounts?.map((account) => {
          return account.paymentInfo.nextStatementCloseDate;
        });

        if (statementCloseDates.length > 1) {
          statementCloseDates.sort((a, b) => {
            const date1 = new Date(a);
            const date2 = new Date(b);
            return date1 - date2;
          });
        }

        setStatementCloseDate(statementCloseDates[0]);
        setCaseCreationFormContext({
          ...caseCreationFormContext,
          nextStatementCloseDate: statementCloseDates[0],
        });
      },
    },
  );

  const [getCaseTypeOptions, { loading: loadingTeamsAndCaseTypes }] =
    useLazyQuery(GET_CASE_TYPE_OPTIONS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ caseWorkOptions }) => {
        setCaseTypeOptions(caseWorkOptions.caseTypes);

        form.setFieldValue('team', caseWorkOptions.teams);

        setCaseCreationFormContext({
          ...caseCreationFormContext,
          caseTypes: caseWorkOptions.caseTypes,
          teams: caseWorkOptions.teams,
          userId: customerId || applicationId,
          createCase: true,
        });
      },
    });

  const [createZendeskCase, { loading: sendingZendeskCase }] = useMutation(
    CREATE_ZENDESK_CASE,
    {
      refetchQueries: ['GetAgentCustomerActivity'],
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleCaseTypeSelect = (caseType) => {
    setCaseTypeSelected(caseType);
    setCaseCreationFormContext({
      ...caseCreationFormContext,
      selectedCaseType: caseType,
    });
  };

  const saveNote = async (formValues) => {
    trackEvent(ANALYTICS_EVENTS.NOTE_BOX_SAVE_CLICKED);

    const customerEmail = customerInfo?.email;
    const customerName = customerInfo?.fullName;

    if (!noteSaved) {
      try {
        await addNote(formValues.note, false);
        setNoteSaved(true);

        if (!createCaseChecked) {
          form.resetFields(['note']);
          noteVar('');
          setCustomerNoteContext(null);
        }
      } catch (error) {
        setFailureMessage('Failed to save note. Please try again.');
        return;
      }
    }

    if (createCaseChecked && !caseSaved) {
      try {
        const response = await createZendeskCase({
          variables: {
            note: formValues.note,
            email: customerEmail,
            name: customerName,
            cycleDate: formValues.nextStatementCloseDate,
            caseType: formValues.caseType,
            requestedDueDate: formatDateToSubmit(formValues.requestedDueDate),
          },
        });
        setZendeskUrl(response?.data?.caseWorkCreateCase.url);
        setCaseSaved(true);
        setShowSuccessMessage(true);

        setCustomerNoteContext(null);
        setCaseCreationFormContext(null);
        noteVar('');
        form.resetFields();
        setFailureMessage('');
        setCaseTypeSelected('');
        setCreateCaseChecked(false);
        setSelectedAccountId('');
      } catch (error) {
        return setFailureMessage(
          <div>
            Failed to submit Zendesk ticket. Please try again. <br /> If this
            error persists, go to Zendesk directly to create your ticket.{' '}
          </div>,
        );
      }
    }

    setCaseSaved(false);
    setNoteSaved(false);
  };

  let renderCopyTooltipText = () => {
    return zendeskLinkCopied ? (
      <span>
        Link Copied <LikeOutlined />
      </span>
    ) : (
      'Copy Link'
    );
  };

  const zendeskUrlButton = {
    text: (
      <>
        <ArrowRightOutlined /> View Ticket in Zendesk
      </>
    ),
    ghost: false,
    type: 'link',
    target: 'zendesk_url',
    href: zendeskUrl,
  };

  const copyZendeskLinkButton = {
    text: (
      <>
        <Tooltip title={renderCopyTooltipText()}>
          <CopyOutlined /> Copy Ticket Link{' '}
        </Tooltip>
      </>
    ),
    ghost: false,
    type: 'link',
    onClick: () => {
      setZendeskLinkCopied(true);
      copy(zendeskUrl);
    },
  };

  return (
    <Form
      form={form}
      layout={'vertical'}
      name="create-case-form"
      onFinish={saveNote}
      size="small"
      style={{ paddingBottom: showSuccessMessage ? '120px' : '0px' }}
    >
      <SectionMessage
        buttons={[zendeskUrlButton, copyZendeskLinkButton]}
        verticalButtons={true}
        data-testid="success-message"
        status={STATUS.SUCCESS}
        size={SIZE.MD}
        text="Case successfully submitted!"
        cover={true}
        visible={showSuccessMessage}
        canClose={true}
        handleClose={() => setShowSuccessMessage(false)}
      />

      <Item
        name="note"
        label="Call Note"
        rules={[
          {
            required: true,
            message: 'Please include call details.',
          },
        ]}
        style={{ marginBottom: 'var(--spacing-xs)' }}
      >
        <TextArea
          data-testid="note-text-area"
          autoSize={{ minRows: 4, maxRows: 20 }}
          onChange={(e) => {
            handleNoteChange(e.target.value);
          }}
          placeholder="New Note"
          disabled={noteSaved}
        />
      </Item>

      <Checkbox
        name="createCase"
        data-testid="create-case-checkbox"
        style={{ marginBottom: 'var(--spacing-xs)' }}
        checked={createCaseChecked}
        onChange={(e) => {
          handleCreateCaseChange(e);
        }}
      >
        Create Zendesk Case
      </Checkbox>

      {createCaseChecked && (
        <Item>
          <Item
            required
            name="team"
            label="Case Team"
            rules={[
              {
                required: true,
                message: 'Please select the team.',
              },
            ]}
            style={{ marginBottom: 'var(--spacing-xs)' }}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              data-testid="team-select"
              placeholder="Select team"
              disabled={loadingTeamsAndCaseTypes || sendingZendeskCase}
            >
              {teamOptions.map((option) => {
                return (
                  <Option
                    data-testid={`team-${option.value}`}
                    key={`team-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Item>

          <Item
            required
            name="caseType"
            label="Case Type"
            rules={[
              {
                required: true,
                message: 'Please select the case type.',
              },
            ]}
            style={{ marginBottom: 'var(--spacing-xs)' }}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              data-testid="case-type-select"
              placeholder="Select case type"
              disabled={loadingTeamsAndCaseTypes || sendingZendeskCase}
              onSelect={handleCaseTypeSelect}
            >
              {caseTypeOptions.map((option) => {
                return (
                  <Option
                    data-testid={`case-${option.value}`}
                    key={`caseType-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Item>

          {requestedDueDateRequiredCases[caseTypeSelected] && (
            <>
              {accounts.length > 1 && (
                <Item
                  name="selectedAccount"
                  label="Account"
                  style={{
                    marginTop: 'var(--spacing-xs)',
                    marginBottom: '0px',
                  }}
                  rules={[
                    {
                      required: true,
                      message:
                        'Please select the account to apply the due date change to.',
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    data-testid="account-select"
                    placeholder="Select account"
                    disabled={isAccountsPage}
                    onSelect={(value) => {
                      setSelectedAccountId(value);
                      form.setFieldValue('requestedDueDate', null);
                      setCaseCreationFormContext({
                        ...caseCreationFormContext,
                        selectedAccountId: value,
                      });
                    }}
                  >
                    {accounts.map((account) => {
                      return (
                        <Option
                          data-testid={`account-${account.id}`}
                          key={`account-${account.id}`}
                          value={account.id}
                        >
                          {getCardDisplayName(account)}
                        </Option>
                      );
                    })}
                  </Select>
                </Item>
              )}

              <Item
                name="requestedDueDate"
                label="Newly Requested Due Date"
                style={{ marginTop: 'var(--spacing-xs)', marginBottom: '0px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please select the requested due date.',
                  },
                ]}
              >
                <DatePicker
                  data-testid="requestedDueDatePicker"
                  disabled={sendingZendeskCase}
                  disabledDate={(d) => {
                    return (
                      !d ||
                      isDate26thOr29thTo31st(d) ||
                      d.isSame(dayjs(currentDueDate), 'day') ||
                      is4DaysLeadingToDueDate(d) ||
                      d.isBefore(dayjs())
                    );
                  }}
                  style={{ marginBottom: 'var(--spacing-sm)' }}
                  onChange={(date) => {
                    setCaseCreationFormContext({
                      ...caseCreationFormContext,
                      selectedDueDate: formatDateToSubmit(date),
                    });
                  }}
                />
              </Item>
            </>
          )}

          {cycleDateRequiredCases[caseTypeSelected] && (
            <Item
              name="nextStatementCloseDate"
              label="Next Statement Close Date"
              initialValue={statementCloseDate}
              style={{ marginBottom: '0px' }}
            >
              <Input disabled={true} data-testid="cycle-date-input" />
            </Item>
          )}
        </Item>
      )}

      {failureMessage ? (
        <Alert
          data-testid="failure-message"
          type="error"
          message={failureMessage}
          style={{ marginBottom: 'var(--spacing-xs)' }}
          Alert
        />
      ) : null}

      <Flex justify="flex-end" height="auto">
        <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
          <Button
            data-testid="submit-button"
            type="primary"
            loading={addNoteMutation.loading || sendingZendeskCase}
            htmlType="submit"
            style={{ marginBottom: '0px' }}
          >
            Save
          </Button>
        </Item>
      </Flex>
    </Form>
  );
};

export default NoteForm;
