import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from 'antd';
import { useMutation } from '@apollo/client';

import useAccountQuery from 'hooks/useAccountQuery';
import useAnalytics from 'hooks/useAnalytics';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { ANALYTICS_EVENTS, ACCOUNT_STATUS_CODE } from 'utilities/constants';
import { formatDollarsFromCents } from 'utilities/helpers';
import { CREATE_ZENDESK_CASE } from 'mutations/zendeskCaseCreation';
import { REQUEST_CREDIT_BALANCE_REFUND } from 'mutations/creditBalanceRefund';

import StepContainer from '../StepContainer';
import {
  resetCreditBalanceRefundState,
  setSubmissionInitiated,
  setSubmissionCompleted,
} from './creditBalanceRefundState';

const { Paragraph, Text } = Typography;

const SubmissionStep = ({ containerStyles }) => {
  const { accountId, customerId } = useParams();
  const { account } = useAccountQuery(customerId, accountId);
  const { trackEvent } = useAnalytics();
  const { customerInfo } = useCustomerInfo();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const {
    refundAmount,
    hasFraud,
    largeRefund,
    fraudStatuses,
    requiresFraudReview,
  } = useMemo(() => {
    let refundAmount = 0;
    let fraudStatuses = [];

    if (account) {
      refundAmount = Math.abs(account.balanceInfo?.currentBalance || 0);

      const fraudCodes = [
        ACCOUNT_STATUS_CODE.TRANSFER,
        ACCOUNT_STATUS_CODE.SECURITY_FRAUD,
        ACCOUNT_STATUS_CODE.CONFIRMED_FRAUD,
      ];
      const cardWatchReasons = ['07', '08', '09'];
      const tieredWatchResons = ['L7', 'L8', 'L9'];
      const statuses = account.statuses?.raw || [];

      fraudStatuses = statuses.reduce((blockers, status) => {
        const { statusCode, reasonCode } = status;
        if (fraudCodes.includes(statusCode)) {
          blockers.push(statusCode);
        }
        if (
          statusCode === ACCOUNT_STATUS_CODE.CARD_WATCH &&
          cardWatchReasons.includes(reasonCode)
        ) {
          blockers.push(statusCode);
        }
        if (
          statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH &&
          tieredWatchResons.includes(reasonCode)
        ) {
          blockers.push(statusCode);
        }
        return blockers;
      }, []);
    }

    const hasFraud = !!fraudStatuses.length;
    const largeRefund = refundAmount >= 50000; // $500 or greater

    return {
      refundAmount,
      fraudStatuses,
      hasFraud,
      largeRefund,
      requiresFraudReview: hasFraud || largeRefund,
    };
  }, [account]);

  const [requestCreditBalanceRefund, { loading: processingRefund }] =
    useMutation(REQUEST_CREDIT_BALANCE_REFUND);

  const [createZendeskCase, { loading: processingZendesk }] = useMutation(
    CREATE_ZENDESK_CASE,
    {
      refetchQueries: ['GetAgentCustomerActivity'],
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleSubmit = async () => {
    setErrorMessage(undefined);
    setSubmissionInitiated(true);

    try {
      if (requiresFraudReview) {
        await createZendeskCase({
          variables: {
            note: 'Credit balance refund request.',
            email: customerInfo?.email,
            name: customerInfo?.fullName,
            caseType: 'SAH_CBR_CHECK',
          },
        });
      } else {
        await requestCreditBalanceRefund({
          variables: { accountId, amount: refundAmount },
        });
      }
    } catch (error) {
      setErrorMessage(
        requiresFraudReview
          ? 'Failed to create zendesk case. Please try again.'
          : 'Failed to submit credit balance refund request. Please try again.',
      );
      trackEvent(ANALYTICS_EVENTS.CBR_SUBMISSION_FAILURE, {
        customerId,
        accountId,
      });
      return;
    }

    setSubmissionCompleted(true);
    trackEvent(ANALYTICS_EVENTS.CBR_SUBMISSION_SUCCESS, {
      customerId,
      accountId,
    });
  };

  const processingRequest = processingRefund || processingZendesk;

  return (
    <StepContainer
      title="Submit Credit Balance Refund Request"
      onContinue={handleSubmit}
      onExit={resetCreditBalanceRefundState}
      buttonText={requiresFraudReview ? 'Send to Fraud' : 'Request Refund'}
      buttonProps={{
        loading: processingRequest,
        disabled: processingRequest,
      }}
      errorMessage={errorMessage}
      style={containerStyles}
    >
      {requiresFraudReview ? (
        <>
          <Alert
            banner
            type="warning"
            style={{ marginBottom: 'var(--spacing)' }}
            message={
              <Text>
                This request will require Fraud review and approval after
                submission.
                {hasFraud ? (
                  <>
                    <br />{' '}
                    {`Active Fraud Statuses: ${fraudStatuses.join(', ')}`}
                  </>
                ) : null}
                {largeRefund ? (
                  <>
                    <br /> Refund is over $500.
                  </>
                ) : null}
              </Text>
            }
          />
          <Paragraph data-testid="fraud-message" style={{ marginBottom: 0 }}>
            Once you click "Send to Fraud", the credit balance refund request of{' '}
            <strong>{formatDollarsFromCents(refundAmount)}</strong> will be sent
            to the Fraud team for escalation. Please refer to Credit Balance
            Refund (CBR) wiki for timeframe.
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph data-testid="no-fraud-message" style={{ marginBottom: 0 }}>
            Once you click "Request Refund", the credit balance refund request
            of <strong>{formatDollarsFromCents(refundAmount)}</strong> will be
            sent to TSYS and processed. Please refer to Credit Balance Refund
            (CBR) wiki for timeframe.
          </Paragraph>
        </>
      )}
    </StepContainer>
  );
};

export default SubmissionStep;
