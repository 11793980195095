import { COLORS_LIGHT_THEME } from 'utilities/styles';

export const LIGHT_THEME = {
  components: {
    Alert: {
      colorText: COLORS_LIGHT_THEME.neutral[11],
    },
    Layout: {
      bodyBg: COLORS_LIGHT_THEME.neutral[0],
      headerBg: COLORS_LIGHT_THEME.neutral[0],
      siderBg: COLORS_LIGHT_THEME.neutral[0],
    },
    Message: {
      contentBg: COLORS_LIGHT_THEME.neutral[2],
    },
    Segmented: {
      trackBg: COLORS_LIGHT_THEME.neutral[3],
    },
  },
  token: {
    // colorWhite: '#fff', Ant says dont touch
    // colorTextBase: '#000', Ant says dont touch

    colorBgBase: COLORS_LIGHT_THEME.neutral[0],
    colorBgContainer: COLORS_LIGHT_THEME.neutral[0],
    colorBgElevated: COLORS_LIGHT_THEME.neutral[0],
    colorBgLayout: COLORS_LIGHT_THEME.neutral[0],
    colorBgMask: COLORS_LIGHT_THEME.neutralAlpha[4],
    colorBgSolid: COLORS_LIGHT_THEME.neutral[7],
    colorBgHover: COLORS_LIGHT_THEME.neutral[6],
    colorBgSolidActive: COLORS_LIGHT_THEME.neutral[8],
    colorBgSpotlight: COLORS_LIGHT_THEME.neutral[11],
    // colorBgBlur: 'transparent',
    // colorBgTextActive: matches colorFill
    // colorBgTextHover: matches colorFillSecondary
    // colorBgContainerDisabled: matches colorFillTertiary,

    colorBorder: COLORS_LIGHT_THEME.neutral[4],
    colorBorderSecondary: COLORS_LIGHT_THEME.neutral[3],
    //colorBorderBg: matches colorTextLightSolid,

    colorFill: COLORS_LIGHT_THEME.neutral[4],
    colorFillSecondary: COLORS_LIGHT_THEME.neutral[3],
    colorFillTertiary: COLORS_LIGHT_THEME.neutral[2],
    colorFillQuaternary: COLORS_LIGHT_THEME.neutral[1],
    // colorFillAlter: matches colorFillQuaternary
    // colorFillContent: matches colorFillSecondary
    // colorFillContentHover: matches colorFill

    // colorIcon: matches colorTextTertiary,
    // colorIconHover: matches colorText,

    colorLink: COLORS_LIGHT_THEME.blue[4],
    colorLinkActive: COLORS_LIGHT_THEME.blue[3],
    colorLinkHover: COLORS_LIGHT_THEME.blue[5],

    colorText: COLORS_LIGHT_THEME.neutral[10],
    colorTextSecondary: COLORS_LIGHT_THEME.neutral[7],
    colorTextTertiary: COLORS_LIGHT_THEME.neutral[6],
    colorTextQuaternary: COLORS_LIGHT_THEME.neutral[5],
    colorTextHeading: COLORS_LIGHT_THEME.neutral[10],
    // colorTextLabel: COLORS_LIGHT_THEME.red[4], // Not sure why this isnt doing anything
    colorTextLightSolid: COLORS_LIGHT_THEME.neutral[0],
    colorTextPlaceholder: COLORS_LIGHT_THEME.neutral[5],
    // colorTextDescription: matches colorTextSecondary
    // colorTextDisabled: matches colorTextTertiary

    colorSplit: COLORS_LIGHT_THEME.neutral[3], // dividers/hr

    /**
     * Primary
     */
    colorPrimary: COLORS_LIGHT_THEME.blue[4],
    colorPrimaryActive: COLORS_LIGHT_THEME.blue[3],
    colorPrimaryHover: COLORS_LIGHT_THEME.blue[5],
    colorPrimaryBg: COLORS_LIGHT_THEME.blue[10],
    colorPrimaryBgHover: COLORS_LIGHT_THEME.blue[9],
    colorPrimaryBorder: COLORS_LIGHT_THEME.blue[8], // slider
    colorPrimaryBorderHover: COLORS_LIGHT_THEME.blue[7],
    colorPrimaryText: COLORS_LIGHT_THEME.blue[4],
    colorPrimaryTextActive: COLORS_LIGHT_THEME.blue[3],
    colorPrimaryTextHover: COLORS_LIGHT_THEME.blue[5],

    /**
     * Error / Danger
     */
    colorError: COLORS_LIGHT_THEME.red[4],
    colorErrorActive: COLORS_LIGHT_THEME.red[3],
    colorErrorHover: COLORS_LIGHT_THEME.red[5],
    colorErrorBg: COLORS_LIGHT_THEME.red[10],
    colorErrorBgActive: COLORS_LIGHT_THEME.red[8],
    colorErrorBgHover: COLORS_LIGHT_THEME.red[9],
    colorErrorBgFilledHover: COLORS_LIGHT_THEME.red[9],
    colorErrorBorder: COLORS_LIGHT_THEME.red[4],
    colorErrorBorderHover: COLORS_LIGHT_THEME.red[3],
    colorErrorText: COLORS_LIGHT_THEME.red[3],
    colorErrorTextActive: COLORS_LIGHT_THEME.red[2],
    colorErrorTextHover: COLORS_LIGHT_THEME.red[4],

    /**
     * Info
     */
    colorInfo: COLORS_LIGHT_THEME.blue[4],
    colorInfoActive: COLORS_LIGHT_THEME.blue[3],
    colorInfoHover: COLORS_LIGHT_THEME.blue[5],
    colorInfoBg: COLORS_LIGHT_THEME.blue[10],
    colorInfoBgActive: COLORS_LIGHT_THEME.blue[8],
    colorInfoBgHover: COLORS_LIGHT_THEME.blue[9],
    colorInfoBorder: COLORS_LIGHT_THEME.blue[4],
    colorInfoBorderHover: COLORS_LIGHT_THEME.blue[3],
    colorInfoText: COLORS_LIGHT_THEME.blue[3],
    colorInfoTextActive: COLORS_LIGHT_THEME.blue[2],
    colorInfoTextHover: COLORS_LIGHT_THEME.blue[4],

    /**
     * Success
     */
    colorSuccess: COLORS_LIGHT_THEME.green[4],
    colorSuccessActive: COLORS_LIGHT_THEME.green[3],
    colorSuccessHover: COLORS_LIGHT_THEME.green[5],
    colorSuccessBg: COLORS_LIGHT_THEME.green[10],
    colorSuccessBgActive: COLORS_LIGHT_THEME.green[8],
    colorSuccessBgHover: COLORS_LIGHT_THEME.green[9],
    colorSuccessBorder: COLORS_LIGHT_THEME.green[4],
    colorSuccessgBorderHover: COLORS_LIGHT_THEME.green[3],
    colorSuccessText: COLORS_LIGHT_THEME.green[3],
    colorSuccessTextActive: COLORS_LIGHT_THEME.green[2],
    colorSuccessTextHover: COLORS_LIGHT_THEME.green[4],

    /**
     * Warning
     */
    colorWarning: COLORS_LIGHT_THEME.yellow[4],
    colorWarningActive: COLORS_LIGHT_THEME.yellow[3],
    colorWarningHover: COLORS_LIGHT_THEME.yellow[5],
    colorWarningBg: COLORS_LIGHT_THEME.yellow[10],
    colorWarningBgActive: COLORS_LIGHT_THEME.yellow[8],
    colorWarningBgHover: COLORS_LIGHT_THEME.yellow[9],
    colorWarningBorder: COLORS_LIGHT_THEME.yellow[4],
    colorWarningBorderHover: COLORS_LIGHT_THEME.yellow[3],
    colorWarningText: COLORS_LIGHT_THEME.yellow[3],
    colorWarningTextActive: COLORS_LIGHT_THEME.yellow[2],
    colorWarningTextHover: COLORS_LIGHT_THEME.yellow[4],

    // controlItemBgActive: matches colorPrimaryBg,
    // controlItemBgActiveDisabled: matches colorFill,
    // controlItemBgActiveHover: matches colorPrimaryBgHover,
    // controlItemBgHover: matches colorFillTertiary,
    // controlOutline: 'transparent',
    // controlTmpOutline: matches colorFillQuaternary,

    // EXTRAS. Figure out what to do with these
    // colorBgSolidHover: 'rgba(0, 0, 0, 0.75)',
    // colorErrorOutline: 'rgba(254, 68, 40, 0.19)',
    // colorWarningOutline: 'rgba(245, 180, 14, 0.13)',
    // colorHighlight: '#f87168', For some reason set as Red 400
    // colorSuccessBorderHover: '#9ee8c4', not sure what the hell this is
  },
};
