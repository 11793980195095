import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
  return (
    <Spin
      data-testid="loading-spinner"
      indicator={
        <LoadingOutlined style={{ fontSize: 48, color: 'var(--purple-3)' }} />
      }
      style={{ display: 'block' }}
    />
  );
};

export default Loading;
