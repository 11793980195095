import { Empty, Space, Statistic as AntStatistic } from 'antd';
import styled from '@emotion/styled';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AccountTitle from 'components/AccountTitle/AccountTitle';
import AssetPill from 'components/Pill/AssetPill';
import DigitalWalletPill from 'components/Pill/DigitalWalletPill';
import LinkCard from 'components/LinkCard/LinkCard';
import Pill from 'components/Pill/Pill';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

const OVERVIEW_BODY_HEIGHT = '195px';

const OverviewBody = styled.div`
  min-height: ${OVERVIEW_BODY_HEIGHT};
`;

const Statistic = styled(AntStatistic)(({ placeholder }) => {
  return placeholder
    ? `
      .ant-statistic-title, .ant-statistic-content {
        color: var(--color-text-quaternary);
      }
    `
    : null;
});

const AccountOverview = ({
  accountLabel = 'Account',
  accountId,
  cardLast4,
  children,
  cardIsActivated,
  cardIsPreExpired,
  enableNavigation = false,
  navigateTo = '',
  onNavigate = () => {},
  placeholder = false,
  stat = '--',
  statPrefix,
  statValue = '--',
  subHeading = null,
  query,
  creditorValue,
}) => {
  const { showActivateCard, enableAssetSale } = useFlags();

  return (
    <LinkCard
      enabled={enableNavigation}
      onNavigate={onNavigate}
      to={navigateTo}
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <AccountTitle level={5} loading={query?.loading} cardLast4={cardLast4}>
          {accountLabel}
        </AccountTitle>
        <AccountOverviewStatusPane
          accountId={accountId}
          showActivateCard={showActivateCard}
          cardIsActivated={cardIsActivated}
          cardIsPreExpired={cardIsPreExpired}
          query={query}
          creditorValue={creditorValue}
          enableAssetSale={enableAssetSale}
        />

        {subHeading}
        <Statistic
          title={stat}
          prefix={statPrefix}
          value={statValue}
          placeholder={placeholder}
        />
        <OverviewBody>
          <QueryBoundary
            messageHeight={OVERVIEW_BODY_HEIGHT}
            errorMessage={'Failed to load Account Data.'}
            query={query}
            skeleton={{
              title: false,
              paragraph: { rows: 6, width: '100%' },
            }}
          >
            {children ? (
              children
            ) : !placeholder ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            ) : null}
          </QueryBoundary>
        </OverviewBody>
      </Space>
    </LinkCard>
  );
};

export default AccountOverview;

function AccountOverviewStatusPane({
  accountId,
  query,
  showActivateCard,
  cardIsActivated,
  cardIsPreExpired,
  creditorValue,
  enableAssetSale,
}) {
  const statusPillProps =
    cardIsActivated || cardIsPreExpired
      ? {
          color: 'green',
          icon: <CheckCircleOutlined />,
          label: 'Activated',
        }
      : {
          color: 'orange',
          icon: <ExclamationCircleOutlined />,
          label: 'Not Activated',
        };
  return (
    <Space>
      {!query && (
        <>
          {showActivateCard && (
            <Pill color={statusPillProps.color} icon={statusPillProps.icon}>
              {statusPillProps.label}
            </Pill>
          )}
          {enableAssetSale && creditorValue && (
            <AssetPill>{creditorValue}</AssetPill>
          )}
        </>
      )}
      <DigitalWalletPill accountId={accountId} />
    </Space>
  );
}
