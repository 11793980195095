import { Button, Flex, Typography } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import MakePaymentModal from '../MakePaymentModal';
import ThirdPartyInfoPromptAlert from './ThirdPartyInfoPromptAlert';
import ThirdPartyEmailModal from './ThirdPartyEmailModal';
import useModalControls from 'hooks/useModalControls';
import OfferCard from './OfferCard';

const { Title } = Typography;

const ChooseOfferStep = ({
  offers,
  hasPendingPayment,
  hasScheduledPayment,
  thirdParty,
}) => {
  const thirdPartyEmailModal = useModalControls();
  const makePaymentModal = useModalControls();

  const OfferCards = offers?.map((offer, index) => {
    return (
      <OfferCard
        key={index}
        offer={offer}
        hasPendingPayment={hasPendingPayment}
        hasScheduledPayment={hasScheduledPayment}
        thirdParty={thirdParty}
      />
    );
  });

  return (
    <>
      {!thirdParty?.email && !thirdParty?.error && (
        <ThirdPartyInfoPromptAlert
          showThirdPartyEmailModal={thirdPartyEmailModal.show}
        />
      )}
      <ThirdPartyEmailModal {...thirdPartyEmailModal} thirdParty={thirdParty} />
      <Title level={4}>Choose An Offer</Title>
      <Flex
        gap={'var(--spacing-lg)'}
        style={{ marginBottom: 'var(--spacing-sm)' }}
      >
        {offers && OfferCards}
      </Flex>
      <Button size="small" type="text" onClick={() => makePaymentModal.show()}>
        Make an immediate, non-offer payment
      </Button>
      <MakePaymentModal {...makePaymentModal} paymentUUID={uuidv4()} />
    </>
  );
};

export default ChooseOfferStep;
