import { DatePicker as AntDatePicker } from 'antd';

export const ACCEPTABLE_DATE_FORMATS = [
  'M/D/YYYY', //default format on 'enter'
  'M/DD/YYYY',
  'MM/D/YYYY',
  'MM/DD/YYYY',
];

/**
 * This is to provide a default set of acceptable entered formats
 * when entering dates manually. Once a date is matched it will be
 * accepted and displayed in the field with the format M/D/YYYY
 */
const DatePicker = ({ placeholder, format, ...props }) => {
  placeholder = placeholder || 'M/D/YYYY';
  format = format || ACCEPTABLE_DATE_FORMATS;

  return <AntDatePicker placeholder={placeholder} format={format} {...props} />;
};

const RangePicker = ({ format, ...props }) => {
  format = format || ACCEPTABLE_DATE_FORMATS;

  return <AntDatePicker.RangePicker format={format} {...props} />;
};

DatePicker.RangePicker = RangePicker;

export default DatePicker;
