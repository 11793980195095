import { makeVar, useReactiveVar } from '@apollo/client';

import { COLOR_PALETTE, COLORS_CALABRIO } from 'utilities/styles';

export const AGENT_STATUS_COLORS = Object.freeze({
  AfterCallWork: {
    background: COLORS_CALABRIO.teal,
    foreground: COLOR_PALETTE.neutralLight0,
  },
  Available: {
    background: COLORS_CALABRIO.green,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  Break: {
    background: COLORS_CALABRIO.orange,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  Busy: {
    background: COLORS_CALABRIO.green,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  'Floor Walker': {
    background: COLORS_CALABRIO.peach,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  Lunch: {
    background: COLORS_CALABRIO.yellow,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  Meeting: {
    background: COLORS_CALABRIO.blue,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  MissedCallAgent: {
    background: COLORS_CALABRIO.red,
    foreground: COLOR_PALETTE.neutralLight0,
  },
  Offline: {
    background: COLORS_CALABRIO.red,
    foreground: COLOR_PALETTE.neutralLight0,
  },
  'Outbound Call': {
    background: COLORS_CALABRIO.purple,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  PendingBusy: {
    background: COLORS_CALABRIO.green,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  'Project Work': {
    background: COLORS_CALABRIO.pink,
    foreground: COLOR_PALETTE.neutralDark0,
  },
  ZenDesk: {
    background: COLORS_CALABRIO.gray,
    foreground: COLOR_PALETTE.neutralDark0,
  },
});

const agentStatusVar = makeVar(null);
const agenStatusColorVar = makeVar(AGENT_STATUS_COLORS.Offline);
const inboundCallInfoVar = makeVar(null);

export const setAgentStatus = (status) => {
  agentStatusVar(status);
  agenStatusColorVar(
    AGENT_STATUS_COLORS[status] || AGENT_STATUS_COLORS.Offline,
  );
};

export const setInboundCallInfo = (contact) => {
  inboundCallInfoVar(contact);
};

export const clearInboundCallInfo = () => {
  inboundCallInfoVar(null);
};

// This is the incoming call information passed in by the softphone. It is of type Contact:
// https://github.com/amazon-connect/amazon-connect-streams/blob/d136b26ab04630aa544392ffa326e21bafcee72f/src/index.d.ts#L1103
const useAWSConnectInfo = () => {
  const agentStatus = useReactiveVar(agentStatusVar);
  const agentStatusColors = useReactiveVar(agenStatusColorVar);
  const inboundCallInfo = useReactiveVar(inboundCallInfoVar);

  return {
    agentStatus,
    agentStatusColors,
    clearInboundCallInfo,
    inboundCallInfo,
    setAgentStatus,
    setInboundCallInfo,
  };
};

export default useAWSConnectInfo;
